import { Flex, Text } from "@chakra-ui/react";
import { useSiteContext } from "context/SiteContext";
import { t } from "i18next";

export default () => {
  const { language } = useSiteContext();
  return (
    <Flex
      mt={[0, 0, 10]}
      mb={[10, 20]}
      mx="auto"
      maxW={["400px", "80%", "80%"]}
      flexDir="column"
    >
      <Flex flexWrap="wrap" w="auto" mx="auto">
        {["1", "2", "3", "4", "5", "6"].map((number) => {
          return (
            <Flex
              flexBasis={["100%", "100%", "50%"]}
              flexDirection="column"
              px="30px"
              dir={language === "ar" ? "rtl" : "ltr"}
              mt={10}
              key={number}
            >
              <Text
                fontSize="24px"
                fontWeight={300}
                color="gray.800"
                lineHeight="30px"
                mt={2}
              >
                {t(`Pricing.Question.${number}`)}
              </Text>
              <Text
                fontSize="20px"
                fontWeight={200}
                color="gray.800"
                lineHeight="30px"
                mt={2}
              >
                {t(`Pricing.Answer.${number}`)}
              </Text>
            </Flex>
          );
        })}
      </Flex>
      <Flex mt={14} mx="auto" flexDir="column" textAlign="center">
        <Text fontSize="24px">{t("Questions.CTA")}</Text>
        <Text fontSize="20px" color="gray.600">
          {t("Questions.Description")}
        </Text>
      </Flex>
    </Flex>
  );
};
