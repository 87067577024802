import { Flex, IconButton, Image } from "@chakra-ui/react";
import { ReactComponent as LinkedInIcon } from "icons/linkedinIcon.svg";
import { ReactComponent as TwitterIcon } from "icons/twitterIcon.svg";
import { ReactComponent as FBIcon } from "icons/FB_icon.svg";
import FBIcon2 from "icons/FB_icon_2.png";
import Twitter2 from "icons/Twitter2.png";
import { ReactComponent as WhatsappIcon } from "icons/whatsappIcon.svg";
import { Form, FormLink, Channel } from "types/Form";
import { useGetFormUrl } from "hooks/useGetFormUrl";
import { logAnalyticsEvent } from "../../../firebase/firebase-analytics";

export default ({ form, disabled }: { form: Form; disabled?: boolean }) => {
  const formURL = useGetFormUrl();

  const getLink = (channel: Channel, links?: FormLink[]): string => {
    if (!links) return formURL;
    return links.find((link) => link.channel === channel)?.link ?? formURL;
  };

  return (
    <Flex
      gap={{ base: "5px", md: "10px" }}
      mx="auto"
      pointerEvents={disabled ? "none" : "initial"}
    >
      <IconButton
        borderRadius="50%"
        bg="white"
        h="36px"
        w="36px"
        minW="40px"
        minH="40px"
        icon={<Image style={{ height: "inherit" }} src={FBIcon2} />}
        aria-label="Preview"
        _active={{
          backgroundColor: "white",
        }}
        _focus={{ outline: "none" }}
        onClick={() => {
          logAnalyticsEvent("facebook_share");
          (window as any).FB.ui({
            method: "share",
            href: getLink(Channel.FACEBOOK, form?.links),
          });
        }}
      />
      <a
        href={`https://twitter.com/intent/tweet?url=${encodeURI(
          getLink(Channel.TWITTER, form?.links)
        )}`}
        target="_blank"
        rel="noreferrer"
      >
        <IconButton
          borderRadius="50%"
          bg="white"
          h="36px"
          w="36px"
          minW="40px"
          minH="40px"
          // icon={
          //   <TwitterIcon style={{ margin: "9px auto", height: "inherit" }} />
          // }
          icon={<Image style={{ height: "inherit" }} src={Twitter2} />}
          aria-label="Preview"
          _active={{
            backgroundColor: "white",
          }}
          _focus={{ outline: "none" }}
          onClick={() => {
            logAnalyticsEvent("twitter_share");
          }}
        />
      </a>
      <a
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(
          getLink(Channel.LINKEDIN, form?.links)
        )}`}
        target="_blank"
        rel="noreferrer"
      >
        <IconButton
          borderRadius="50%"
          bg="white"
          h="36px"
          w="36px"
          minW="40px"
          minH="40px"
          icon={
            <LinkedInIcon style={{ margin: "9px auto", height: "inherit" }} />
          }
          aria-label="Preview"
          _active={{
            backgroundColor: "teal.600",
          }}
          _focus={{ outline: "none" }}
          onClick={() => logAnalyticsEvent("linkedIn_share")}
        />
      </a>
      <a
        href={`https://wa.me/?text=${encodeURI(
          getLink(Channel.WHATSAPP, form?.links)
        )}`}
        data-action="share/whatsapp/share"
        target="_blank"
        rel="noreferrer"
      >
        <IconButton
          borderRadius="50%"
          bg="white"
          h="36px"
          w="36px"
          minW="40px"
          minH="40px"
          icon={
            <WhatsappIcon style={{ margin: "9px auto", height: "inherit" }} />
          }
          aria-label="Preview"
          _active={{
            backgroundColor: "white",
          }}
          _focus={{ outline: "none" }}
          onClick={() => logAnalyticsEvent("whatsapp_share")}
        />
      </a>
    </Flex>
  );
};
