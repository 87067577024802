import { Button } from "@chakra-ui/react";
import { useFormContext } from "context/FormContext";
import { useMemo } from "react";
import { getFontSize } from "utils/form/fontSize";
import { logAnalyticsEvent } from "../../firebase/firebase-analytics";

export const CTA = ({
  width,
  minWidth,
  onClick,
}: {
  width: number;
  minWidth: number;
  onClick?: () => void;
}) => {
  const { form } = useFormContext();
  const fontFamily = form?.theme?.font_family;
  const fontSize = useMemo(
    () => getFontSize(form?.theme?.fields?.font_size, width > minWidth),
    [form?.theme?.fields?.font_size, width, minWidth]
  );

  return (
    <Button
      id="publish-button"
      dir="rtl"
      fontWeight="400"
      iconSpacing="14px"
      color="white"
      bg="teal.600"
      w="fit-content"
      minW="0"
      maxW="255px"
      fontFamily={fontFamily ?? "inherit"}
      fontSize={fontSize}
      _hover={{
        bg: "teal.400",
      }}
      onClick={() => {
        onClick && onClick();
        logAnalyticsEvent("click_cta");
      }}
    >
      asd
    </Button>
  );
};
