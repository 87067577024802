/* eslint-disable react/jsx-no-constructed-context-values */
import {
  createContext,
  useState,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";
import { onAuthStateChanged, User } from "firebase/auth";
import { useDisclosure } from "@chakra-ui/react";
import { setUserId } from "firebase/analytics";
import { UserRole } from "types/User";
import { AuthMode, SignUpState } from "types/Auth";
import { auth } from "../firebase/firebase-config";
import { analytics } from "../firebase/firebase-analytics";
import { getCustomClaimRole } from "./AuthContext.utils";

export const AuthContext = createContext<{
  user?: User;
  userRole: UserRole | null;
  error?: any;
  isLoginModalOpen: boolean;
  openLoginModal: (successUrl: string) => void;
  closeLoginModal: () => void;
  signOut?: any;
  isAdmin?: boolean;
  isReturningUser?: boolean;
  signInSuccessUrl: string;
  setLogRocketInitiated: (bool: boolean) => void;
  logRocketInitiated: boolean;
  isBeta: boolean;
  authMode: AuthMode;
  setAuthMode: Dispatch<SetStateAction<AuthMode>>;
  signUpState: SignUpState | undefined;
  setSignUpState: Dispatch<SetStateAction<SignUpState>>;
}>({
  userRole: null,
  openLoginModal: () => null,
  isLoginModalOpen: false,
  closeLoginModal: () => null,
  signInSuccessUrl: "/account/create-chat",
  setLogRocketInitiated: (bool) => null,
  logRocketInitiated: false,
  isBeta: false,
  authMode: AuthMode.LOGIN,
  setAuthMode: () => {},
  signUpState: undefined,
  setSignUpState: () => {},
});

export const AuthContextProvider = (props) => {
  const [user, setUser] = useState<User | null>();
  const [userRole, setUserRole] = useState<UserRole | null>(props.userRole);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isReturningUser, setIsReturningUser] = useState<boolean>(false);
  const [signInSuccessUrl, setSignInSuccessUrl] = useState<string>(
    "/account/create-chat"
  );
  const [logRocketInitiated, setLogRocketInitiated] = useState<boolean>(false);
  const [isBeta, setIsBeta] = useState<boolean>(false);

  const [authMode, setAuthMode] = useState<AuthMode>(AuthMode.LOGIN);
  const [signUpState, setSignUpState] = useState<SignUpState>();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleAuthStateChanged = async (firebaseUser: any | null) => {
    if (firebaseUser) {
      if (authMode === AuthMode.SIGN_UP) {
        setSignUpState(SignUpState.USER_TYPE);
      }

      if (process.env.NODE_ENV !== "development") {
        setUserId(analytics, firebaseUser.uid);
      }

      if (firebaseUser.metadata?.createdAt) {
        const creationTimestamp = Number(firebaseUser.metadata?.createdAt);
        const time = new Date(creationTimestamp).getTime();

        if (creationTimestamp) {
          const twentyfourh = time + 60 * 60 * 24 * 1000;

          if (new Date().getTime() > twentyfourh) {
            setIsReturningUser(true);
          }
        }
      }

      setUser(firebaseUser);
    } else {
      setUser(null);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChanged);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const getUserRole = async () => {
      const decodedToken = await getCustomClaimRole();
      if (!decodedToken?.claims) {
        setUserRole(UserRole.BASIC);
        return;
      }
      const { stripeRole, role } = decodedToken?.claims as any;
      setUserRole(stripeRole);

      const areTheyAdmin = role === "admin";
      setIsAdmin(areTheyAdmin);
    };

    if (user) {
      // to prevent login modal to open again.
      if (authMode === AuthMode.SIGN_UP) {
        setSignUpState(SignUpState.USER_TYPE);
      }
      if (user.uid === "KpbtrxTtjNeudUxJNo0cTJq9lrB3") setIsBeta(true);
      getUserRole();
    }
  }, [user, authMode]);

  return (
    <AuthContext.Provider
      value={{
        user,
        userRole,
        isLoginModalOpen: isOpen,
        openLoginModal: (successUrl: string) => {
          setSignInSuccessUrl(successUrl);
          onOpen();
        },
        closeLoginModal: onClose,
        signOut: () => {
          auth.signOut();
          setAuthMode(AuthMode.LOGIN);
          setSignUpState(undefined);
        },
        isAdmin,
        isReturningUser,
        signInSuccessUrl,
        logRocketInitiated,
        setLogRocketInitiated,
        isBeta,
        authMode,
        setAuthMode,
        signUpState,
        setSignUpState,
      }}
      {...props}
    />
  );
};

export const useAuthState = () => {
  const authContextData = useContext(AuthContext);
  const appAuth = auth;

  return {
    ...authContextData,
    isAuthenticated: authContextData?.user !== null,
    auth: appAuth,
  };
};
