import { Flex, Button, Text, IconButton } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useEffect, useRef, useState } from "react";
import {
  DEFULT_BUTTON_COLOR,
  DEFULT_BUTTON_CONTENT_COLOR,
} from "utils/addForm/formConstants";
import { InlineEdit } from "./InlineEdit";
import { getAlphaLetter } from "./utils";

interface MultipleChoiceButtonProps {
  choiceIndex: number;
  choiceId: string;
  language: string;
  label: string;
  isStatic: boolean;
  submitResponse?: (choiceId: string) => void;
  isSelected: boolean;
  removeChoice?: ({ choiceId }: { choiceId: string }) => void;
  editChoice?: ({ choiceId, text }: { choiceId: string; text: string }) => void;
  buttonTextColor?: string;
  buttonBackgroundColor?: string;
  fontFamily?: string;
  fontSize?: string;
}

const MultipleChoiceButton = ({
  choiceIndex,
  choiceId,
  language = "en",
  label,
  isStatic,
  submitResponse,
  isSelected,
  removeChoice,
  editChoice,
  buttonTextColor = DEFULT_BUTTON_CONTENT_COLOR,
  buttonBackgroundColor = DEFULT_BUTTON_COLOR,
  fontFamily,
  fontSize,
}: MultipleChoiceButtonProps) => {
  const [editingValue, setEditingValue] = useState(label);
  const ref = useRef<any>(null);

  const handleChange = (event) => {
    if (editChoice) {
      editChoice({ text: event.target.innerText, choiceId });
    }
  };

  useEffect(() => {
    const element = ref.current;

    element.addEventListener("input", handleChange);

    return () => {
      element.removeEventListener("input", handleChange);
    };
  }, []);

  return (
    <Flex
      flex="0 1 30px"
      position="relative"
      maxW="100%"
      dir={language === "en" ? "ltr" : "rtl"}
      height="auto"
    >
      <Flex flex={1} minW="130px">
        <Button
          _hover={{
            cursor: "pointer",
            borderColor: buttonBackgroundColor,
            bg: buttonBackgroundColor
              ? `${buttonBackgroundColor}12`
              : "teal.500",
            boxShadow: "lg",
          }}
          _active={{
            bg: buttonBackgroundColor
              ? `${buttonBackgroundColor}12`
              : "teal.500",
            boxShadow: "lg",
          }}
          h="100%"
          _focus={{
            outline: "none",
          }}
          borderRadius="4px"
          fontSize="14px"
          fontWeight={400}
          color={buttonTextColor ?? "gray.400"}
          onClick={() => {
            if (submitResponse) {
              submitResponse(choiceId);
            }
          }}
          fontFamily={fontFamily ?? "inherit"}
          p={0}
          minWidth={0}
          border="1px"
          borderColor={buttonBackgroundColor}
          bg={
            isSelected
              ? buttonBackgroundColor
                ? `${buttonBackgroundColor}52`
                : "teal.100"
              : buttonBackgroundColor
              ? `${buttonBackgroundColor}12`
              : "teal.600"
          }
          boxShadow={isSelected ? "lg" : "none"}
          maxW="100%"
        >
          <Flex minW="20px" alignItems="start" mx={2}>
            <Text
              h="100%"
              m="auto"
              fontSize={fontSize}
              fontFamily={fontFamily ?? "inherit"}
            >
              {getAlphaLetter(choiceIndex, language)} -
            </Text>
          </Flex>
          <InlineEdit
            value={editingValue}
            disabled={isStatic}
            data-testid="multiple-choice-option"
            innerRef={ref}
            fontSize={fontSize}
          />
        </Button>
      </Flex>
      {!isStatic && (
        <IconButton
          alignSelf="center"
          bg="white"
          margin="0px 10px"
          padding="8px"
          _focus={{
            border: 0,
          }}
          borderRadius="50%"
          size="xl"
          aria-label="Add to friends"
          icon={<CloseIcon color="gray.500" h={2} w={2} />}
          data-testid={`delete-choice-${choiceId}`}
          onClick={() => {
            if (removeChoice) {
              removeChoice({ choiceId });
            }
          }}
        />
      )}
    </Flex>
  );
};

export default MultipleChoiceButton;
