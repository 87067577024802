import { Box, useDisclosure, useOutsideClick } from "@chakra-ui/react";
import { useState, useRef } from "react";
import { FormField, Answer } from "types/Form";
import { useTranslation } from "react-i18next";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { useStaticFormContext } from "context/StaticFormContext";
import { useFormContext } from "context/FormContext";
import DateField from "./DateField";

const DateWrapper = ({
  isStatic,
  field,
}: {
  isStatic: boolean;
  field: FormField;
}) => {
  const { form } = useFormContext();
  const ref = useRef(null);
  const [date, setDate] = useState(new Date());
  const { i18n } = useTranslation();
  const { answers, setAnswers } = useStaticFormContext();

  const save = (e) => {
    setDate(e);
    if (answers && setAnswers) {
      const objIndex = answers.findIndex(
        (obj) => obj.field_id === field.field_id
      );

      const newAnswer: Answer = {
        field_id: field.field_id,
        type: "date",
        value: {
          date: new Date(e).getTime(),
        },
      };
      if (objIndex < 0) {
        setAnswers([...answers, newAnswer]);
      } else {
        answers[objIndex] = newAnswer;
        setAnswers([...answers]);
      }
    }
  };
  const answer = () => {
    const fieldResponse = answers?.find(
      (response) => response.field_id === field.field_id
    );
    if (fieldResponse?.type === "date") {
      return fieldResponse.value.date.toString();
    }
    return "";
  };

  const fontFamily = form?.theme?.font_family;
  const fontColor = form?.theme?.colors?.answer;

  return (
    <DateField
      fontFamily={fontFamily}
      isStatic={isStatic}
      date={date}
      onChange={save}
      borderColor={fontColor}
      textColor={fontColor}
    />
  );
};
export default DateWrapper;
