import {
  Box,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from "@chakra-ui/react";
import React from "react";

import { ReactComponent as CloseIcon } from "icons/close.svg";
import { useSiteContext } from "context/SiteContext";
import { DeleteIcon } from "@chakra-ui/icons";

interface OverlayWrapperProps extends React.PropsWithChildren<any>, ModalProps {
  disableClose?: boolean;
  title?: string;
  isMobileQuestionModal?: boolean;
  isResponseModal?: boolean;
  isFilterQuestionsModal?: boolean;
  onClickDelete?: () => void;
}

const OverlayWrapper = ({
  children,
  isOpen,
  onClose = () => {},
  title,
  disableClose,
  isMobileQuestionModal = false,
  isResponseModal = false,
  isFilterQuestionsModal = false,
  onClickDelete = () => {},
  ...props
}: OverlayWrapperProps) => {
  const { language, isDesktop, direction } = useSiteContext();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      motionPreset={isDesktop ? undefined : "slideInBottom"}
      isCentered={isDesktop}
      {...props}
    >
      <ModalOverlay height="100vh" />
      <ModalContent
        position={isDesktop ? undefined : "fixed"}
        h={isMobileQuestionModal ? "100%" : undefined}
        bottom={0}
        mb={0}
        mt={0}
        borderRadius={
          isDesktop
            ? "5px"
            : isMobileQuestionModal
            ? 0
            : "1.75rem 1.75rem 0px 0px"
        }
        dir={language === "ar" ? "rtl" : "ltr"}
        overflowY={isMobileQuestionModal ? "auto" : undefined}
        maxH={isResponseModal || isFilterQuestionsModal ? "70%" : undefined}
      >
        {!isMobileQuestionModal && (
          <ModalHeader px={0}>
            {!isDesktop && !isMobileQuestionModal && (
              <Flex flexDir="row" justifyContent="center" mb={3}>
                <Box
                  height="5px"
                  width="48px"
                  borderRadius="3px"
                  bgColor="#CDCFD0"
                />
              </Flex>
            )}
            <Flex
              flexDir="row"
              px={6}
              alignItems="center"
              pb={4}
              borderBottomWidth={isDesktop || isResponseModal ? "0.2px" : 0}
              borderColor="#eeeeee"
            >
              <Flex flex={1}>
                <Text
                  fontWeight={!isDesktop ? "500" : "semiBold"}
                  fontSize={!isDesktop ? "18px" : "xl"}
                  color="#2D3748"
                >
                  {title}
                </Text>
              </Flex>
              {isResponseModal && (
                <IconButton
                  ml={direction === "rtl" ? 2 : 0}
                  mr={direction === "ltr" ? 2 : 0}
                  _focus={{
                    outline: "none",
                  }}
                  onClick={onClickDelete}
                  variant="unstyled"
                  h="24px"
                  w="27px"
                  aria-label="delete-button"
                  color="#6C6A6A"
                  icon={
                    <DeleteIcon display="initial" height="20px" width="20px" />
                  }
                />
              )}
              {!disableClose && (
                <IconButton
                  _focus={{
                    outline: "none",
                  }}
                  onClick={onClose}
                  variant="unstyled"
                  h="25px"
                  minW="25px"
                  borderRadius="50%"
                  aria-label="close-button"
                  icon={
                    <CloseIcon
                      display="initial"
                      height="12px"
                      width="12px"
                      fill="#808080"
                      stroke="#808080"
                    />
                  }
                />
              )}
            </Flex>
          </ModalHeader>
        )}
        <ModalBody
          pb={4}
          p={isMobileQuestionModal ? 0 : undefined}
          overflowY={
            isResponseModal || isFilterQuestionsModal ? "auto" : undefined
          }
        >
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default OverlayWrapper;
