import {
  Box,
  Flex,
  Textarea,
  useDimensions,
  useMediaQuery,
} from "@chakra-ui/react";
import ExpandIconButton from "components/buttons/ExpandIconButton";
import { useFormContext } from "context/FormContext";
import { useEffect, useMemo, useRef } from "react";
import { FormField, FieldType } from "types/Form";
import {
  DEFAULT_TITLE_COLOR,
  DEFULT_DESCRIPTION_COLOR,
} from "utils/addForm/formConstants";
import { getFontSize } from "utils/form/fontSize";

export const Inputs = ({
  minWidth,
  isStatic,
  width,
  changeForm,
  field,
  isExpanded,
  onExpand,
}: {
  minWidth: number;
  isStatic: boolean;
  isExpanded?: boolean;
  width: number;
  changeForm: any;
  field: FormField;
  onExpand?: () => void;
}) => {
  const [isDesktop] = useMediaQuery("(min-width: 992px)");
  const { form } = useFormContext();

  const elementRef = useRef<HTMLDivElement>(null);
  // to observe window resize and update textarea height
  const dimensions = useDimensions(elementRef, true);

  const ref = useRef<any>();
  const questionColor = form?.theme?.colors?.question;
  const fontSize = useMemo(
    () => getFontSize(form?.theme?.fields?.font_size, width > minWidth),
    [form?.theme?.fields?.font_size, width, minWidth]
  );
  const fontFamily = form?.theme?.font_family;
  const textAlignment =
    field.type === FieldType.WELCOME_SCREEN
      ? form?.theme?.screens?.alignment
      : form?.theme?.fields?.alignment;

  const showSpace = () => {
    if (field?.properties?.description?.length === 0) {
      if (!isStatic) {
        return <Flex flexGrow={1} flexShrink={1} flexBasis={["34px"]} />;
      }
      if (isStatic) {
        return null;
      }
    }

    return <Flex flexGrow={1} flexShrink={1} flexBasis={["34px"]} />;
  };

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    ref.current.style.height = "5px";
    ref.current.style.height = `${ref.current.scrollHeight}px`;
  }, [field?.title, ref, dimensions?.contentBox.width]);

  return (
    <Flex flexDir="row" alignItems="flex-start" ref={elementRef}>
      <Flex flexDir="column" flex={1}>
        <Flex w="100%">
          <Textarea
            // id="1"
            className={field.field_id.substring(0, 5)}
            pt="1px"
            resize="none"
            disabled={isStatic}
            variant="unstyled"
            fontWeight="400"
            fontFamily={fontFamily ?? "inherit"}
            fontSize={fontSize}
            onChange={(e) =>
              changeForm({
                fieldAttribute: "title",
                value: e.target.value,
                id: field?.field_id,
                type: field?.type,
              })
            }
            textAlign={textAlignment}
            value={field?.title}
            px={1}
            w="100%"
            h="auto"
            minH="60px"
            ref={ref}
            border={isStatic ? "none" : "1px dashed"}
            borderColor="gray.300"
            overflowY="hidden"
            style={{
              WebkitTextFillColor: questionColor ?? "black",
              opacity: 1,
            }}
          />
        </Flex>
        <Flex mt={2}>
          <Textarea
            disabled={isStatic}
            fontWeight="300"
            fontSize="16px"
            color="gray.600"
            variant="unstyled"
            textAlign={textAlignment}
            onChange={(e) =>
              changeForm({
                fieldAttribute: "description",
                value: e.target.value,
                id: field?.field_id,
                type: field?.type,
              })
            }
            value={field?.properties?.description}
            resize="none"
            px={1}
            fontFamily={fontFamily ?? "inherit"}
            border={isStatic ? "none" : "1px dashed"}
            borderColor="gray.300"
            overflow="hidden"
            h="auto"
            minH="0"
            ref={(node) => {
              if (!node || !isDesktop) return;
              node.style.height = "auto";
              node.style.height = `${node?.scrollHeight}px`;
            }}
            style={{
              WebkitTextFillColor:
                questionColor === DEFAULT_TITLE_COLOR ||
                questionColor === undefined
                  ? DEFULT_DESCRIPTION_COLOR
                  : questionColor,
              opacity: 1,
            }}
          />
        </Flex>
        {showSpace()}
      </Flex>
      {/* {!isDesktop && !isStatic && (
        <Box ms={2}>
          <ExpandIconButton expanded={isExpanded} onClick={onExpand} />
        </Box>
      )} */}
    </Flex>
  );
};
