import { ref, get, set, update, query, Database } from "firebase/database";
import {
  EndingType,
  FieldType,
  Form,
  FormField,
  FormFieldProperties,
  ThankYouScreen,
} from "types/Form";
import { deleteFirebaseFile, uploadFileToStorage } from "utils/firebaseStorage";
import uuid from "react-uuid";
import { DEFAULT_NUMBER_OF_SELECTIONS } from "components/modals/MultipleChoiceSettingsModal";
import { hasRequiredOption } from "components/form/utils/utils";
import { t } from "i18next";

let db: Database;

import("../../context/firebase-db").then(({ database }) => {
  db = database;
});

export const pushForm = async (form: Form, formBackgroundUrl?: string) => {
  if (formBackgroundUrl) {
    try {
      const id = uuid();
      const response = await uploadFileToStorage(
        formBackgroundUrl,
        `formBackground/${id}`
      );
      // by checking metadata make sure file is uploaded
      if (response.metadata) {
        // delete older logo
        if (form?.theme?.logo_id) {
          await deleteFirebaseFile(`formBackground/${form.theme.logo_id}`);
        }

        if (form.theme) {
          form.theme.logo_id = id;
        } else {
          form.theme = {
            logo_id: id,
          };
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  // permission errors can be caught here
  await set(ref(db, `form/${form?.uid}`), { ...form, editedAt: Date.now() });
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

export const onDragEnd = (result, form: Form, key, setForm) => {
  if (!result.destination) {
    return;
  }

  const newFields = reorder(
    form[key],
    result.source.index,
    result.destination.index
  );

  setForm({ ...form, [key]: newFields as FormField[] });
};

export const removePage = (index, form: Form, key, setForm) => {
  if (key === "welcome_screens") {
    const tempForm = { ...form };
    delete tempForm[key];
    setForm(tempForm);
  } else {
    const result = Array.from(form[key]);
    result?.splice(index, 1);
    setForm({ ...form, [key]: result });
  }
  // (result[index] as any).active = false;
};

export const isWalkthroughComplete = async (userId: string, callback) => {
  const formQuery = query(ref(db, `user/${userId}`));
  const snapshot = await get(formQuery);

  const data: { [key: string]: Form } = await snapshot?.val();

  if (data?.hasCompletedWalkthrough) return callback(true);

  callback(false);

  return null;
};

export const isWalkthroughAIComplete = async (userId: string, callback) => {
  const formQuery = query(ref(db, `user/${userId}`));
  const snapshot = await get(formQuery);

  const data: { [key: string]: Form } = await snapshot?.val();

  if (data?.hasCompletedWalkthroughAI) return callback(true);

  callback(false);

  return null;
};

export const isWhatsappNumberSet = async (userId: string, callback) => {
  const formQuery = query(ref(db, `user/${userId}`));
  const snapshot = await get(formQuery);

  const data: { [key: string]: Form } = await snapshot?.val();

  if (data?.whatsappNumber) return callback(true);

  callback(false);

  return null;
};

export const setUserWhatsappNumber = (userId: string, number: string) => {
  return update(ref(db, `user/${userId}`), {
    whatsappNumber: number,
  });
};

export const isSurveyComplete = async (userId: string, callback) => {
  const formQuery = query(ref(db, `user/${userId}`));
  const snapshot = await get(formQuery);

  const data: { [key: string]: Form } = await snapshot.val();

  if (data?.hasCompletedSurvey) return callback(true);

  callback(false);

  return null;
};

export const setWalkthroughComplete = (userId: string) => {
  update(ref(db, `user/${userId}`), {
    hasCompletedWalkthrough: true,
  }).catch((err) => console.log({ err }));
};

export const setWalkthroughAIComplete = (userId: string) => {
  update(ref(db, `user/${userId}`), {
    hasCompletedWalkthroughAI: true,
  }).catch((err) => console.log({ err }));
};

export const setSurveyComplete = (userId: string) => {
  update(ref(db, `user/${userId}`), {
    hasCompletedSurvey: true,
  }).catch((err) => console.log({ err }));
};

export const addPageToForm = (
  form: Form,
  type: FieldType | EndingType,
  language: string
) => {
  if (type === EndingType.SCORE || type === EndingType.THANK_YOU) {
    const { thankyou_screens } = form;
    let newScreen: ThankYouScreen | undefined;

    if (type === EndingType.THANK_YOU) {
      newScreen = {
        id: uuid(),
        type,
        title: "",
        active: true,
        properties: { description: "", share_icons: true },
      };
    } else {
      newScreen = {
        id: uuid(),
        type,
        title: t("ScoreDefaultTitle"),
        active: true,
        properties: { description: "", share_icons: true },
      };
    }

    if (!thankyou_screens) {
      return {
        ...form,
        thankyou_screens: [newScreen],
      };
    }

    thankyou_screens?.splice(-1, 0, newScreen);

    return {
      ...form,
      thankyou_screens,
    };
  }
  const properties: FormFieldProperties = { description: "" };

  if (type === FieldType.WELCOME_SCREEN) {
    const welcome_screens = form.welcome_screens ?? [];

    const id = uuid();

    welcome_screens.push({
      id,
      ref: id,
      type,
      title: "",
      properties,
      active: true,
    });

    return {
      ...form,
      welcome_screens,
    };
  }

  const fields = form.fields ?? [];

  if (type === FieldType.MULTIPLE_CHOICE) {
    properties.number_of_selection = DEFAULT_NUMBER_OF_SELECTIONS;
    properties.choices = [
      { id: uuid(), label: language === "en" ? "Choice 1" : "الخيار ١" },
      { id: uuid(), label: language === "en" ? "Choice 2" : "الخيار ٢" },
    ];
  }

  if (type === FieldType.OPINION_SCALE) {
    properties.choices = [
      { id: uuid(), label: language === "en" ? "0" : "٠", value: 0 },
      { id: uuid(), label: language === "en" ? "1" : "١", value: 1 },
      { id: uuid(), label: language === "en" ? "2" : "٢", value: 2 },
      { id: uuid(), label: language === "en" ? "3" : "٣", value: 3 },
      { id: uuid(), label: language === "en" ? "4" : "٤", value: 4 },
      { id: uuid(), label: language === "en" ? "5" : "٥", value: 5 },
    ];
  }

  if (
    hasRequiredOption(type as FieldType) ||
    type === FieldType.MULTIPLE_CHOICE
  ) {
    properties.required = true;
  }

  fields.push({
    field_id: uuid(),
    type: type as FieldType,
    title: "",
    properties,
    active: true,
  });

  return {
    ...form,
    fields,
  };
};
