import { SettingsIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  IconButton,
  ModalFooter,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { changeFormHandler } from "components/form/utils/utils";
import { useFormContext } from "context/FormContext";
import { useSiteContext } from "context/SiteContext";
import { ReactNode, useRef } from "react";
import { FieldType, FormField } from "types/Form";
import { t } from "i18next";
import OverlayWrapper from "components/form/general/overlay/OverlayWrapper";
import { MultipleChoiceButtons } from "components/field/MultipleChoiceButtons";
import { logAnalyticsEvent } from "../../firebase/firebase-analytics";

interface BasicFieldSettingsModalChildrenProps {
  onClick: () => void;
}

interface BasicFieldSettingsModalProps {
  isAiForm?: boolean;
  isDefaultOpen?: boolean;
  field: FormField;
  children?: (props: BasicFieldSettingsModalChildrenProps) => ReactNode;
}

export default ({
  isAiForm = false,
  isDefaultOpen,
  field,
  children,
  ...props
}: BasicFieldSettingsModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    isOpen: isDefaultOpen,
  });
  const { form, setForm } = useFormContext();
  const { isDesktop } = useSiteContext();

  // fix stale closure
  const ref = useRef<any>({ form: [] }).current;
  ref.form = form;

  const changeForm = ({
    fieldAttribute,
    value,
  }: {
    fieldAttribute: string;
    value: any;
  }) => {
    if (!field?.field_id) return;
    const updatedForm = changeFormHandler({
      form: ref.form,
      fieldAttribute,
      value,
      id: field.field_id,
      type: field.type,
    });

    if (setForm) {
      setForm(updatedForm);
    }
  };

  const onSettingClicked = () => {
    logAnalyticsEvent("open_multiple_choice_settings");
    onOpen();
  };

  const onCancel = () => {
    onClose();
  };

  return (
    <Flex {...props}>
      {children ? (
        children({
          onClick: onSettingClicked,
        })
      ) : (
        <IconButton
          icon={
            <SettingsIcon
              color={isAiForm ? "#6C6A6A" : { base: "#3D2399", lg: "#6C6A6A" }}
              width={isAiForm ? "12px" : { base: "16px", lg: undefined }}
              height={isAiForm ? "12px" : { base: "16px", lg: undefined }}
            />
          }
          size={isAiForm ? "xs" : undefined}
          onClick={onSettingClicked}
          aria-label="settings"
          bg="white"
          border={isAiForm ? "none" : "1px"}
          borderColor={{ base: "#3D2399", lg: "#6C6A6A" }}
        />
      )}

      <OverlayWrapper
        title={t("Settings")}
        isOpen={isOpen}
        onClose={() => {
          logAnalyticsEvent("close_multiple_choice_settings");
          onCancel();
        }}
        size={isDesktop ? "sm" : "6xl"}
      >
        <Flex gap="10px" flexDir="column">
          <Flex gap="10px" alignItems="center">
            <Flex flex={1}>
              <Text fontSize="18px">{t("Required")}</Text>
            </Flex>
            <Switch
              colorScheme="teal"
              isChecked={field.properties.required}
              onChange={(e) => {
                changeForm({
                  fieldAttribute: "required",
                  value: e.target.checked,
                });
              }}
            />
          </Flex>
          {isAiForm && (
            <Flex gap="10px" direction="column">
              <Flex gap="10px" alignItems="center">
                <Flex flex={1}>
                  <Text fontSize="18px">{t("Multiple Choice")}</Text>
                </Flex>
                <Switch
                  colorScheme="teal"
                  isChecked={field.type === FieldType.MULTIPLE_CHOICE}
                  onChange={(e) => {
                    changeForm({
                      fieldAttribute: "type",
                      value: e.target.checked
                        ? FieldType.MULTIPLE_CHOICE
                        : FieldType.SHORT_ANSWER,
                    });
                  }}
                />
              </Flex>
              {field.type === FieldType.MULTIPLE_CHOICE && (
                <MultipleChoiceButtons
                  language={form?.language ?? "en"}
                  choices={field.properties.choices}
                  changeForm={changeForm}
                  field={field}
                  isStatic={false}
                />
              )}
            </Flex>
          )}
        </Flex>

        <ModalFooter
          mt={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="outline"
            borderColor="teal.500"
            color="teal.500"
            w="100%"
            maxW="200px"
            fontWeight="normal"
            onClick={onCancel}
            ms={2}
          >
            {t("Ok")}
          </Button>
        </ModalFooter>
      </OverlayWrapper>
    </Flex>
  );
};
