/* eslint react/no-children-prop: 0 */ // --> OFF

import { useDisclosure, useOutsideClick } from "@chakra-ui/react";
import { useState, useEffect, useRef, useMemo } from "react";
import { useStaticFormContext } from "context/StaticFormContext";
import { Answer, FormField } from "types/Form";
import { useTranslation } from "react-i18next";
import { useFormContext } from "context/FormContext";
import { DEFULT_ANSWER_COLOR } from "utils/addForm/formConstants";
import Countries from "../modals/NewFormModal/PhoneInput/countries.json";
import PhoneInputField from "./PhoneInputField";

export const isValidPhoneNumber = (value: string) => {
  const foundedCountry = Countries.find((country) =>
    value.includes(country.dial_code)
  );

  if (foundedCountry) {
    if (foundedCountry.length.length === 1) {
      const parsedPhoneNumber = value
        .trim()
        .slice(foundedCountry.dial_code.length);

      return parsedPhoneNumber.length === foundedCountry.length[0];
    }
  }

  return false;
};

const PhoneInputWrapper = ({
  isStatic,
  field,
  language,
}: {
  isStatic: boolean;
  field: FormField;
  language: string;
}) => {
  const { form } = useFormContext();
  const { answers, setAnswers } = useStaticFormContext();
  const [country, setCountry] = useState("+1");
  const [number, setNumber] = useState("");
  const { i18n } = useTranslation();
  const arabicT = i18n.getFixedT("ar");

  const save = (e) => {
    setNumber(e);
  };

  useEffect(() => {
    if (answers && setAnswers) {
      const removeSpaceNumber = number.replace(/\s/g, "");

      const finalNumber = removeSpaceNumber.slice(country.length);
      const objIndex = answers.findIndex(
        (obj) => obj.field_id === field.field_id
      );

      const newAnswer: Answer = {
        field_id: field.field_id,
        type: "phone",
        value: {
          text: `${country}${removeSpaceNumber}`,
        },
      };
      if (objIndex < 0) {
        setAnswers([...answers, newAnswer]);
      } else {
        answers[objIndex] = newAnswer;
        setAnswers([...answers]);
      }
    }
  }, [country, number]);

  const answer = () => {
    const fieldResponse = answers?.find(
      (response) => response.field_id === field.field_id
    );

    if (fieldResponse?.type === "phone") {
      const removeSpaceNumber = fieldResponse.value.text.replace(/\s/g, "");
      const finalNumber = removeSpaceNumber.replace(country, "");
      return `${finalNumber}`;
    }
    return "";
  };

  const isValid = useMemo(() => {
    if (!field.properties.required) {
      return true;
    }

    const fieldResponse = answers?.find(
      (response) => response.field_id === field.field_id
    );

    if (fieldResponse?.type === "phone") {
      const phoneValue = fieldResponse.value.text ?? "";

      if (answer().length <= 0) {
        return true;
      }

      return isValidPhoneNumber(phoneValue);
    }

    return false;
  }, [answers, field.properties.required]);

  const fontColor = form?.theme?.colors?.answer;
  const isDifferentFontColor = fontColor === DEFULT_ANSWER_COLOR ? "" : "";

  return (
    <PhoneInputField
      error={
        !isValid
          ? language === "ar"
            ? arabicT("Phone.Validation.Error")
            : i18n.t("Phone.Validation.Error")
          : undefined
      }
      borderColor={isDifferentFontColor ? fontColor : "#EFEFEF"}
      textColor={fontColor}
      country={country}
      onCountryChange={setCountry}
      isStatic={isStatic}
      language={language}
      number={answer()}
      onNumberChange={(numberValue) => save(numberValue)}
      placeholder={
        language === "en"
          ? "Type your number here..."
          : arabicT("PhonePlaceholder")
      }
    />
  );
};

export default PhoneInputWrapper;
