import { ref, get, update, query, Database } from "firebase/database";
import { Form } from "types/Form";
import { Dispatch, SetStateAction } from "react";

let db: Database;

import("../firebase-db").then(({ database }) => {
  db = database;
});

export const isOnboardingComplete = async (
  userId: string,
  callback: Dispatch<SetStateAction<boolean>>
) => {
  const formQuery = query(ref(db, `user/${userId}`));
  const snapshot = await get(formQuery);

  const data: { [key: string]: Form } = await snapshot.val();

  if (data?.hasCompletedOnboarding) return callback(true);

  callback(false);

  return null;
};

export const setOnboardingComplete = (userId: string) => {
  update(ref(db, `user/${userId}`), {
    hasCompletedOnboarding: true,
  }).catch((err) => console.log({ err }));
};
