import { useMemo } from "react";
import { EndingType, Form, FormField } from "types/Form";

export const useIsLastQuestion = (field_id: string, form?: Form) => {
  const isLastPageInFields = useMemo(() => {
    if (!form?.fields) return false;
    const fieldsLength = form?.fields?.length ?? 0;
    const lastFieldId = form?.fields[fieldsLength - 1]?.field_id;
    const isScorePageExists = form?.thankyou_screens?.some((currField) => {
      return currField.type === EndingType.SCORE;
    });
    return lastFieldId === field_id && isScorePageExists;
  }, [form?.fields, field_id, form?.thankyou_screens]);

  return isLastPageInFields;
};
