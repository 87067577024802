import {
  ref,
  orderByChild,
  equalTo,
  query,
  get,
  remove,
} from "firebase/database";
import { FormField, FormResponses, Response, WhatsAppAnswer } from "types/Form";
import { Dispatch, SetStateAction } from "react";
import { InboundMessageDb } from "types/WhatsApp";

import { useTranslation } from "react-i18next";

const PAGE_SIZE = 5;

let db;

import("../../context/firebase-db").then(({ database }) => {
  db = database;
});

export const deleteResponses = async ({
  responseIds,
  formId,
  setFormResponses,
  responses,
}: {
  responseIds: string[];
  formId: string;
  setFormResponses: Dispatch<SetStateAction<FormResponses>> | undefined;
  responses: FormResponses;
}) => {
  const calls = responseIds.map(async (responseId) => {
    await remove(ref(db, `response/${responseId}`));
  });

  await Promise.allSettled(calls);
  setFormResponses &&
    setFormResponses({
      ...responses,
      [formId]: responses[formId].filter(
        (response) => !responseIds.includes(response.response_id)
      ),
    });
};

export const getWhatsappResponses = async ({
  responses,
  setFormResponses,
}: {
  responses: { [key: string]: Response[] };
  setFormResponses: Dispatch<
    SetStateAction<{
      [key: string]: Response[];
    }>
  >;
}) => {
  // one phone number, many conversations

  // 1. get conversation id's
  // 2. get all inbound by conversation id
  // 3. get all outbound by conversation id

  // get conversation ID's

  const conversations = query(
    ref(db, "conversation"),
    orderByChild("sender"),
    equalTo("12676593388")
  );

  const snapshot = await get(conversations);

  if (!snapshot.exists()) {
    return;
  }

  const data: { [key: string]: Response } = await snapshot.val();

  const calls = Object.keys(data).map(async (conversation) => {
    const inboundMessages = query(
      ref(db, "whatsappInbound"),
      orderByChild("conversationId"),
      equalTo(conversation)
    );

    const inboundSnapshot = await get(inboundMessages);

    if (!inboundSnapshot.exists()) {
      return;
    }

    const inboundData: { [key: string]: InboundMessageDb } =
      await inboundSnapshot.val();

    const response: any = {
      response_id: conversation,
      form_id: "string",
      start_time: data[conversation].start_time,
    };

    Object.keys(inboundData).forEach((key) => {
      const inboundMessage = inboundData[key];
      const answer: WhatsAppAnswer = {
        field_id: "string",
        type: "whatsapp",
        value: {
          choice: {
            id: "string",
            label: "string",
            value: 0,
          },
        },
      };
      if (response.answers) {
        response.answers = [...response.answers, answer];
      } else {
        response.answers = [answer];
      }
    });
  });

  await Promise.all(calls);
  setFormResponses(responses);
};

export const getResponses = async ({
  responses,
  formKeys,
  setFormResponses,
}) => {
  const calls = formKeys.map(async (formKey) => {
    const formQuery = query(
      ref(db, "response"),
      orderByChild("form_id"),
      equalTo(formKey)
    );

    // permission errors can be caught here
    const snapshot = await get(formQuery);

    if (!snapshot.exists()) {
      return;
    }

    const data: { [key: string]: Response } = await snapshot.val();

    Object.keys(data).forEach((key) => {
      const form = data[key];
      form.response_id = key;
      if (responses[form.form_id]) {
        responses[form.form_id] = [...responses[form.form_id], form];
      } else {
        responses[form.form_id] = [form];
      }
    });
  });

  await Promise.all(calls);
  setFormResponses(responses);
};

export const getSingleFormResponse = async ({
  formKey,
  responses,
  setFormResponses,
}) => {
  const formQuery = query(
    ref(db, "response"),
    orderByChild("form_id"),
    equalTo(formKey)
  );

  // permission errors can be caught here
  const snapshot = await get(formQuery);

  if (!snapshot.exists()) {
    return;
  }

  const data: { [key: string]: Response } = await snapshot.val();

  Object.keys(data).forEach((key) => {
    const form = data[key];
    form.response_id = key;
    if (responses[form.form_id]) {
      responses[form.form_id] = [...responses[form.form_id], form];
    } else {
      responses[form.form_id] = [form];
    }
  });

  setFormResponses(responses);
};

export const getUsers = async ({ callback }) => {
  const formQuery = query(ref(db, "user"));

  const snapshot = await get(formQuery);

  if (!snapshot.exists()) {
    return;
  }

  const data: { [key: string]: Response } = await snapshot.val();

  callback(data);
};

export const filterResponses = ({
  responses,
  field,
}: {
  responses?: Response[];
  field?: FormField;
}) => {
  if (!responses) return [];
  return responses.filter((response) => {
    if (field) {
      return (
        response.submission_time !== undefined &&
        response?.answers?.filter(
          (fieldResponse) => fieldResponse.field_id === field.field_id
        )?.length > 0
      );
    }
    return response.submission_time !== undefined;
  });
};

export const sortAndLimitResponses = ({
  responses,
  limit,
}: {
  responses?: Response[];
  limit: number | null;
}) => {
  function compare(a: any, b: any) {
    return b.submission_time - a.submission_time;
  }

  if (!responses) return [];

  if (!limit) return responses.sort(compare);

  if (responses.length > limit) return responses.sort(compare).slice(-limit);

  return responses.sort(compare);
};

export const sliceResponses = ({
  responses,
  currentPage,
}: {
  responses: Response[];
  currentPage: number;
}) => {
  if (!responses) return [];
  const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
  const lastPageIndex = firstPageIndex + PAGE_SIZE;
  return responses.slice(firstPageIndex, lastPageIndex);
};

export function timeSince(date) {
  const { t } = useTranslation();
  const seconds = Math.floor(((new Date() as any) - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    if (interval < 2) {
      return t("one_year", { interval: Math.floor(interval) });
    }
    if (interval > 10) {
      return t("years_under_ten", { interval: Math.floor(interval) });
    }
    return t("years_over_ten", { interval: Math.floor(interval) });
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    if (interval < 2) {
      return t("one_month", { interval: Math.floor(interval) });
    }
    if (interval > 10) {
      return t("months_under_ten", { interval: Math.floor(interval) });
    }
    return t("months_over_ten", { interval: Math.floor(interval) });
  }
  interval = seconds / 86400;
  if (interval > 1) {
    if (interval < 2) {
      return t("one_day", { interval: Math.floor(interval) });
    }
    if (interval > 10) {
      return t("days_under_ten", { interval: Math.floor(interval) });
    }
    return t("days_over_ten", { interval: Math.floor(interval) });
  }
  interval = seconds / 3600;
  if (interval > 1) {
    if (interval < 2) {
      return t("one_hour", { interval: Math.floor(interval) });
    }
    if (interval > 10) {
      return t("hours_under_ten", { interval: Math.floor(interval) });
    }
    return t("hours_over_ten", { interval: Math.floor(interval) });
  }
  interval = seconds / 60;
  if (interval > 1) {
    if (interval < 2) {
      return t("one_minute", { interval: Math.floor(interval) });
    }
    if (interval > 10) {
      return t("mins_under_ten", { interval: Math.floor(interval) });
    }
    return t("mins_over_ten", { interval: Math.floor(interval) });
  }

  if (interval > 10) {
    if (interval < 2) {
      return t("one_second", { interval: Math.floor(interval) });
    }
    return t("seconds_under_ten", { interval: Math.floor(interval) });
  }
  return t("seconds_over_ten", { interval: Math.floor(interval) });

  //   return t("{{interval}} seconds ago", { interval: Math.floor(interval) });
}
