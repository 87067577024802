import { ref, get, query, Database } from "firebase/database";
import { Form } from "types/Form";
import { auth } from "../firebase/firebase-config";

let db: Database;

import("context/firebase-db").then(({ database }) => {
  db = database;
});

export const getRole = async ({ userId, callback }) => {
  const formQuery = query(ref(db, `user/${userId}`));
  const snapshot = await get(formQuery);

  const data: { [key: string]: Form } = await snapshot?.val();

  if (data?.plan) return callback(data.plan);

  callback(false);

  return null;
};

export const getCustomClaimRole = async () => {
  if (!auth) {
    return undefined;
  }

  await auth?.currentUser?.getIdToken(true);
  const decodedToken = await auth?.currentUser?.getIdTokenResult();
  return decodedToken;
};
