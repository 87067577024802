export const pricing = {
  Pricing: "الاشتراكات",
  NoResponses: "لم تتلق أي ردود",
  NumberOfForms: "عدد النماذج",
  QuestionsPerForm: "عدد الأسئلة في النموذج",
  ResponsesPerMonth: "عدد الردود في الشهر",
  Quizzes: "الاختبارات",
  CSVExport: "تحميل بصيغة CSV",
  AvailableSpace: "المساحة المتوفرة",
  Support: "دعم عبر البريد الإلكتروني والهاتف على مدار الساعة",
  FormCreation: "إنشاء نموذج سين جيم",
  CloudStorage: "تخزين Google Cloud ",
  "Free.Description": "إنشاء نماذج تفاعلية وجميلة",
  // "Paid.Description": "ميزات متقدمة ودعم إضافي من خلال اشتراك البريميوم",
  "Paid.Description": "ميزات متقدمة ودعم إضافي",
  "Free.Header": "اشتراك مجاني",
  "Paid.Header": "اشتراك مدفوع",
  "Pricing.Header": "اختر الاشتراك الذي يناسبك",
  "Pricing.Subheader": "يمكنك دائماً البدء بالاشتراك المجاني ودون أي تكلفة",
  "Pricing.Question.1": "لماذا يجب أن أختار سين جيم؟",
  "Pricing.Answer.1":
    "بإمكانك ترقية اشتراكك، أو الرجوع إلى الاشتراك السابق، أو إلغاءه. ولتغيير اشتراكك، يرجى زبارة صفحة الدفع.",
  "Pricing.Question.2": "ضمان إعادة الأموال لمدة 30 يوماً",
  "Pricing.Answer.2":
    "إذا قمت بإلغاء اشتراكك في غضون 30 يوماً من الدفع، ستحصل على كافة الأموال المدفوعة - دون طرح أي أسئلة. يرجى التواصل مع فريق الدعم لتقديم طلب استرداد الأموال.",
  "Pricing.Question.3": "دعم العملاء على مدار الساعة طوال الأسبوع",
  "Pricing.Answer.3":
    "فريق الدعم لدينا متوفر على مدار الساعة طوال أيام الأسبوع لمساعدتكم وخدمتكم.",
  // "Pricing.Question.4": "ما هي الميزات التي يمكنني استخدامها بشكل مجاني؟",
  "Pricing.Question.4": "هل تعمل نماذج سين جيم على جميع الأجهزة؟",
  "Pricing.Answer.4":
    "تعمل نماذج سين جيم وتظهر بشكل رائع على أجهزة الهواتف، والأجهزة اللوحية (التابليت)، وأجهزة الحواسيب.",
  "Pricing.Question.5": "ما مدى أمان بياناتي؟",
  "Pricing.Answer.5":
    "يعد الحفاظ على أمان البيانات أمراً في غاية الأهمية بالنسبة لنا. فقد قمنا بإنشاء تقنيتنا على Google Cloud لتكون بياناتك مؤمنة بواسطة Google بمستويات متعددة من التشفير.",
  "Pricing.Question.6": "ماذا يمكنني أن أفعل ببياناتي بمجرد جمعها؟",
  "Pricing.Answer.6":
    "بإمكانك استعراض جميع الردود في جدول في صفحة “الردود”، أو استعراض تقرير ملخص في صفحة “النتائج”. إذا أردت تصدير بياناتك لاستخدام Google Sheets, Microsoft Excel, أو أي ملفات أخرى، عليك تحميل بياناتك في ملف بصيغة CSV.",
  month: "شهر",
  Upgrade: "Upgrade",
  Unlimited: "غير محدود",
  Features: "الخصائص",
  "Testimonial.1.Text": "[Testimonial Goes Here]",
  // " بإمكانك ترقية اشتراكك، أو الرجوع إلى الاشتراك السابق، أو إلغاءه. ولتغيير اشتراكك، يرجى زبارة صفحة الدفع.",
  "Testimonial.1.Name": "Layth Al-Qattan",
  "Testimonial.1.Title": "Founder",
  "Questions.CTA": "هل لديك أي أسئلة؟",
  "Questions.Description": "تواصل معنا عبر البريد الإلكتروني أو عبر الوتساب",
  SignUpNow: "اشتراك الآن",
};

export const pricingEnglish = {
  Pricing: "Pricing",
  NumberOfForms: "Number of Forms",
  QuestionsPerForm: "Number of Questions per Form",
  ResponsesPerMonth: "Number of Responses per Month",
  Quizzes: "Quizzes",
  CSVExport: "Export to CSV",
  AvailableSpace: "Available Storage",
  Support: "24/7 Email and Phone Support",
  FormCreation: "Support with Form Creation",
  CloudStorage: "Google Cloud Storage",
  "Free.Description": "Create beautiful and engaging forms",
  // "Paid.Description": "ميزات متقدمة ودعم إضافي من خلال اشتراك البريميوم",
  "Paid.Description": "Additional features and support",
  "Free.Header": "Basic",
  "Paid.Header": "Premium",
  "Pricing.Header": "Choose an option that works for you",
  "Pricing.Subheader": "Cancel your subscription anytime at no additional cost",
  "Pricing.Question.1": "Why should I choose SeenJeem?",
  "Pricing.Answer.1":
    "You can upgrade, cancel or downgrade your subscription any time. To change your subscription, go to the billing page.",
  "Pricing.Question.2": "30-day money back guarantee",
  "Pricing.Answer.2":
    "If you cancel your subscription within 30-days of payment, we will give you a gull refund - no questions asked. Please contact the support team to request a refund.",
  "Pricing.Question.3": "24/7 Customer support",
  "Pricing.Answer.3": "Our support team is available 24/7 to help you anytime.", // "Pricing.Question.4": "ما هي الميزات التي يمكنني استخدامها بشكل مجاني؟",
  "Pricing.Question.4": "Do SeenJeem forms work on all devices?",
  "Pricing.Answer.4":
    "SeenJeem forms work and look great on mobile, tablet and desktop devices",
  "Pricing.Question.5": "How is my data safe?",
  "Pricing.Answer.5":
    "The security of your data is extremely important to us. We’ve build all our technology on the Google Cloud, so your data is secured by Google with multiple levels of encryption.",
  "Pricing.Question.6": "What can I do with my data once it’s collected?",
  "Pricing.Answer.6":
    "You can see all your responses in table form under the ‘Responses’ tab, or see a summary report under the ‘Summary’ tab. If you want to export your data to use Google Sheets, Microsoft Excel or any others you can do that by exporting it to CSV format.",
  month: "month",
  Upgrade: "Upgrade",
  Unlimited: "Unlimited",
  Features: "Features",
  "Testimonial.1.Text": "[Testimonial Goes Here]",
  // " بإمكانك ترقية اشتراكك، أو الرجوع إلى الاشتراك السابق، أو إلغاءه. ولتغيير اشتراكك، يرجى زبارة صفحة الدفع.",
  "Testimonial.1.Name": "Layth Al-Qattan",
  "Testimonial.1.Title": "Founder",
  "Questions.CTA": "Any questions?",
  "Questions.Description": "Send us an email or WhatsApp message",
  SignUpNow: "Upgrade Now",
};
