import { FormFieldLogic, FormFieldLogicAction } from "types/Logic";
import { Answer, Form } from "types/Form";
import { OutcomeChoice } from "types/EndingOutcome";

// 1. format outcome logic
// 2. extract format logic
// 3. add/update logic actions

export const updateOutcomeLogicActions = ({
  formFieldLogic,
  action,
  choiceId,
  screenId,
}: {
  formFieldLogic: FormFieldLogic;
  action: FormFieldLogicAction;
  choiceId: string;
  screenId: string;
}) => {
  const existingActionForChoiceIndex = formFieldLogic.actions.findIndex(
    (logicAction) => {
      return (
        logicAction.details.target.value.endsWith(screenId) &&
        logicAction.condition.vars.find(
          (variable) =>
            variable.type === "choice" && variable.value === choiceId
        )
      );
    }
  );

  // 1. if field logic doesn't have action for choice x then add
  if (existingActionForChoiceIndex >= 0) {
    // added new ability to delete action if already exist
    formFieldLogic.actions.splice(existingActionForChoiceIndex, 1);

    // formFieldLogic.actions[existingActionForChoiceIndex] = action;
  } else {
    formFieldLogic.actions.push(action);
  }

  // 2. if field logic does have action for choice x, replace

  return formFieldLogic;
};

export const formatOutcomeLogicAction = ({
  fieldId,
  choiceId,
  variable,
  value,
}: {
  fieldId: string;
  choiceId: string;
  variable: string;
  value: number;
}): FormFieldLogicAction => {
  return {
    action: "add",
    condition: {
      op: "equals",
      vars: [
        {
          type: "field",
          value: fieldId,
        },
        {
          type: "choice",
          value: choiceId,
        },
      ],
    },
    details: {
      target: {
        type: "variable",
        value: variable,
      },
      value: {
        type: "constant",
        value,
      },
    },
  };
};

export const extractOutcomeLogic = (
  fieldId: string,
  formFieldLogic?: FormFieldLogic
): OutcomeChoice[] => {
  if (!formFieldLogic) return [];
  if (fieldId !== formFieldLogic.field_id) return [];
  // just finds first action
  const action = formFieldLogic.actions
    .filter((logicAction) => {
      return logicAction.details.target.value.startsWith("counter_");
    })
    .map((logicAction) => ({
      formId: logicAction.details.target.value.replace("counter_", ""),
      counterId: logicAction.details.target.value,
      choiceId: logicAction.condition.vars[1].value,
      fieldId: logicAction.condition.vars[0].value,
      counterValue: logicAction.details.value.value,
      counterAction: logicAction.action,
    }));

  return action;
};

export const extractOutcomeLogicByScreenId = (
  screenId: string,
  formFieldLogic: FormFieldLogic[] = []
): OutcomeChoice[] => {
  return formFieldLogic
    .filter((fieldLogic) =>
      fieldLogic.actions.some((action) =>
        action.details.target.value.startsWith("counter_")
      )
    )
    .map((fieldLogic) => {
      return {
        ...fieldLogic,
        actions: fieldLogic.actions.filter((action) =>
          action.details.target.value.endsWith(screenId)
        ),
      };
    })
    .flatMap((fieldLogic) => fieldLogic.actions)
    .map((logicAction) => ({
      formId: logicAction.details.target.value.replace("counter_", ""),
      counterId: logicAction.details.target.value,
      choiceId: logicAction.condition.vars[1].value,
      fieldId: logicAction.condition.vars[0].value,
      counterValue: logicAction.details.value.value,
      counterAction: logicAction.action,
    }));
};

export const changeFormOutcomeLogicHandler = ({
  form,
  fieldLogic,
}: {
  form: Form;
  fieldLogic: FormFieldLogic;
}) => {
  const updatedForm: Form = JSON.parse(JSON.stringify(form));

  if (!updatedForm.logic) updatedForm.logic = [];

  const logicIndex = updatedForm.logic.findIndex(
    (logic) => logic.field_id === fieldLogic.field_id
  );

  // 1. if no form logic for field, then add
  // 2. if form logic for field, then replace

  if (logicIndex >= 0) {
    updatedForm.logic[logicIndex] = fieldLogic;
  } else {
    updatedForm.logic.push(fieldLogic);
  }

  return updatedForm;
};
