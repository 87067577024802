import { Text, Flex, Spinner } from "@chakra-ui/react";
import BaseButton from "components/buttons/BaseButton";
import { UserRole } from "types/User";
import { useAuthState } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useCheckout } from "stripe/useCheckout";
import { useSiteContext } from "context/SiteContext";
import { logAnalyticsEvent } from "../../../firebase/firebase-analytics";
import { FeaturesMobile } from "./FeaturesMobile";
import usePricing, { Plan } from "../usePricing";

export default () => {
  const { userRole, openLoginModal, isAuthenticated } = useAuthState();
  const { plans } = usePricing();
  const navigate = useNavigate();

  const { handleCheckout, loadingStripe } = useCheckout();

  const handleBasic = () => {
    logAnalyticsEvent("click_basic_pricing");
    if (isAuthenticated) return navigate("/account/create");
    openLoginModal("/pricing");
  };

  return (
    <Flex
      flexDir={["column", "column", "row-reverse"]}
      gap={["10px", "10px", "10px", "80px"]}
      mx="auto"
    >
      {plans.map((plan, index) => {
        return (
          <Table
            handleBasic={handleBasic}
            handleCheckout={handleCheckout}
            key={index}
            plan={plan}
            userRole={userRole}
            loading={loadingStripe}
          />
        );
      })}
    </Flex>
  );
};

const Table = ({
  plan,
  handleBasic,
  handleCheckout,
  loading,
}: {
  plan: Plan;
  handleCheckout: () => void;
  handleBasic: () => void;
  userRole: UserRole | null;
  loading: boolean;
}) => {
  const { language } = useSiteContext();
  return (
    <Flex
      boxShadow="xl"
      m={4}
      p={2}
      border="1px"
      borderColor="gray.200"
      borderRadius="8px"
      flexDir="column"
      dir={language === "ar" ? "rtl" : "ltr"}
      fontWeight={300}
      flex="50%"
      minW="370px"
    >
      <Flex borderBottom="1px" borderColor="gray.200" flexDir="column">
        <Flex flex={1} p={4} flexDir="column" textAlign="start">
          <Text fontSize="24px" fontWeight={500}>
            {plan.label}
          </Text>
          <Flex alignItems="center" flex={1}>
            <Text fontSize={["16px", "18px"]} color="gray.600">
              {plan.description}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <FeaturesMobile plan={plan} />
      <Flex borderTop="1px" borderColor="gray.200" flexDir="column">
        <Flex p={4} flexDir="column" textAlign="right">
          {plan.name === UserRole.BASIC && (
            <BaseButton
              onClick={handleBasic}
              bgColor="teal.600"
              _hover={{
                bgColor: "teal.500",
              }}
            >
              <Text fontWeight={400}>{plan.CTA}</Text>
            </BaseButton>
          )}

          {plan.name === UserRole.PREMIUM && (
            <BaseButton
              w="100%"
              onClick={handleCheckout}
              bgColor="teal.600"
              _hover={{
                bgColor: "teal.500",
              }}
            >
              {loading ? <Spinner /> : <Text fontWeight={400}>{plan.CTA}</Text>}
            </BaseButton>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
