import { collection, getDoc, addDoc, onSnapshot } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { firestoreDb, functions, auth } from "../firebase/firebase-config";

export const subscribe = async ({ currentUser, setLoading }) => {
  setLoading(true);
  const selectedPrice = {
    quantity: 1,
    price: "price_1MRgiGIAYXkghEBoV8ynu6FG",
  };

  const checkoutSession = {
    line_items: [selectedPrice],
    success_url: `${window.location.origin}/account/create`,
    cancel_url: `${window.location.origin}/pricing`,
    mode: "subscription",
  };

  const docRef = await addDoc(
    collection(firestoreDb, "customers", currentUser, "checkout_sessions"),
    checkoutSession
  );

  const docSnap = await getDoc(docRef);

  onSnapshot(docRef, (data) => {
    if (docSnap.exists()) {
      const { url } = data.data() as any;
      if (url) {
        setLoading(false);
        window.location.assign(url);
      }
    } else {
      setLoading(false);
      console.log("No such document!");
    }
  });
};

export const billingPortal = async () => {
  const functionRef = httpsCallable(
    functions,
    "ext-firestore-stripe-payments-xghi-createPortalLink"
  );

  const { data } = await functionRef({ returnUrl: window.location.origin });

  window.location.assign((data as any).url);
};
