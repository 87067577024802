import uuid from "react-uuid";
import {
  EndingType,
  FieldType,
  Form,
  FormField,
  ThankYouScreen,
  WelcomeScreens,
} from "types/Form";

export const changeFormHandler = ({
  form,
  fieldAttribute,
  value,
  id,
  type,
}: {
  form: Form;
  fieldAttribute: string;
  value: any;
  id: string;
  type: string;
}) => {
  const updatedForm: Form = JSON.parse(JSON.stringify(form));
  let objIndex;

  if (type === FieldType.WELCOME_SCREEN) {
    objIndex = updatedForm.welcome_screens?.findIndex((obj) => obj.id === id);

    if (updatedForm.welcome_screens?.[objIndex]) {
      if (fieldAttribute === "title") {
        updatedForm.welcome_screens[objIndex].title = value;
      } else {
        updatedForm.welcome_screens[objIndex].properties = {
          ...updatedForm.welcome_screens[objIndex].properties,
          [fieldAttribute]: value,
        };
      }
    }
  } else if (type !== EndingType.THANK_YOU) {
    objIndex = updatedForm.fields?.findIndex((obj) => obj.field_id === id);

    if (updatedForm.fields?.[objIndex]) {
      if (fieldAttribute === "type") {
        if (value === FieldType.MULTIPLE_CHOICE) {
          updatedForm.fields[objIndex].type = value;
          updatedForm.fields[objIndex].properties = {
            ...updatedForm.fields[objIndex].properties,
            choices: [
              {
                id: uuid(),
                label: form?.language === "en" ? "Choice 1" : "الخيار ١",
              },
              {
                id: uuid(),
                label: form?.language === "en" ? "Choice 2" : "الخيار ٢",
              },
            ],
          };
        } else if (value === FieldType.SHORT_ANSWER) {
          updatedForm.fields[objIndex].type = value;
          delete updatedForm.fields[objIndex].properties.choices;
        }
      } else if (fieldAttribute === "title") {
        updatedForm.fields[objIndex].title = value;
      } else {
        updatedForm.fields[objIndex].properties = {
          ...updatedForm.fields[objIndex].properties,
          [fieldAttribute]: value,
        };
      }
    }
  } else {
    objIndex = updatedForm.thankyou_screens?.findIndex((obj) => obj.id === id);

    if (updatedForm.thankyou_screens?.[objIndex]) {
      if (fieldAttribute === "title") {
        updatedForm.thankyou_screens[objIndex].title = value;
      } else {
        updatedForm.thankyou_screens[objIndex].properties = {
          ...updatedForm.thankyou_screens[objIndex].properties,
          [fieldAttribute]: value,
        };
      }
    }
  }

  return updatedForm;
};

export const changeFormThemeHandler = ({
  form,
  fieldAttribute,
  value,
  type,
}: {
  form: Form;
  fieldAttribute: string;
  value: any;
  type: string;
}) => {
  const updatedForm: Form = JSON.parse(JSON.stringify(form));

  if (type === "font") {
    if (updatedForm.theme) {
      updatedForm.theme.font_family = value;
    } else {
      updatedForm.theme = {
        font_family: value,
      };
    }
  } else if (updatedForm.theme) {
    if (updatedForm.theme[type]) {
      updatedForm.theme[type] = {
        ...updatedForm.theme[type],
        [fieldAttribute]: value,
      };
    } else {
      updatedForm.theme[type] = {
        [fieldAttribute]: value,
      };
    }
  } else {
    updatedForm.theme = {
      [type]: {
        [fieldAttribute]: value,
      },
    };
  }

  return updatedForm;
};

export const isInputOrSelectionField = (
  field: FormField | ThankYouScreen | WelcomeScreens
) => {
  return (
    field.type !== FieldType.WELCOME_SCREEN && field.type !== FieldType.QUOTE
  );
};

export const getFieldIndexById = (
  fields: (FormField | ThankYouScreen | WelcomeScreens)[],
  fieldId: string
) => {
  return fields
    .filter((field) => isInputOrSelectionField(field as FormField))
    .findIndex((field) => (field as FormField).field_id === fieldId);
};

export const isThankYouScreen = (
  field: FormField | ThankYouScreen | WelcomeScreens
) => {
  return (
    field.type === EndingType.SCORE ||
    field.type === EndingType.THANK_YOU ||
    field.type === EndingType.THANK_YOU_CTA
  );
};

// didn't included multiple choice as we are having separate setting modal for it.
export const hasRequiredOption = (type: FieldType) =>
  [
    FieldType.EMAIL,
    FieldType.SHORT_ANSWER,
    FieldType.VOICE,
    FieldType.PHONE,
    FieldType.OPINION_SCALE,
  ].includes(type);

export function pickTextColorBasedOnBgColorSimple(
  bgColor,
  lightColor,
  darkColor
) {
  const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  const uicolors = [r / 255, g / 255, b / 255];
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return ((col + 0.055) / 1.055) ** 2.4;
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179 ? darkColor : lightColor;
}
