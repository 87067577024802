import uuid from "react-uuid";
import { Channel, EndingType, FieldType, Form, FormType } from "types/Form";
import { Alignment, FontSize, Theme } from "types/Theme";

export const DEFAULT_TITLE_COLOR = "#1A202C";
export const DEFULT_DESCRIPTION_COLOR = "#4A5568";
export const DEFULT_ANSWER_COLOR = "#4A5568";
export const DEFULT_BUTTON_COLOR = "#3D2399";
export const DEFULT_BUTTON_CONTENT_COLOR = "#ffffff";
export const DEFULT_BACKGROUND_COLOR = "#ffffff";

export const DEFAULT_SCREENS_FONT_SIZE = FontSize.SMALL;
export const DEFAULT_SCREENS_ALIGNMENT = Alignment.CENTER;

export const DEFAULT_FIELDS_FONT_SIZE = FontSize.SMALL;
export const DEFAULT_FIELDS_ALIGNMENT = Alignment.RIGHT;

export const DEFAULT_SELECTED_FONT_FAMILY = "Estedad";

export const THEME_COLORS = {
  QUESTION_COLORS: ["#3D2399"],
  ANSWER_COLORS: ["#4285F4"],
  BUTTON_COLORS: ["#4CAF50"],
  BUTTON_TEXT_COLORS: ["#00BCD4"],
  BACKGROUND_COLORS: ["#E1D8F1"],
};

export const THEME_1: Theme = {
  colors: {
    answer: DEFULT_ANSWER_COLOR,
    background: DEFULT_BACKGROUND_COLOR,
    button: DEFULT_BUTTON_COLOR,
    button_content: DEFULT_BUTTON_CONTENT_COLOR,
    question: DEFAULT_TITLE_COLOR,
  },
  fields: { alignment: Alignment.RIGHT, font_size: FontSize.SMALL },
  font_family: "Estedad",
  screens: {
    alignment: Alignment.RIGHT,
    font_size: FontSize.SMALL,
  },
};

export const THEME_2: Theme = {
  colors: {
    answer: "#FE4AD6",
    background: "#3D2399",
    button: "#FE4AD6",
    button_content: "#ffffff",
    question: "#FE4AD6",
  },
  fields: { alignment: Alignment.RIGHT, font_size: FontSize.SMALL },
  font_family: "Reem Kufi Fun",
  screens: {
    alignment: Alignment.RIGHT,
    font_size: FontSize.SMALL,
  },
};

export const THEME_3: Theme = {
  colors: {
    answer: "#3D2399",
    background: "#F3B244",
    button: "#3D2399",
    button_content: "#ffffff",
    question: "#3D2399",
  },
  fields: { alignment: Alignment.RIGHT, font_size: FontSize.SMALL },
  font_family: "Amiri",
  screens: {
    alignment: Alignment.RIGHT,
    font_size: FontSize.SMALL,
  },
};

export const INITIAL_FORM: Form = {
  user: "any_user_id",
  language: "ar",
  title: "صيغة جديدة",
  type: FormType.SURVEY,
  userType: "university",
  theme: THEME_1,
  fields: [
    {
      field_id: uuid(),
      title: "كيف كانت تجربتك؟",
      type: FieldType.OPINION_SCALE,
      properties: {
        description: "نود أن نسمع رأيك",
        choices: [
          { id: "0", label: "٠", value: 0 },
          { id: "1", label: "١", value: 1 },
          { id: "2", label: "٢", value: 2 },
          { id: "3", label: "٣", value: 3 },
          { id: "4", label: "٤", value: 4 },
          { id: "5", label: "٥", value: 5 },
        ],
        required: true,
      },
      active: true,
    },
  ],
  thankyou_screens: [
    {
      id: uuid(),
      title: "شكرا على المشاركة",
      type: EndingType.THANK_YOU,
      properties: {
        description: "",
        share_icons: true,
      },
      active: true,
    },
  ],
  createdAt: Date.now(),
  uid: "-NVEoiHEa6w8LxnnagjB-111",
};

export const FORM_RESPONSES = {
  "-NVEoiHEa6w8LxnnagjB-111": [
    ...Array.from({ length: 10 }).map((_, index) => ({
      answers: [
        {
          field_id: "b8a1840-dd75-4af5-7164-d027fbace44",
          type: "choices",
          value: {
            choices: [
              {
                id: "2",
                label: "٢",
                value: 2,
              },
            ],
          },
        },
        {
          field_id: "eab7165-131e-8fcc-16c8-1bb4888152de",
          type: "text",
          value: {
            text: `short answer ${index + 1}`,
          },
        },
        {
          field_id: "eab7165-131e-8fcc-16c8-1bb4888825d4",
          type: "email",
          value: {
            text: `example_${index + 1}@gmail.com`,
          },
        },
        {
          field_id: "46317e-3e5f-58f-18e2-42aa2b20b57d",
          type: "phone",
          value: {
            text: "11234567890",
          },
        },
        {
          field_id: "48ccbbd-573-a7f4-b4e5-405875437d2",
          type: "choices",
          value: {
            choices: [
              {
                id: "5ae08bc-eee0-7f1-afbb-b6f52c4edab",
                label: "الخيار ٢",
              },
            ],
          },
        },
        {
          field_id: "2206efe-1344-0e02-1c7b-6167efe00202",
          type: "date",
          value: {
            date: 1689100200000,
          },
        },
      ],
      form_id: "-NVEoiHEa6w8LxnnagjB-111",
      start_time: 1689232861314,
      submission_time: 1689232928436,
      response_id: "-N_D6yX-2_zQB3_FiYTH",
    })),
  ],
};

export const FORM_OBJ: Form = {
  createdAt: 1683892659381,
  editedAt: 1687763464438,
  fields: [
    {
      active: true,
      field_id: "b8a1840-dd75-4af5-7164-d027fbace44",
      properties: {
        choices: [
          {
            id: "0",
            label: "٠",
            value: 0,
          },
          {
            id: "1",
            label: "١",
            value: 1,
          },
          {
            id: "2",
            label: "٢",
            value: 2,
          },
          {
            id: "3",
            label: "٣",
            value: 3,
          },
          {
            id: "4",
            label: "٤",
            value: 4,
          },
          {
            id: "5",
            label: "٥",
            value: 5,
          },
        ],
        description: "نود أن نسمع رأيك",
        required: true,
      },
      title: "كيف كانت تجربتك؟",
      type: FieldType.OPINION_SCALE,
    },
    {
      active: true,
      field_id: "48ccbbd-573-a7f4-b4e5-405875437d2",
      properties: {
        choices: [
          {
            id: "467bad-b40-ebb2-b308-c3be5f83747",
            label: "الخيار ١",
          },
          {
            id: "5ae08bc-eee0-7f1-afbb-b6f52c4edab",
            label: "الخيار ٢",
          },
          {
            id: "116ec4-217c-dbc-1427-7a2ab3f88633",
            label: "الخيار ٣",
          },
          {
            id: "487768-007-6c65-3a8-10885aafb0e",
            label: "الخيار ٤",
          },
        ],
        description: "هذا النص هو مثال لنص يمكن أن يستبدل في نفس",
        multiple_selection: true,
        number_of_selection: 3,
        required: true,
      },
      title: "ما هي هوايتك؟",
      type: FieldType.MULTIPLE_CHOICE,
    },
    {
      active: true,
      field_id: "46317e-3e5f-58f-18e2-42aa2b20b57d",
      properties: {
        description: "هذا النص هو مثال لنص يمكن أن يستبدل في نفس",
        required: true,
      },
      title: "ما هو رقم هاتفك؟",
      type: FieldType.PHONE,
    },
    {
      active: true,
      field_id: "eab7165-131e-8fcc-16c8-1bb4888825d4",
      properties: {
        description: "هذا النص هو مثال لنص يمكن أن يستبدل في نفس",
        required: true,
      },
      title: "ما هو لونك المفضل؟",
      type: FieldType.EMAIL,
    },
    {
      active: true,
      field_id: "eab7165-131e-8fcc-16c8-1bb4888152de",
      properties: {
        description: "هذا النص هو مثال لنص يمكن أن يستبدل في نفس",
        required: true,
      },
      title: "ما هو لونك المفضل؟",
      type: FieldType.SHORT_ANSWER,
    },
    {
      active: true,
      field_id: "2206efe-1344-0e02-1c7b-6167efe00202",
      properties: {
        description: "هذا النص هو مثال لنص يمكن أن يستبدل في نفس",
      },
      title: "ما هو تاريخ ميلادك؟",
      type: FieldType.DATE,
    },
    {
      active: true,
      field_id: "3caeb44-154-0a2d-0126-b5ab27a5f2",
      properties: {
        description: "هذا النص هو مثال لنص يمكن أن يستبدل في نفس",
        required: true,
      },
      title: "هل يمكنك أن ترسل لي التسجيل؟",
      type: FieldType.VOICE,
    },
    {
      active: true,
      field_id: "4774d71-a1fc-df12-a0ee-a5dbba853f1d",
      properties: {
        description: "",
      },
      title: "Quote",
      type: FieldType.QUOTE,
    },
  ],
  language: "ar",
  links: [],
  logic: [
    {
      actions: [
        {
          action: "add",
          condition: {
            op: "equals",
            vars: [
              {
                type: "field",
                value: "b8a1840-dd75-4af5-7164-d027fbace44",
              },
              {
                type: "choice",
                value: "0",
              },
            ],
          },
          details: {
            target: {
              type: "variable",
              value: "counter_5af8d1-a836-0500-80-ae880e4efbaf",
            },
            value: {
              type: "constant",
              value: 1,
            },
          },
        },
        {
          action: "add",
          condition: {
            op: "equals",
            vars: [
              {
                type: "field",
                value: "b8a1840-dd75-4af5-7164-d027fbace44",
              },
              {
                type: "choice",
                value: "1",
              },
            ],
          },
          details: {
            target: {
              type: "variable",
              value: "counter_5af8d1-a836-0500-80-ae880e4efbaf",
            },
            value: {
              type: "constant",
              value: 1,
            },
          },
        },
      ],
      field_id: "b8a1840-dd75-4af5-7164-d027fbace44",
      type: "add",
    },
  ],
  thankyou_screens: [
    {
      active: true,
      id: "6346a33-1caf-7600-348d-bfb816daff1",
      properties: {
        description: "",
        share_icons: true,
      },
      title: "شكرا على المشاركة",
      type: EndingType.THANK_YOU,
    },
  ],
  theme: THEME_1,
  title: "Theme",
  type: FormType.QUIZ,
  uid: "-NVEoiHEa6w8LxnnagjB-111",
  user: "0HuxBpNi7za9wfI20TTxY9HlZ203",
  userType: "university",
  welcome_screens: [],
};

export const FORM_OBJ_WELCOME_THANKYOU: Form = {
  ...FORM_OBJ,
  welcome_screens: [
    {
      active: true,
      id: "0ab84f-206d-a7b0-bfb6-bc0c2dac6d8d",
      properties: {
        description: "This is me introducing you.",
      },
      ref: "0ab84f-206d-a7b0-bfb6-bc0c2dac6d8d",
      title: "Welcome to the world.",
      type: FieldType.WELCOME_SCREEN,
    },
  ],
  thankyou_screens: [
    {
      active: true,
      id: "6346a33-1caf-7600-348d-bfb816daff1",
      properties: {
        description: "",
        share_icons: true,
      },
      title: "شكرا على المشاركة",
      type: EndingType.THANK_YOU,
    },
  ],
};
