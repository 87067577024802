import { ref, update, push, Database } from "firebase/database";
import { EndingType, FieldType, Form, FormType } from "types/Form";
import { getAllLinks } from "utils/dynamicLinks/dynamicLinks";
import uuid from "react-uuid";
import { logAnalyticsEvent } from "../../firebase/firebase-analytics";
import {
  DEFAULT_FIELDS_ALIGNMENT,
  DEFAULT_FIELDS_FONT_SIZE,
  DEFAULT_SCREENS_ALIGNMENT,
  DEFAULT_SCREENS_FONT_SIZE,
  DEFAULT_SELECTED_FONT_FAMILY,
  DEFAULT_TITLE_COLOR,
  DEFULT_ANSWER_COLOR,
  DEFULT_BACKGROUND_COLOR,
  DEFULT_BUTTON_COLOR,
  DEFULT_BUTTON_CONTENT_COLOR,
} from "./formConstants";
import { TemplateOptions } from "../../components/modals/NewFormModal/NewFormTemplateModal/TemplateOptions";

let db: Database;

import("context/firebase-db").then(({ database }) => {
  db = database;
});

export const addForm = async ({
  userId,
  formTitle,
  selectedForm,
}: {
  userId: string;
  formTitle: string;
  selectedForm?: TemplateOptions;
}) => {
  const usersRef = ref(db, `user/${userId}/forms`);
  const formsRef = ref(db, "form");

  let formFunction: (args: { user: string; title: string }) => Form;

  switch (selectedForm) {
    case "customersSatisfaction":
      formFunction = customersSatisfactionForm;
      break;
    case "leaveOne":
      formFunction = leaveOneForm;
      break;
    case "leaveTwo":
      formFunction = leaveTwoForm;
      break;
    case "employment":
      formFunction = employmentForm;
      break;
    case "volunteer":
      formFunction = volunteerForm;
      break;
    case "effectiveness":
      formFunction = effectivenessForm;
      break;
    case "chat":
      formFunction = newDefaultChatForm;
      break;
    default:
      formFunction = newDefaultForm;
  }

  const form = formFunction({
    user: userId,
    title: formTitle,
  });
  // permission errors can be caught here
  const formKey = await push(formsRef, form);
  if (!formKey?.key) return;

  const links = await getAllLinks(
    "SeenJeem",
    "أول أداة إلكترونية عربية لتصميم الاستبيانات بسرعة وسهولة",
    formKey.key,
    selectedForm === FormType.CHAT
  ).catch((err) => {
    logAnalyticsEvent("getAllLinks_fail", { value: err });
  });

  if (links) {
    await update(formKey, { links });
  }

  await update(usersRef, { [formKey.key as string]: true });

  logAnalyticsEvent("new_form", {
    value: formKey.key,
  });

  return formKey.key;
};

const newDefaultChatForm = ({
  user,
  title,
}: {
  user: string;
  title: string;
}): Form => {
  const welcomeScreenId = uuid();
  return {
    overview: "محادثة لجمع بعض المعلومات الشخصية من المستخدم",
    type: FormType.CHAT,
    user,
    language: "ar",
    title,
    theme: getDefaultTheme(),
    welcome_screens: [
      {
        active: true,
        id: welcomeScreenId,
        properties: {
          description: "",
        },
        ref: welcomeScreenId,
        title: "مرحباً",
        type: FieldType.WELCOME_SCREEN,
      },
    ],
    fields: [
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "الاسم",
        type: FieldType.SHORT_ANSWER,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "البريد الإلكتروني",
        type: FieldType.SHORT_ANSWER,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "الهاتف",
        type: FieldType.SHORT_ANSWER,
      },
    ],
    thankyou_screens: [
      {
        id: uuid(),
        title: "شكرا على المشاركة",
        type: EndingType.THANK_YOU,
        properties: {
          description: "",
          share_icons: true,
        },
        active: true,
      },
    ],
    createdAt: Date.now(),
  };
};

const newDefaultForm = ({
  user,
  title,
}: {
  user: string;
  title: string;
}): Form => {
  return {
    user,
    language: "ar",
    title,
    theme: getDefaultTheme(),
    fields: [
      {
        field_id: uuid(),
        title: "كيف كانت تجربتك؟",
        type: FieldType.OPINION_SCALE,
        properties: {
          description: "نود أن نسمع رأيك",
          choices: [
            { id: "0", label: "٠", value: 0 },
            { id: "1", label: "١", value: 1 },
            { id: "2", label: "٢", value: 2 },
            { id: "3", label: "٣", value: 3 },
            { id: "4", label: "٤", value: 4 },
            { id: "5", label: "٥", value: 5 },
          ],
          required: true,
        },
        active: true,
      },
    ],
    thankyou_screens: [
      {
        id: uuid(),
        title: "شكرا على المشاركة",
        type: EndingType.THANK_YOU,
        properties: {
          description: "",
          share_icons: true,
        },
        active: true,
      },
    ],
    createdAt: Date.now(),
  };
};

export const getDefaultTheme = (form?: Form) => {
  const existingTheme = form?.theme ?? {};
  return {
    font_family: DEFAULT_SELECTED_FONT_FAMILY,
    created_at: Date.now(),
    colors: {
      question: DEFAULT_TITLE_COLOR,
      answer: DEFULT_ANSWER_COLOR,
      button: DEFULT_BUTTON_COLOR,
      button_content: DEFULT_BUTTON_CONTENT_COLOR,
      background: DEFULT_BACKGROUND_COLOR,
      ...(existingTheme?.colors ?? {}),
    },
    fields: {
      font_size: DEFAULT_FIELDS_FONT_SIZE,
      alignment: DEFAULT_FIELDS_ALIGNMENT,
      ...(existingTheme?.fields ?? {}),
    },
    screens: {
      font_size: DEFAULT_SCREENS_FONT_SIZE,
      alignment: DEFAULT_SCREENS_ALIGNMENT,
      ...(existingTheme?.screens ?? {}),
    },
    ...existingTheme,
  };
};

const customersSatisfactionForm = ({
  user,
  title,
}: {
  user: string;
  title: string;
}): Form => {
  return {
    user,
    language: "ar",
    title,
    theme: getDefaultTheme(),
    fields: [
      {
        field_id: uuid(),
        title: "أنا …؟",
        type: FieldType.MULTIPLE_CHOICE,
        properties: {
          description: "",
          choices: [
            { id: uuid(), label: "عميل جديد" },
            { id: uuid(), label: "عميل سابق" },
            {
              id: uuid(),
              label: "لست عميلا، ولكنني أفكر بأن أصبح أحد عملائكم",
            },
            { id: uuid(), label: "لست عميلا، ولا أفكر بأن أصبح كذلك" },
          ],
          required: true,
          number_of_selection: 1,
          multiple_selection: false,
        },
        active: true,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "بشكل عام، ما مدى رضاك عن المنتج/الخدمة؟",
        type: FieldType.SHORT_ANSWER,
      },
      {
        field_id: uuid(),
        title: "على مقياس 1-5، ما مدى رضاك عن المنتج/الخدمة؟",
        type: FieldType.OPINION_SCALE,
        properties: {
          description: "",
          choices: [
            { id: "0", label: "٠", value: 0 },
            { id: "1", label: "١", value: 1 },
            { id: "2", label: "٢", value: 2 },
            { id: "3", label: "٣", value: 3 },
            { id: "4", label: "٤", value: 4 },
            { id: "5", label: "٥", value: 5 },
          ],
          required: true,
        },
        active: true,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "ماذا يعجبك بالمنتج/الخدمة؟",
        type: FieldType.SHORT_ANSWER,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title:
          "برأيك، ما الذي يمكننا القيام به لتحسين المنتج/الخدمة بشكل أكبر؟",
        type: FieldType.SHORT_ANSWER,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title:
          "بناءً على خبرتك وتجربتك معنا، هل ستقوم باستخدام المنتج/الخدمة في المستقبل أو التوصية به؟",
        type: FieldType.SHORT_ANSWER,
      },
      {
        field_id: uuid(),
        title: "على مقياس 1-5، يرجى تقييم خدمة العملاء",
        type: FieldType.OPINION_SCALE,
        properties: {
          description: "",
          choices: [
            { id: "0", label: "٠", value: 0 },
            { id: "1", label: "١", value: 1 },
            { id: "2", label: "٢", value: 2 },
            { id: "3", label: "٣", value: 3 },
            { id: "4", label: "٤", value: 4 },
            { id: "5", label: "٥", value: 5 },
          ],
          required: true,
        },
        active: true,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "هل لديك اقتراحات أخرى؟",
        type: FieldType.SHORT_ANSWER,
      },
    ],
    thankyou_screens: [
      {
        id: uuid(),
        title: "نشكرك على وقتك",
        type: EndingType.THANK_YOU,
        properties: {
          description: "",
          share_icons: true,
        },
        active: true,
      },
    ],
    createdAt: Date.now(),
  };
};

const leaveOneForm = ({
  user,
  title,
}: {
  user: string;
  title: string;
}): Form => {
  return {
    user,
    language: "ar",
    title,
    theme: getDefaultTheme(),
    fields: [
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "الاسم",
        type: FieldType.SHORT_ANSWER,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: false,
        },
        title: "الرقم الوظيفي (اختياري)",
        type: FieldType.SHORT_ANSWER,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: false,
        },
        title: "المسمى الوظيفي",
        type: FieldType.SHORT_ANSWER,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "رقم الهاتف",
        type: FieldType.PHONE,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
        },
        title: "تاريخ اليوم",
        type: FieldType.DATE,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: false,
        },
        title: "ساعة المغادرة",
        type: FieldType.SHORT_ANSWER,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: false,
        },
        title: "ساعة العودة",
        type: FieldType.SHORT_ANSWER,
      },
    ],
    thankyou_screens: [
      {
        id: uuid(),
        title: "نشكرك على تعبئة الطلب",
        type: EndingType.THANK_YOU,
        properties: {
          description: "",
          share_icons: true,
        },
        active: true,
      },
    ],
    createdAt: Date.now(),
  };
};

const leaveTwoForm = ({
  user,
  title,
}: {
  user: string;
  title: string;
}): Form => {
  return {
    user,
    language: "ar",
    title,
    theme: getDefaultTheme(),
    fields: [
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "الاسم",
        type: FieldType.SHORT_ANSWER,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: false,
        },
        title: "الرقم الوظيفي (اختياري)",
        type: FieldType.SHORT_ANSWER,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: false,
        },
        title: "المسمى الوظيفي",
        type: FieldType.SHORT_ANSWER,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "رقم الهاتف",
        type: FieldType.PHONE,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
        },
        title: "تاريخ اليوم",
        type: FieldType.DATE,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
        },
        title: "تاريخ بدء الإجازة",
        type: FieldType.DATE,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
        },
        title: "تاريخ انتهاء الإجازة",
        type: FieldType.DATE,
      },
      {
        field_id: uuid(),
        title: "أسباب الإجازة",
        type: FieldType.MULTIPLE_CHOICE,
        properties: {
          description: "",
          choices: [
            { id: uuid(), label: "إجازة سنوية" },
            { id: uuid(), label: "إجازة طارئة" },
            {
              id: uuid(),
              label: "إجازة مرضية",
            },
            { id: uuid(), label: "إجازة بدون راتب" },
          ],
          required: true,
          number_of_selection: 1,
          multiple_selection: false,
        },
        active: true,
      },
    ],
    thankyou_screens: [
      {
        id: uuid(),
        title: "نشكرك على تعبئة الطلب",
        type: EndingType.THANK_YOU,
        properties: {
          description: "",
          share_icons: true,
        },
        active: true,
      },
    ],
    createdAt: Date.now(),
  };
};

const employmentForm = ({
  user,
  title,
}: {
  user: string;
  title: string;
}): Form => {
  return {
    user,
    language: "ar",
    title,
    theme: getDefaultTheme(),
    fields: [
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "الوظيفة المطلوبة",
        type: FieldType.SHORT_ANSWER,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
        },
        title: "البيانات الشخصية",
        type: FieldType.QUOTE,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "الاسم",
        type: FieldType.SHORT_ANSWER,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
        },
        title: "تاريخ الميلاد",
        type: FieldType.DATE,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "الجنسية",
        type: FieldType.SHORT_ANSWER,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "رقم الهاتف",
        type: FieldType.PHONE,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "البريد الإلكتروني",
        type: FieldType.EMAIL,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "المدينة",
        type: FieldType.SHORT_ANSWER,
      },
      {
        field_id: uuid(),
        title: "هل تعمل حالياً؟",
        type: FieldType.MULTIPLE_CHOICE,
        properties: {
          description: "",
          choices: [
            { id: uuid(), label: "نعم" },
            { id: uuid(), label: "لا" },
          ],
          required: true,
          number_of_selection: 1,
          multiple_selection: false,
        },
        active: true,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
        },
        title: "المؤهلات التعليمية",
        type: FieldType.QUOTE,
      },
      {
        field_id: uuid(),
        title: "المؤهل العلمي",
        type: FieldType.MULTIPLE_CHOICE,
        properties: {
          description: "",
          choices: [
            { id: uuid(), label: "مدرسة" },
            { id: uuid(), label: "دبلوم" },
            { id: uuid(), label: "بكالوريوس" },
            { id: uuid(), label: "ماجستير" },
            { id: uuid(), label: "دكتوراه" },
          ],
          required: true,
          number_of_selection: 1,
          multiple_selection: false,
        },
        active: true,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "الجامعة/المدرسة",
        type: FieldType.SHORT_ANSWER,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "التخصص",
        type: FieldType.SHORT_ANSWER,
      },
      {
        field_id: uuid(),
        title: "التقدير",
        type: FieldType.MULTIPLE_CHOICE,
        properties: {
          description: "",
          choices: [
            { id: uuid(), label: "ضعيف" },
            { id: uuid(), label: "مقبول" },
            { id: uuid(), label: "جيد" },
            { id: uuid(), label: "جيد جداً" },
            { id: uuid(), label: "ممتاز" },
          ],
          required: true,
          number_of_selection: 1,
          multiple_selection: false,
        },
        active: true,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
        },
        title: "الخبرات العملية",
        type: FieldType.QUOTE,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title:
          "يرجى ذكر اسم المؤسسة/الشركة، وسنوات الخبرة، والمسمى الوظيفي، ونبذة قصيرة عن طبيعة العمل",
        type: FieldType.SHORT_ANSWER,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "المهارات العملية",
        type: FieldType.SHORT_ANSWER,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "اللغات",
        type: FieldType.SHORT_ANSWER,
      },
    ],
    thankyou_screens: [
      {
        id: uuid(),
        title: "نشكرك على وقتك. سنتواصل معك قريباً",
        type: EndingType.THANK_YOU,
        properties: {
          description: "",
          share_icons: true,
        },
        active: true,
      },
    ],
    createdAt: Date.now(),
  };
};

const volunteerForm = ({
  user,
  title,
}: {
  user: string;
  title: string;
}): Form => {
  return {
    user,
    language: "ar",
    title,
    theme: getDefaultTheme(),
    fields: [
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "الاسم",
        type: FieldType.SHORT_ANSWER,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
        },
        title: "تاريخ الميلاد",
        type: FieldType.DATE,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "الجنسية",
        type: FieldType.SHORT_ANSWER,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "رقم الهاتف",
        type: FieldType.PHONE,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "البريد الإلكتروني",
        type: FieldType.EMAIL,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "المدينة",
        type: FieldType.SHORT_ANSWER,
      },
      {
        field_id: uuid(),
        title: "هل تطوعت مسبقاً؟",
        type: FieldType.MULTIPLE_CHOICE,
        properties: {
          description: "",
          choices: [
            { id: uuid(), label: "نعم" },
            { id: uuid(), label: "لا" },
          ],
          required: true,
          number_of_selection: 1,
          multiple_selection: false,
        },
        active: true,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: false,
        },
        title: "إذا أجبت بنعم، أخبرنا عن خبرتك التطوعية السابقة",
        type: FieldType.SHORT_ANSWER,
      },
      {
        field_id: uuid(),
        title: "ما هي الأيام التي ترغب بالتطوع بها؟",
        type: FieldType.MULTIPLE_CHOICE,
        properties: {
          description: "",
          choices: [
            { id: uuid(), label: "الأحد" },
            { id: uuid(), label: "الاثنين" },
            { id: uuid(), label: "الثلاثاء" },
            { id: uuid(), label: "الأربعاء" },
            { id: uuid(), label: "الخميس" },
            { id: uuid(), label: "الجمعة" },
            { id: uuid(), label: "السبت" },
          ],
          required: true,
          number_of_selection: 1,
          multiple_selection: true,
        },
        active: true,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "أخبرنا عن مهاراتك بشكل مختصر",
        type: FieldType.SHORT_ANSWER,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "اللغات",
        type: FieldType.SHORT_ANSWER,
      },
    ],
    thankyou_screens: [
      {
        id: uuid(),
        title: "نشكرك على وقتك. سنتواصل معك قريباً",
        type: EndingType.THANK_YOU,
        properties: {
          description: "",
          share_icons: true,
        },
        active: true,
      },
    ],
    createdAt: Date.now(),
  };
};

const effectivenessForm = ({
  user,
  title,
}: {
  user: string;
  title: string;
}): Form => {
  return {
    user,
    language: "ar",
    title,
    theme: getDefaultTheme(),
    fields: [
      {
        field_id: uuid(),
        title: "على مقياس 1-10، ما مدى رضاك عن الفعالية؟",
        type: FieldType.OPINION_SCALE,
        properties: {
          description: "",
          choices: [
            { id: "0", label: "٠", value: 0 },
            { id: "1", label: "١", value: 1 },
            { id: "2", label: "٢", value: 2 },
            { id: "3", label: "٣", value: 3 },
            { id: "4", label: "٤", value: 4 },
            { id: "5", label: "٥", value: 5 },
            { id: "6", label: "٦", value: 6 },
            { id: "7", label: "٧", value: 7 },
            { id: "8", label: "٨", value: 8 },
            { id: "9", label: "٩", value: 9 },
            { id: "10", label: "١٠", value: 10 },
          ],
          required: true,
        },
        active: true,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title: "ما هي أكثر الأشياء التي أعجبتك في الفعالية؟",
        type: FieldType.SHORT_ANSWER,
      },
      {
        field_id: uuid(),
        title: "هل توافقت توقعاتك مع ما قدمته الفعالية؟",
        type: FieldType.MULTIPLE_CHOICE,
        properties: {
          description: "",
          choices: [
            { id: uuid(), label: "نعم" },
            { id: uuid(), label: "إلى حدٍ ما" },
            { id: uuid(), label: "لا" },
          ],
          required: true,
          number_of_selection: 1,
          multiple_selection: false,
        },
        active: true,
      },
      {
        active: true,
        field_id: uuid(),
        properties: {
          description: "",
          required: true,
        },
        title:
          "هل لديك أي اقتراحات أخرى من شأنها أن تساعدنا في تحسين الفعاليات المستقبلية؟",
        type: FieldType.SHORT_ANSWER,
      },
    ],
    thankyou_screens: [
      {
        id: uuid(),
        title: "نشكر حضورك ووقتك ونتمى أن نراك في المرات القادمة",
        type: EndingType.THANK_YOU,
        properties: {
          description: "",
          share_icons: true,
        },
        active: true,
      },
    ],
    createdAt: Date.now(),
  };
};
