import { FormFieldLogic } from "./Logic";
import { Theme } from "./Theme";

export enum Channel {
  WHATSAPP = "whatsapp",
  FACEBOOK = "facebook",
  LINKEDIN = "linkedin",
  TWITTER = "twitter",
  DIRECT = "direct",
  QRCODE = "qrcode",
}

export interface FormField {
  field_id: string;
  title: string;
  type: FieldType;
  properties: FormFieldProperties;
  active: boolean;
}

export interface FormFieldProperties {
  description: string;
  required?: boolean;
  multiple_selection?: boolean;
  number_of_selection?: number;
  choices?: FormChoice[];
}

export interface FormChoice {
  id: string;
  label: string;
  value?: number;
}

export interface ThankYouScreen {
  id: string;
  ref?: string;
  title: string;
  type: EndingType; // e.g. "thankyou_screen";
  properties: {
    description: string;
    share_icons?: boolean;
    show_cta?: boolean;
    show_success_icon?: boolean;
    hide_logo?: boolean;
    right_align?: boolean;
  };
  active: boolean;
}

export interface WelcomeScreens {
  id: string;
  ref: string; // ?
  title: string;
  type: FieldType; // e.g. "thankyou_screen";
  properties: {
    description: string;
  };
  active: boolean;
}

export enum FormType {
  SURVEY = "survey",
  SCORE = "score",
  QUIZ = "quiz",
  RESEARCH = "research",
  REGISTRATION = "registration",
  POLL = "poll",
  SALE = "sale",
  FEEDBACK = "feedback",
  CONTACT = "contact",
  APPLICATION = "application",
  OTHER = "other",
  CHAT = "chat",
}
export interface Form {
  overview?: string;
  uid?: string;
  language: string;
  title: string;
  theme?: Theme;
  welcome_screens?: WelcomeScreens[];
  fields?: FormField[];
  thankyou_screens?: ThankYouScreen[];
  variables?: Variables;
  logic?: FormFieldLogic[];
  outcome?: {
    variable: string; // e.g. winning_outcome_id
    choices: {
      id: string;
      ref: string; // e.g. thankyou_screen_id
      title: string;
      counter_variable: string; // e.g. variable_id for counter variable
      thankyou_screen_ref: string; // e.g. thankyou_screen_id
    }[];
  };
  phoneNumber?: string;
  user: string;
  createdAt: number;
  editedAt?: number;
  type?: FormType;
  userType?: string;
  links?: FormLink[];
  disabled?: boolean | undefined;
  wspNotificationEnabled?: boolean | undefined;
}

export type FormLink = {
  channel: Channel;
  link: string;
};

export type Variables = { [key: string]: number };

export interface ChoicesAnswer extends BaseAnswer {
  type: "choices";
  value: {
    choices: FormChoice[];
  };
}

export interface TextAnswer extends BaseAnswer {
  type: "text";
  value: {
    text: string;
  };
}

export interface VoiceAnswer extends BaseAnswer {
  type: "voice";
  value: {
    source: string;
    duration?: number;
  };
}

export interface DateAnswer extends BaseAnswer {
  type: "date";
  value: {
    date: number;
  };
}

export interface WhatsAppAnswer extends BaseAnswer {
  type: "whatsapp";
  value: {
    choice: {
      id: string;
      label: string;
      value?: number;
    };
  };
}

interface EmailAnswer extends BaseAnswer {
  type: "email";
  value: {
    text: string;
  };
}
export interface PhoneAnswer extends BaseAnswer {
  type: "phone";
  value: {
    text: string;
  };
}
export interface BaseAnswer {
  field_id: string;
}

export type Answer =
  | ChoicesAnswer
  | TextAnswer
  | WhatsAppAnswer
  | VoiceAnswer
  | EmailAnswer
  | DateAnswer
  | PhoneAnswer;

export enum ResponseType {
  CHAT = "chat",
}

interface ChatMessage {
  role: "system" | "user" | "assistant";
  content: string;
}

export interface Response {
  response_id: string;
  answers: Answer[];
  start_time: number;
  submission_time?: number;
  form_id: string;
  variables?: {
    key: string; // e.g. counter_id or winning_outcome_id
  };
  outcome?: {
    id: string; // outcome_id
    title: string;
  };
  number?: string;
  type?: ResponseType;
  message_list?: ChatMessage[];
}

export interface FormResponses {
  [key: string]: Response[];
}

export enum FieldType {
  WELCOME_SCREEN = "welcome_screen",
  QUOTE = "quote",
  WHATSAPP = "whatsapp",
  OPINION_SCALE = "opinion_scale",
  MULTIPLE_CHOICE = "multiple_choice",
  SHORT_ANSWER = "short_answer",
  VOICE = "voice",
  EMAIL = "email",
  PHONE = "phone",
  DATE = "date",
}

export enum EndingType {
  THANK_YOU = "thankyou_screen",
  SCORE = "score",
  THANK_YOU_CTA = "thankyou_screen_cta",
  THEME_SETTING = "theme_settings",
}
