import { User } from "firebase/auth";
import { Database, ref, get, query } from "firebase/database";
import { httpsCallable } from "firebase/functions";
import { Form } from "types/Form";
import { setWhatsappSurveyComplete } from "components/modals/SurveyModal/utils";
import { functions } from "../firebase/firebase-config";

let db: Database;

import("context/firebase-db").then(({ database }) => {
  db = database;
});

export const startConversationHandler = async ({ user }: { user: User }) => {
  const startConversation = httpsCallable(functions, "startConversation");

  const userQuery = query(ref(db, `user/${user.uid}`));
  const snapshot = await get(userQuery);

  const data: { [key: string]: Form } = await snapshot.val();

  const { number } = data;

  if (number) {
    startConversation({ name: user.displayName, number })
      .then((result) => {
        setWhatsappSurveyComplete(user.uid);
      })
      .catch((err) => console.log({ err }));
  }
};

export const trySendWspNotification = async (
  formId: string
): Promise<boolean> => {
  const wspNotify = httpsCallable<Object, any>(functions, "wspNotify");
  await wspNotify({ formId });
  return true;
};
