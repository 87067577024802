import { Flex } from "@chakra-ui/react";
import { ReactComponent as DateIcon } from "icons/date.svg";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { DEFULT_ANSWER_COLOR } from "utils/addForm/formConstants";

const DateField = ({
  isStatic,
  fontFamily,
  fontSize,
  language,
  textColor,
  borderColor,
  onChange,
  date,
}: {
  isStatic?: boolean;
  date?: Date;
  fontFamily?: string;
  fontSize?: string;
  language?: string;
  borderColor?: string;
  textColor?: string;
  onChange?: (date: Date) => void;
}) => {
  return (
    <Flex
      as="section"
      position="relative"
      borderWidth={1}
      borderColor={borderColor ?? "gray.200"}
      maxWidth="200px"
      alignItems="center"
      pe={3}
      borderRadius="6px"
      cursor={!isStatic ? "not-allowed" : undefined}
    >
      <Flex flex={1}>
        <SingleDatepicker
          date={date}
          onDateChange={(newDate) => {
            if (onChange) {
              onChange(newDate);
            }
          }}
          disabled={!isStatic}
          configs={{
            dateFormat: "dd/MMM/yyyy",
          }}
          propsConfigs={{
            inputProps: {
              width: "100%",
              maxWidth: "200px",
              borderWidth: 0,
              fontFamily,
              textColor,
              fontSize,
              height: "auto",
              minH: "40px",
              _focus: {
                outline: 0,
              },
            },
            popoverCompProps: {
              popoverContentProps: {
                maxWidth: "320px",
              },
              popoverBodyProps: {
                borderStyle: "none",
              },
            },
          }}
        />
      </Flex>
      <DateIcon stroke={textColor ?? DEFULT_ANSWER_COLOR} />
    </Flex>
  );
};
export default DateField;
