import { Flex, Button } from "@chakra-ui/react";
import { useFormContext } from "context/FormContext";
import { useStaticFormContext } from "context/StaticFormContext";
import { FormField, Answer } from "types/Form";
import {
  DEFULT_BUTTON_COLOR,
  DEFULT_BUTTON_CONTENT_COLOR,
} from "utils/addForm/formConstants";
import { OpinionScaleButton } from "./OpinionScaleButton";
import { isChoiceSelected } from "./utils";

export const OpinionScaleButtons = ({ field }: { field: FormField }) => {
  const { answers, setAnswers } = useStaticFormContext();
  const { form } = useFormContext();

  const buttonTextColor = form?.theme?.colors?.button_content;
  const buttonBackgroundColor = form?.theme?.colors?.button;
  const fontFamily = form?.theme?.font_family;

  const isDifferentButtonContentColor =
    buttonTextColor !== DEFULT_BUTTON_CONTENT_COLOR;

  return (
    <Flex
      justifyContent="space-between"
      alignSelf="start"
      mb={0}
      w="100%"
      flexDir="row"
      gap="3px"
      maxW="300px"
    >
      {field.properties.choices?.map(({ id, label, value }) => {
        const isSelected = isChoiceSelected({ id, answers, field });

        return (
          <OpinionScaleButton
            key={id}
            isSelected={isSelected}
            buttonTextColor={
              isDifferentButtonContentColor ? buttonTextColor : undefined
            }
            buttonBackgroundColor={buttonBackgroundColor}
            fontFamily={fontFamily}
            label={label}
            onClick={() => {
              if (answers && setAnswers) {
                const objIndex = answers.findIndex(
                  (obj) => obj.field_id === field.field_id
                );

                const newAnswer: Answer = {
                  field_id: field.field_id,
                  type: "choices",
                  value: {
                    choices: [
                      {
                        id,
                        label,
                        value,
                      },
                    ],
                  },
                };

                if (objIndex < 0) {
                  setAnswers([...answers, newAnswer]);
                } else {
                  answers[objIndex] = newAnswer;
                  setAnswers([...answers]);
                }
              }
            }}
          />
        );
      })}
    </Flex>
  );
};
