import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";

export const analytics = getAnalytics();

export const logAnalyticsEvent = (
  event: string,
  params?: { [key: string]: any }
) => {
  if (process.env.NODE_ENV === "development") return;
  logEvent(analytics, event, params);
};

export const setUserProperty = (property: { [key: string]: string }) => {
  if (process.env.NODE_ENV === "development") return;
  setUserProperties(analytics, property);
};
