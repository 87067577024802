/* eslint-disable react/jsx-no-constructed-context-values */
import {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";
import { ref, push, update } from "firebase/database";
import { trySendWspNotification } from "utils/whatsApp";
import { Answer } from "../types/Form";
import { logAnalyticsEvent } from "../firebase/firebase-analytics";

let db;

import("./firebase-db").then(({ database }) => {
  db = database;
});

export const StaticFormContext = createContext<{
  answers: Answer[];
  setAnswers: Dispatch<SetStateAction<Answer[]>>;
  onSaveResponse: () => void;
  mountForm: (formId?: string, userId?: string) => void;
} | null>(null);

export const StaticFormContextProvider = (props) => {
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [responseKey, setResponseKey] = useState<string | null>(null);

  const modifyPhoneNumberResponse = (copyAnswersObjAry: Answer[]) => {
    copyAnswersObjAry.forEach((element) => {
      if (element.type === "phone") {
        element.value.text = element.value.text.replace("+", "");
      }
    });
    return copyAnswersObjAry;
  };

  const onSaveResponse = () => {
    const objIndex = answers.findIndex((obj) => obj.type === "phone");
    let requestParemeter = {};
    if (objIndex) {
      requestParemeter = {
        value: JSON.stringify(modifyPhoneNumberResponse(answers)),
      };
    } else {
      requestParemeter = { value: JSON.stringify(answers) };
    }
    logAnalyticsEvent("save_field", requestParemeter);
    update(ref(db, `response/${responseKey}`), {
      answers,
      submission_time: Date.now(),
    }).catch((error) => console.log({ error }));
  };

  const mountForm = (formId: string, userId?: string) => {
    logAnalyticsEvent("mount_form", { value: formId });
    push(ref(db, `response/`), {
      form_id: formId,
      start_time: Date.now(),
      ...(userId && {
        variables: {
          user_id: userId,
        },
      }),
    }).then(async (data) => {
      setResponseKey(data.key);
      await trySendWspNotification(formId);
    });
  };

  return (
    <StaticFormContext.Provider
      value={{
        answers,
        setAnswers,
        onSaveResponse,
        mountForm,
      }}
      {...props}
    />
  );
};

export const useStaticFormContext = () => {
  const formContextData = useContext(StaticFormContext);

  return {
    ...formContextData,
  };
};
