/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useState, useEffect, useContext } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { useAuthState } from "context/AuthContext";
import {
  isOnboardingComplete,
  setOnboardingComplete,
} from "./OnboardingFormContext.utils";
import { logAnalyticsEvent } from "../../firebase/firebase-analytics";

export const OnboardingFormContext = createContext<{
  onClose: () => void;
  isOpen: boolean;
} | null>(null);

export const OnboardingFormContextProvider = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useAuthState();

  const [onboardingCompleteState, setOnboardingCompleteState] =
    useState<boolean>(true);

  useEffect(() => {
    if (user) {
      isOnboardingComplete(user.uid, setOnboardingCompleteState);
    }
  }, [user]);

  useEffect(() => {
    if (!onboardingCompleteState) {
      logAnalyticsEvent("onboarding_start", { user: user?.uid });
      onOpen();
    }
  }, [onboardingCompleteState]);

  const onCloseOnboarding = () => {
    if (user) {
      logAnalyticsEvent("onboarding_finished", { user: user?.uid });
      setOnboardingComplete(user?.uid);
    }
    onClose();
  };

  return (
    <OnboardingFormContext.Provider
      value={{
        onClose: onCloseOnboarding,
        isOpen,
      }}
      {...props}
    />
  );
};

export const useOnboardingFormContext = () => {
  const formContextData = useContext(OnboardingFormContext);

  return {
    ...formContextData,
  };
};
