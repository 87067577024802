import { AddIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default ({ addChoice }) => {
  const { t } = useTranslation();
  return (
    <Button
      mt={1}
      w="auto"
      variant="outline"
      fontWeight={400}
      onClick={addChoice}
      bg="white"
      _hover={{
        bg: "white",
      }}
    >
      {t("Add a choice")} <AddIcon h={4} w={4} ms={2} />
    </Button>
  );
};
