import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  IconButton,
  Flex,
  Switch,
  Text,
  FlexProps,
} from "@chakra-ui/react";
import { SettingsIcon } from "@chakra-ui/icons";
import { t } from "i18next";
import { ReactNode, useRef } from "react";
import { useFormContext } from "context/FormContext";
import { changeFormHandler } from "components/form/utils/utils";
import { EndingType, FormField } from "types/Form";
import { useSiteContext } from "context/SiteContext";
import { useAuthState } from "context/AuthContext";
import { UserRole } from "types/User";
import OverlayWrapper from "components/form/general/overlay/OverlayWrapper";
import { useNavigate } from "react-router-dom";
import { logAnalyticsEvent } from "../../firebase/firebase-analytics";

interface SettingsModalChildrenProps {
  onClick: () => void;
}
interface SettingsModalProps extends FlexProps {
  isAiForm?: boolean;
  isOpen?: boolean;
  children?: (props: SettingsModalChildrenProps) => ReactNode;
}

export default ({ isAiForm = false, ...props }: SettingsModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    isOpen: props.isOpen,
  });
  const { form, setForm } = useFormContext();
  const { language, isDesktop } = useSiteContext();
  const { isAdmin, userRole } = useAuthState();
  const navigate = useNavigate();

  // fix stale closure
  const ref = useRef<any>({ form: [] }).current;
  ref.form = form;

  const isPaid = userRole === UserRole.PREMIUM;

  const changeForm = ({
    fieldAttribute,
    value,
    id,
    type,
  }: {
    fieldAttribute: string;
    value: any;
    id?: string;
    type: string;
  }) => {
    if (!id) return;
    const updatedForm = changeFormHandler({
      form: ref.form,
      fieldAttribute,
      value,
      id,
      type,
    });

    if (setForm) {
      setForm(updatedForm);
    }
  };

  const onSettingClicked = () => {
    logAnalyticsEvent("open_ending_settings");
    onOpen();
  };

  if (!form) return null;
  return (
    <Flex {...props}>
      {props.children ? (
        props.children({
          onClick: onSettingClicked,
        })
      ) : (
        <IconButton
          icon={
            <SettingsIcon
              color={isAiForm ? "#6C6A6A" : { base: "#3D2399", lg: "#6C6A6A" }}
              width={isAiForm ? "12px" : { base: "16px", lg: undefined }}
              height={isAiForm ? "12px" : { base: "16px", lg: undefined }}
            />
          }
          size={isAiForm ? "xs" : undefined}
          onClick={onSettingClicked}
          aria-label="settings"
          bg="white"
          border={isAiForm ? "none" : "1px"}
          borderColor={{ base: "#3D2399", lg: "#6C6A6A" }}
        />
      )}

      <OverlayWrapper
        title={t("Settings")}
        isOpen={isOpen}
        onClose={() => {
          logAnalyticsEvent("close_ending_settings");
          onClose();
        }}
        size={isDesktop ? "md" : "6xl"}
      >
        <Flex gap="10px" flexDir="column">
          <Flex gap="10px" alignItems="center">
            <Flex flex={1}>
              <Text fontSize="18px">{t("Share Icons")}</Text>
            </Flex>
            <Switch
              colorScheme="teal"
              // todo: will break with multiple screens
              isChecked={form.thankyou_screens?.[0].properties.share_icons}
              onChange={(e) => {
                changeForm({
                  fieldAttribute: "share_icons",
                  value: e.target.checked,
                  id: form.thankyou_screens?.[0].id,
                  type: EndingType.THANK_YOU,
                });
                if (e.target.checked) {
                  logAnalyticsEvent("show_share_icons");
                } else {
                  logAnalyticsEvent("hide_share_icons");
                }
              }}
            />
          </Flex>

          <Flex gap="10px" alignItems="center">
            <Flex flex={1}>
              <Text fontSize="18px">{t("Success Icon")}</Text>
            </Flex>
            <Switch
              colorScheme="teal"
              // todo: will break with multiple screens
              isChecked={
                form.thankyou_screens?.[0].properties.show_success_icon
              }
              onChange={(e) => {
                changeForm({
                  fieldAttribute: "show_success_icon",
                  value: e.target.checked,
                  id: form.thankyou_screens?.[0].id,
                  type: EndingType.THANK_YOU,
                });
                if (e.target.checked) {
                  logAnalyticsEvent("show_success_icon");
                } else {
                  logAnalyticsEvent("hide_success_icon");
                }
              }}
            />
          </Flex>
          {isAdmin && (
            <Flex gap="10px" alignItems="center">
              <Flex flex={1}>
                <Text fontSize="18px">{t("CTA Button")}</Text>
              </Flex>
              <Switch
                colorScheme="teal"
                // todo: will break with multiple screens
                isChecked={form.thankyou_screens?.[0].properties.show_cta}
                onChange={(e) => {
                  changeForm({
                    fieldAttribute: "show_cta",
                    value: e.target.checked,
                    id: form.thankyou_screens?.[0].id,
                    type: EndingType.THANK_YOU,
                  });
                  if (e.target.checked) {
                    logAnalyticsEvent("show_cta");
                  } else {
                    logAnalyticsEvent("hide_cta");
                  }
                }}
              />
            </Flex>
          )}
          <Flex flexDir="column">
            <Flex gap="10px" alignItems="center">
              <Flex flex={1}>
                <Text fontSize="18px">{t("Remove SeenJeem Logo")}</Text>
              </Flex>
              <Switch
                colorScheme="teal"
                // todo: will break with multiple screens
                isChecked={form.thankyou_screens?.[0].properties.hide_logo}
                disabled={!isPaid}
                onChange={(e) => {
                  changeForm({
                    fieldAttribute: "hide_logo",
                    value: e.target.checked,
                    id: form.thankyou_screens?.[0].id,
                    type: EndingType.THANK_YOU,
                  });
                  if (e.target.checked) {
                    logAnalyticsEvent("hide_logo");
                  } else {
                    logAnalyticsEvent("show_logo");
                  }
                }}
              />
            </Flex>
            {!isPaid && (
              <>
                <Text fontSize="14px">{t("RemoveLogo.PaidAccount")}</Text>
                <Button
                  colorScheme="teal"
                  variant="link"
                  onClick={() => {
                    navigate("/pricing");
                  }}
                >
                  {t("AccountLimit.CTA")}
                </Button>
              </>
            )}
          </Flex>
          {isAdmin && (
            <Flex gap="10px" alignItems="center">
              <Flex flex={1}>
                <Text fontSize="18px">{t("Right Align")}</Text>
              </Flex>
              <Switch
                colorScheme="teal"
                // todo: will break with multiple screens
                isChecked={form.thankyou_screens?.[0].properties.right_align}
                onChange={(e) => {
                  changeForm({
                    fieldAttribute: "right_align",
                    value: e.target.checked,
                    id: form.thankyou_screens?.[0].id,
                    type: EndingType.THANK_YOU,
                  });
                  if (e.target.checked) {
                    logAnalyticsEvent("toggle_right_align_on");
                  } else {
                    logAnalyticsEvent("toggle_right_align_off");
                  }
                }}
              />
            </Flex>
          )}
        </Flex>

        <ModalFooter display="flex" justifyContent="center" alignItems="center">
          <Button
            variant="outline"
            borderColor="teal.500"
            color="teal.500"
            w="100%"
            maxW="200px"
            fontWeight="normal"
            onClick={() => {
              logAnalyticsEvent("close_ending_settings");
              onClose();
            }}
            ms={2}
          >
            {t("Close")}
          </Button>
        </ModalFooter>
      </OverlayWrapper>
    </Flex>
  );
};
