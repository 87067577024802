/* eslint-disable react/jsx-no-constructed-context-values */
import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage, connectStorageEmulator } from "firebase/storage";

import {
  initializeAuth,
  connectAuthEmulator,
  browserPopupRedirectResolver,
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { Cloudinary } from "@cloudinary/url-gen";

export const firebaseConfig = {
  apiKey: "AIzaSyCpRotSbkhU1jdjDQaVjyMWVi9SLAzascw",
  authDomain: "palestine-co.firebaseapp.com",
  projectId: "palestine-co",
  storageBucket: "palestine-co.appspot.com",
  messagingSenderId: "189672608863",
  appId: "1:189672608863:web:6d59f325d7d1f121c437ea",
  measurementId: "G-W83GQ8H73J",
  databaseURL:
    "https://palestine-co-default-rtdb.europe-west1.firebasedatabase.app/",
};

export const myCld = new Cloudinary({ cloud: { cloudName: "dyecxbsli" } });

const app = initializeApp(firebaseConfig);

export const functions = getFunctions(app, "europe-west1");

// export const euroFunctions = getFunctions(app, "us-central1");

const perf = getPerformance(app);

export const storage = getStorage();

export const auth = initializeAuth(app, {
  persistence: [browserLocalPersistence, browserSessionPersistence],
  popupRedirectResolver: browserPopupRedirectResolver,
});

export const firestoreDb = getFirestore(app);

if (
  process.env.NODE_ENV === "development" ||
  /* eslint-disable no-restricted-globals */
  location.hostname === "localhost"
) {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(firestoreDb, "localhost", 9001);
  connectFunctionsEmulator(functions, "localhost", 5001); // DEBUG
  connectStorageEmulator(storage, "localhost", 9199);
}
