import React, { useMemo } from "react";
import { Flex, Button } from "@chakra-ui/react";
import { colorWithoutOpacity } from "components/select/presetColorPicker/Utils";

interface OpinionScaleButtonProps {
  isSelected?: boolean;
  buttonTextColor?: string;
  buttonBackgroundColor?: string;
  fontFamily?: string;
  language?: string;
  label?: string;
  fontSize?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export const OpinionScaleButton = ({
  buttonTextColor,
  buttonBackgroundColor,
  fontFamily,
  onClick,
  isSelected,
  language,
  disabled,
  fontSize,
  label = "Label",
}: OpinionScaleButtonProps) => {
  const noAlphaBG = useMemo(
    () =>
      buttonBackgroundColor
        ? colorWithoutOpacity(buttonBackgroundColor)
        : undefined,
    [buttonBackgroundColor]
  );
  return (
    <Flex
      flex="1 1 30px"
      style={{
        aspectRatio: "1/1",
      }}
      minW="30px"
      minH="30px"
      maxW="45px"
    >
      <Button
        isDisabled={disabled}
        _hover={{
          bg: buttonBackgroundColor
            ? `${buttonBackgroundColor}C5`
            : "#3D2399C5",
        }}
        _active={{
          bg: buttonBackgroundColor
            ? `${buttonBackgroundColor}C5`
            : "#3D2399C5",
        }}
        h="100%"
        w="100%"
        _focus={{
          outline: "none",
        }}
        borderRadius="3px"
        fontSize={fontSize}
        fontWeight={400}
        color={buttonTextColor ?? "white"}
        fontFamily={fontFamily ?? "inherit"}
        onClick={onClick}
        p={0}
        minWidth={0}
        bg={
          isSelected
            ? noAlphaBG
              ? `${noAlphaBG}A5`
              : "#3D2399"
            : buttonBackgroundColor ?? "#3D2399"
        }
        boxShadow={isSelected ? "lg" : "none"}
      >
        {label}
      </Button>
    </Flex>
  );
};
