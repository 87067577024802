import { extractOutcomeLogicByScreenId } from "components/modals/OutcomesModal/utils";
import { Answer, Form, FormChoice, Variables } from "types/Form";
import { FormFieldLogicActionCondition } from "types/Logic";

export const outcomeCalculator = (answers: Answer[], form: Form): Variables => {
  let variables: Variables = {};
  if (form.variables) variables = JSON.parse(JSON.stringify(form.variables));

  const allThankyouScreens = form.thankyou_screens ?? [];

  const screenWiseOutcomes = allThankyouScreens
    .map((thankYouScreen) =>
      extractOutcomeLogicByScreenId(thankYouScreen.id, form.logic)
    )
    .flat(1);

  answers.forEach((answer) => {
    if (answer.type === "choices") {
      answer.value.choices.forEach((choice) => {
        const allLogicChoices = screenWiseOutcomes.filter(
          (logicChoice) => logicChoice.choiceId === choice.id
        );

        allLogicChoices.forEach((logicChoice) => {
          if (logicChoice.counterAction === "add") {
            if (variables[logicChoice.counterId] === undefined) {
              variables[logicChoice.counterId] = 0;
            }
            variables[logicChoice.counterId] += logicChoice.counterValue;
          }
        });
      });
    }
  });

  return variables;
};

export const isAllCounterSame = (counters: { [key: string]: number }) => {
  const counterValues = Object.values(counters);

  if (counterValues.length <= 0) {
    return true;
  }

  if (counterValues.length === 1) {
    return false;
  }

  return counterValues.every((val, i, arr) => val === arr[0]);
};
