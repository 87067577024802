import { t } from "i18next";
import { UserRole } from "types/User";

export interface Plan {
  name: UserRole;
  label: string;
  price: string;
  description: string;
  CTA: string;
}

export interface Feature {
  label: string;
  value: {
    [UserRole.BASIC]: string;
    [UserRole.PREMIUM]: string;
  };
}

export default () => {
  const features = [
    {
      label: t("NumberOfForms"),
      value: { [UserRole.BASIC]: t("1"), [UserRole.PREMIUM]: t("Unlimited") },
    },
    {
      label: t("QuestionsPerForm"),
      value: { [UserRole.BASIC]: t("10"), [UserRole.PREMIUM]: t("Unlimited") },
    },
    {
      label: t("ResponsesPerMonth"),
      value: { [UserRole.BASIC]: "10", [UserRole.PREMIUM]: t("Unlimited") },
    },
    {
      label: t("AvailableSpace"),
      value: { [UserRole.BASIC]: " 100mb", [UserRole.PREMIUM]: " 10GB" },
    },
    {
      label: t("Quizzes"),
      value: { [UserRole.BASIC]: "✔", [UserRole.PREMIUM]: "✔" },
    },
    {
      label: t("CSVExport"),
      value: { [UserRole.BASIC]: "✔", [UserRole.PREMIUM]: "✔" },
    },
    {
      label: t("CloudStorage"),
      value: { [UserRole.BASIC]: "✔", [UserRole.PREMIUM]: "✔" },
    },
    {
      label: t("Support"),
      value: { [UserRole.BASIC]: "✘", [UserRole.PREMIUM]: "✔" },
    },
    {
      label: t("FormCreation"),
      value: { [UserRole.BASIC]: "✘", [UserRole.PREMIUM]: "✔" },
    },
  ];

  const plans = [
    {
      name: UserRole.BASIC,
      label: t("Free.Header"),
      price: "0",
      description: t("Free.Description"),
      CTA: t("SignUpNow"),
    },
    {
      name: UserRole.PREMIUM,
      label: t("Paid.Header"),
      price: "10",
      description: t("Paid.Description"),
      CTA: t("Upgrade"),
    },
  ];

  return { features, plans };
};
