import { Flex, Text } from "@chakra-ui/react";
import { useSiteContext } from "context/SiteContext";
import { t } from "i18next";

export default () => {
  const { language } = useSiteContext();
  return (
    <Flex flexDir="column" mx="auto" my={10} w="70%" textAlign="center">
      <Text fontSize="24px" mx="auto">
        {t("Pricing.Header")}
      </Text>
      <Text color="gray.600" fontSize="16px" mx="auto" mt={2} w="80%">
        {t("Pricing.Subheader")}
      </Text>
    </Flex>
  );
};
