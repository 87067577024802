export enum FontSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export enum Alignment {
  // LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export interface Theme {
  id?: string;
  logo_id?: string;
  font_family?: string;
  name?: string;
  created_at?: number;
  updated_at?: number;
  colors?: {
    question: string;
    answer: string;
    button: string;
    button_content: string;
    background: string;
  };
  screens?: {
    font_size: FontSize;
    alignment: Alignment;
  };
  fields?: {
    font_size: FontSize;
    alignment: Alignment;
  };
}
