import {
  Box,
  FormControl,
  FormHelperText,
  Input,
  InputProps,
} from "@chakra-ui/react";

interface EmailFieldProps {
  color?: string;
  language?: string;
  fontFamily?: string;
  fontSize?: string;
  value?: string;
  error?: string;
  disabled?: boolean;
  isStatic?: boolean;
  onChange?: InputProps["onChange"];
}

const EmailField = ({
  onChange,
  isStatic,
  language = "en",
  error,
  value,
  color,
  fontFamily,
  fontSize,
}: EmailFieldProps) => {
  return (
    <Box
      as="section"
      position="relative"
      dir={language === "en" ? "ltr" : "rtl"}
    >
      <FormControl isInvalid={!isStatic ? undefined : error !== undefined}>
        <Input
          variant="outline"
          borderColor={color ?? "gray.200"}
          type="email"
          width="100%"
          height="auto"
          minH="40px"
          maxWidth="200px"
          _focus={{
            outline: "none",
          }}
          dir="ltr"
          placeholder="info@gmail.com"
          _placeholder={{
            color: color ?? "gray.400",
          }}
          color={color ?? "gray.600"}
          disabled={!isStatic}
          border="1px solid"
          focusBorderColor={color ?? "gray.300"}
          value={value}
          fontFamily={fontFamily}
          fontSize={fontSize}
          onChange={onChange}
        />
        {isStatic && error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export default EmailField;
