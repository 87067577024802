import { Flex } from "@chakra-ui/react";
import Logo from "components/general/Logo";
import { logAnalyticsEvent } from "../../firebase/firebase-analytics";

export default ({ disableCTA }: { disableCTA: boolean }) => {
  const redirectURL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : "https://seenjeem.io";

  return (
    <Flex
      mx="auto"
      w="100%"
      maxW="200px"
      mt="5"
      onClick={() => {
        logAnalyticsEvent("thankyou_page_logo");
      }}
    >
      <Logo disable={disableCTA} redirectLink={redirectURL} />
    </Flex>
  );
};
