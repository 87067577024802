import { Flex, SlideFade, Textarea } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { WelcomeScreens } from "types/Form";
import { WelcomeScreenFooter } from "components/field/WelcomeScreenFooter";
import { Inputs } from "components/field/Inputs";

interface WelcomePageProps {
  visiblePageNumber: number;
  setVisiblePageNumber: Dispatch<SetStateAction<number>>;
  isStatic: boolean;
  width: number;
  changeForm: any;
  minWidth: number;
  screen: WelcomeScreens;
  isExpanded?: boolean;
  onExpand?: () => void;
}

const WelcomeScreen = ({
  visiblePageNumber,
  isStatic,
  width,
  changeForm,
  minWidth,
  screen,
  setVisiblePageNumber,
  isExpanded,
  onExpand,
}: WelcomePageProps) => {
  return (
    <SlideFade
      delay={0.5}
      in
      style={{ width: "100%", height: "100%" }}
      offsetY="100%"
    >
      <Flex direction="column" w="100%">
        <Flex w="100%" h="100%" position="relative">
          <Flex direction="column" w="100%">
            <Inputs
              minWidth={minWidth}
              isStatic={isStatic}
              width={width}
              changeForm={changeForm}
              isExpanded={isExpanded}
              onExpand={onExpand}
              field={{
                ...screen,
                field_id: screen.id,
              }}
            />
          </Flex>
        </Flex>
        <WelcomeScreenFooter
          isStatic={isStatic}
          visiblePageNumber={visiblePageNumber}
          setVisiblePageNumber={setVisiblePageNumber}
        />
      </Flex>
    </SlideFade>
  );
};

export default WelcomeScreen;
