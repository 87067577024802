/* eslint-disable react/jsx-no-constructed-context-values */
import {
  Dispatch,
  SetStateAction,
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  ref,
  DataSnapshot,
  DatabaseReference,
  onValue,
  Database,
} from "firebase/database";
import { FormResponses, Form, FormType } from "types/Form";
import { getResponses, filterResponses } from "components/results/utils";
import { UserRole, Limits } from "types/User";
import LogRocket from "logrocket";
import { getDefaultTheme } from "utils/addForm/addForm";
import WebFont from "webfontloader";
import { DEFAULT_SELECTED_FONT_FAMILY } from "utils/addForm/formConstants";
import { useLocation } from "react-router-dom";
import { logAnalyticsEvent } from "../firebase/firebase-analytics";
import { useAuthState } from "./AuthContext";

export const FormContext = createContext<{
  form: Form;
  setForm: Dispatch<SetStateAction<Form>>;
  error: boolean;
  isLoading: boolean;
  language: string;
  setFormId: Dispatch<SetStateAction<string>>;
  formId: string;
  userForms: { [key: string]: Form };
  setUserForms: Dispatch<SetStateAction<{ [key: string]: Form }>>;
  isFormDirty: boolean;
  resetChanges: () => void;
  formResponses: FormResponses;
  setFormResponses: Dispatch<SetStateAction<FormResponses>>;
  selectedLogoUrl?: string;
  setSelectedLogoUrl: Dispatch<SetStateAction<string>>;
  isAiForm?: boolean;
  setIsAiForm?: Dispatch<SetStateAction<boolean>>;
  showAIPreview?: boolean;
  setShowAIPreview?: Dispatch<SetStateAction<boolean>>;
  setOriginalForm: Dispatch<SetStateAction<Form>>;
  hasAiForms?: boolean;
  hasRegularForms?: boolean;
} | null>(null);

export const FormContextProvider = (props) => {
  const [originalForm, setOriginalForm] = useState<Form>(
    props.form ? JSON.parse(JSON.stringify(props.form)) : undefined
  );
  const [form, setForm] = useState<Form>(props.form);
  const [selectedLogoUrl, setSelectedLogoUrl] = useState<string>();
  const [db, setDb] = useState<Database | null>(null);
  const [formId, setFormId] = useState<string>();
  const [language, setLanguage] = useState<string>();
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userForms, setUserForms] = useState<{ [key: string]: Form }>(
    props.userForms
  );
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [formResponses, setFormResponses] = useState<FormResponses>(
    props?.formResponses ?? {}
  );

  const { pathname } = useLocation();

  const [isAiForm, setIsAiForm] = useState<boolean>(false);
  const [showAIPreview, setShowAIPreview] = useState(false);

  useEffect(() => {
    if (pathname.includes("create-chat")) {
      setIsAiForm(true);
    } else {
      setIsAiForm(false);
    }
  }, [pathname]);

  const { isAdmin } = useAuthState();
  const { setLogRocketInitiated, logRocketInitiated, user, userRole } =
    useAuthState();

  import("./firebase-db").then(({ database }) => {
    setDb(database);
  });

  const getVariablesOfForm = useCallback(() => {
    const formFieldLogic = form?.logic ?? [];

    const allActions = formFieldLogic.flatMap(
      (fieldLogic) => fieldLogic.actions
    );

    const allVariables = allActions.map(
      (action) => action.details.target.value
    );

    const uniqueVariables = Array.from(new Set(allVariables));

    return uniqueVariables.reduce((json, value) => {
      json[value] = 0;
      return json;
    }, {});
  }, [form?.logic]);

  const changeSelectedForm = ({
    formIdX,
    database,
  }: {
    formIdX: string;
    database: Database;
  }) => {
    if (props?.storybook) {
      return;
    }

    let formRef: DatabaseReference | null = null;
    if (formIdX && database) {
      formRef = ref(database, `form/${formIdX}`);
    }

    if (formRef) {
      onValue(formRef, async (snapshot: DataSnapshot) => {
        if (!snapshot.exists()) {
          setError(true);
        }

        const data: Form = await snapshot.val();
        if (!data) {
          return;
        }
        setError(false);
        const obj = data;
        obj.uid = formId!;
        obj.theme = getDefaultTheme(obj);
        obj.variables = getVariablesOfForm();

        // loads selected font while accessing in view mode
        if (
          obj.theme?.font_family &&
          obj.theme?.font_family !== DEFAULT_SELECTED_FONT_FAMILY
        ) {
          WebFont.load({
            google: {
              families: [obj.theme.font_family],
            },
          });
        }

        const formCopy = JSON.parse(JSON.stringify(obj));
        setOriginalForm(formCopy);
        setForm(obj);
        setSelectedLogoUrl(undefined);
      });
    }
  };

  useEffect(() => {
    if (isAdmin) return;

    if (userForms && !props.storybook) {
      const formKeys = Object.keys(userForms);

      if (formKeys.length > 0) {
        const responses = {};
        getResponses({ responses, formKeys, setFormResponses });
      }
    }
  }, [isAdmin, userForms]);

  const resetChanges = () => {
    const originalFormCopy = JSON.parse(JSON.stringify(originalForm));
    setForm(originalFormCopy);
    setSelectedLogoUrl(undefined);
  };

  useEffect(() => {
    if (formId && db) {
      changeSelectedForm({ formIdX: formId, database: db });
    }
  }, [formId, db]);

  useEffect(() => {
    if (!form) return;
    setIsLoading(false);
  }, [form]);

  useEffect(() => {
    if (
      JSON.stringify(form) === JSON.stringify(originalForm) &&
      !selectedLogoUrl
    ) {
      setIsFormDirty(false);
    } else {
      setIsFormDirty(true);
    }
  }, [form, originalForm, selectedLogoUrl]);

  // todo: refactor or extract
  useEffect(() => {
    if (formResponses && formId) {
      if (!logRocketInitiated) {
        const responses = filterResponses({ responses: formResponses[formId] });
        if (
          user &&
          userRole !== UserRole.PREMIUM &&
          responses?.length > Limits.BASIC
        ) {
          logAnalyticsEvent("paywall_enabled");
          LogRocket.init("bymiag/palestine-co");
          LogRocket.identify(user.uid, {
            email: user.email || "",
          });
          setLogRocketInitiated(true);
        }
      }
    }
  }, [formId, formResponses]);

  const hasAiForms = useMemo(() => {
    if (userForms)
      return (
        Object.values(userForms).filter(
          (currForm) => currForm.type === FormType.CHAT
        ).length !== 0
      );
  }, [userForms]);

  const hasRegularForms = useMemo(() => {
    if (userForms)
      return (
        Object.values(userForms).filter(
          (currForm) => currForm.type !== FormType.CHAT
        ).length !== 0
      );
  }, [userForms]);

  return (
    <FormContext.Provider
      value={{
        form,
        setForm,
        formId,
        setFormId,
        userForms,
        setUserForms,
        language,
        error,
        isLoading,
        isFormDirty,
        resetChanges,
        formResponses,
        setFormResponses,
        selectedLogoUrl,
        setSelectedLogoUrl,
        isAiForm,
        setIsAiForm,
        showAIPreview,
        setShowAIPreview,
        setOriginalForm,
        hasAiForms,
        hasRegularForms,
      }}
      {...props}
    />
  );
};

export const useFormContext = () => {
  const formContextData = useContext(FormContext);

  return {
    ...formContextData,
  };
};
