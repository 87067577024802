export enum UserRole {
  ADMIN = "admin",
  BASIC = "basic",
  PREMIUM = "premium",
}

export interface User {
  uid: string;
  email: string;
  plan: UserRole;
}

export enum Limits {
  BASIC = 10,
}
