import * as Sentry from "@sentry/react";
import React from "react";
import { render } from "react-dom";
import "./index.css";
import {
  BrowserRouter as Router,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import "./config/i18n";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://6493debfb90748cebb785a89976936c2@o4504180592017408.ingest.sentry.io/4504180595228674",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
