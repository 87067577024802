import { isValidEmail } from "components/field/EmailWrapper";
import { isValidPhoneNumber } from "components/field/PhoneInputField";
import { DEFAULT_NUMBER_OF_SELECTIONS } from "components/modals/MultipleChoiceSettingsModal";
import {
  Answer,
  ChoicesAnswer,
  FieldType,
  Form,
  PhoneAnswer,
  TextAnswer,
} from "types/Form";

export function isFieldAnswered({
  answers,
  form,
  fieldId,
  isStatic,
}: {
  answers: Answer[];
  form: Form;
  fieldId: string;
  isStatic: boolean;
}) {
  const field = (form?.fields ?? []).find(
    (field1) => field1.field_id === fieldId
  );

  // if field is not required then enable button
  if (!field?.properties?.required) {
    return false;
  }

  const answerValue = answers?.find(
    (response) => response.field_id === fieldId
  )?.value;

  // check if entered value is valid short answer
  if (field && field.type === FieldType.EMAIL) {
    const emailValue = answerValue as TextAnswer["value"] | undefined;
    const emailText = emailValue?.text ?? "";
    return (
      !isStatic || !(emailText.length > 0 ? isValidEmail(emailText) : false)
    );
  }

  // check if entered value is valid email
  if (field && field.type === FieldType.SHORT_ANSWER) {
    const shortAnswer = answerValue as TextAnswer["value"] | undefined;
    return !isStatic || (shortAnswer?.text ?? "").trim() === "";
  }

  // check if entered value is valid phone number
  if (field && field.type === FieldType.PHONE) {
    const phoneValue = answerValue as PhoneAnswer["value"] | undefined;
    const phoneText = phoneValue?.text ?? "";
    return (
      !isStatic ||
      !(phoneText.length > 0 ? isValidPhoneNumber(phoneText) : false)
    );
  }

  if (
    field &&
    field.type === FieldType.MULTIPLE_CHOICE &&
    field.properties.multiple_selection &&
    field.properties.number_of_selection !== undefined
  ) {
    const choicesValue =
      (answerValue as ChoicesAnswer["value"] | undefined)?.choices ?? [];

    const numberOfSelections = field.properties.number_of_selection
      ? field.properties.number_of_selection
      : DEFAULT_NUMBER_OF_SELECTIONS;

    return (
      !isStatic ||
      choicesValue.length !== parseInt(numberOfSelections.toString(), 10)
    );
  }

  return !isStatic || !answerValue;
}
