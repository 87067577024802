import { Input } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useStaticFormContext } from "context/StaticFormContext";
import { FormField, Answer } from "types/Form";
import { useFormContext } from "context/FormContext";
import ShortAnswer from "./ShortAnswer";

export default ({
  isStatic,
  field,
  language,
}: {
  isStatic: boolean;
  field: FormField;
  language: string;
}) => {
  const { answers, setAnswers } = useStaticFormContext();
  const { form } = useFormContext();
  const { i18n } = useTranslation();
  const arabicT = i18n.getFixedT("ar");

  const save = (e) => {
    if (answers && setAnswers) {
      const objIndex = answers.findIndex(
        (obj) => obj.field_id === field.field_id
      );

      const newAnswer: Answer = {
        field_id: field.field_id,
        type: "text",
        value: {
          text: e,
        },
      };
      if (objIndex < 0) {
        setAnswers([...answers, newAnswer]);
      } else {
        answers[objIndex] = newAnswer;
        setAnswers([...answers]);
      }
    }
  };

  const answer = () => {
    const fieldResponse = answers?.find(
      (response) => response.field_id === field.field_id
    );
    if (fieldResponse?.type === "text") {
      return fieldResponse.value.text;
    }
    return "";
  };

  const inputColor = form?.theme?.colors?.answer;
  const fontFamily = form?.theme?.font_family;

  return (
    <ShortAnswer
      value={answer()}
      onChange={(e) => save(e.target.value)}
      placeholder={
        language === "en"
          ? "Type your answer here..."
          : arabicT("ShortAnswerPlaceholder")
      }
      disabled={!isStatic}
      {...{
        inputColor,
        fontFamily,
        language,
      }}
    />
  );
};
