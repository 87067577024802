import { Button, Flex, Text } from "@chakra-ui/react";
import { useSiteContext } from "context/SiteContext";
import { t } from "i18next";
import OverlayWrapper from "./OverlayWrapper";

interface DeleteQuestionOverlayProps {
  isOpen?: boolean;
  onClose?: () => void;
  onDelete?: () => void;
}

const DeleteQuestionOverlay = ({
  isOpen = false,
  onClose = () => {},
  onDelete = () => {},
}: DeleteQuestionOverlayProps) => {
  const { direction, isDesktop } = useSiteContext();
  return (
    <OverlayWrapper
      title={t("DeleteQuestionModalTitle")}
      isOpen={isOpen}
      onClose={onClose}
      size={isDesktop ? "lg" : "6xl"}
    >
      <Flex flexDir="column" gap="31px">
        <Text fontSize="20px" fontWeight={500} color="#000" dir={direction}>
          {t("DeleteQuestionModalText")}
        </Text>
        <Flex
          gap="50px"
          alignSelf="center"
          flexDir={direction === "rtl" ? "row-reverse" : "row"}
        >
          <Button
            px="24px"
            py="10px"
            minW="73px"
            color="#3D2399"
            bgColor="white"
            _hover={{ bg: "#3D2399", color: "white" }}
            border="1px solid #3D2399"
            onClick={() => {
              onDelete();
              onClose();
            }}
          >
            {t("Yes")}
          </Button>
          <Button
            px="24px"
            py="10px"
            minW="73px"
            color="#3D2399"
            bgColor="white"
            _hover={{ bg: "#3D2399", color: "white" }}
            border="1px solid #3D2399"
            onClick={onClose}
          >
            {t("No")}
          </Button>
        </Flex>
      </Flex>
    </OverlayWrapper>
  );
};

export default DeleteQuestionOverlay;
