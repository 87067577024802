import { motion, useAnimation, Variants } from "framer-motion";
import { CSSProperties, useRef, useEffect, useCallback, useState } from "react";

const RED_COLOR = "#11807F";

export const outerCircleVariants = (buttonColor?: string): Variants => ({
  circle: {
    transform: "scale(1)",
    opacity: 0.5,
    boxShadow: `0px 0px 0px 5px ${buttonColor ?? RED_COLOR}`,
  },
  largeCircle: {
    transform: "scale(1.5)",
    opacity: 1,
    boxShadow: `0px 0px 0px 5px ${buttonColor ?? RED_COLOR}`,
  },
  pulseIn: {
    transform: "scale(1.7)",
    opacity: 1,
    boxShadow: `0px 0px 0px 10px ${buttonColor ?? RED_COLOR}`,
  },
  pulseOut: {
    transform: "scale(1.7)",
    opacity: 1,
    boxShadow: `0px 0px 0px 5px ${buttonColor ?? RED_COLOR}`,
  },
});

export const styles: Record<string, CSSProperties> = {
  container: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 50,
    height: 50,
    margin: "0 auto",
  },
  circle: {
    position: "absolute",
  },
  outerCircle: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    borderRadius: 9999,
  },
  innerCircle: {
    width: "90%",
    height: "90%",
    overflow: "hidden",
    backgroundColor: RED_COLOR,
  },
};
