import { Box, Image } from "@chakra-ui/react";
import { useFormContext } from "context/FormContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "types/Form";
import { getFirebaseFileUrl } from "utils/firebaseStorage";

interface FormCompanyLogoProps {
  selectedLogoUrl?: string;
  logoId?: string;
  formProp?: Form | undefined;
}

const FormCompanyLogo = ({
  selectedLogoUrl,
  logoId,
  formProp,
}: FormCompanyLogoProps) => {
  const { form } = useFormContext();

  const currForm = useMemo(() => formProp ?? form, [formProp, form]);
  const [publishedLogoUrl, setPublishedLogoUrl] = useState<string>();

  const fetchLogoUrl = useCallback(async () => {
    setPublishedLogoUrl(undefined);
    getFirebaseFileUrl(`formBackground/${logoId}`)
      .then((url) => setPublishedLogoUrl(url))
      .catch((error) => {
        if ((error as any).code === "storage/object-not-found") {
          setPublishedLogoUrl(undefined);
        }
      });
  }, [logoId]);

  useEffect(() => {
    if (!logoId) return;

    fetchLogoUrl();
  }, [logoId, fetchLogoUrl]);

  if (!selectedLogoUrl && !logoId) {
    return <></>;
  }

  const isArabic = currForm?.language === "ar";

  return (
    <Box
      position="absolute"
      right={isArabic ? undefined : "5px"}
      top="5px"
      left={!isArabic ? undefined : "5px"}
    >
      <Image
        src={selectedLogoUrl ?? publishedLogoUrl}
        maxH="40px"
        maxW="96px"
      />
    </Box>
  );
};
export default FormCompanyLogo;
