import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { pricing, pricingEnglish } from "./Pricing";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: "ar",
    debug: false,
    fallbackLng: "en",
    interpolation: {
      format(value, format, lng) {
        if (typeof value === "number")
          return new Intl.NumberFormat().format(value);
        return value;
      },
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          DefaultFormName: "New Form",
          Home: "Home",
          "Sign Out": "Sign Out",
          "Sign In": "Sign In",
          Account: "Account",
          Create: "Create",
          Create2: "Create",
          Results: "Results",
          Page: "Page",
          Submit: "Submit",
          "Thank You": "Thank You",
          Preview: "Preview",
          Restart: "Restart",
          "Are you sure you want to save your form?":
            "Are you sure you want to save your form?",
          No: "No",
          Yes: "Yes",
          "Hero.One": "Why is creating surveys in Arabic so difficult?",
          "Hero.Two": "The first survey building tool made for Arabic",
          "AboutUs.Header": `Asking Questions in Arabic should be easy.`,
          "AboutUs.Description": `But it isn't - creating beautiful Arabic forms is hard. Boring templates, fonts and designs mean lower engagement and less feedback from your audience.`,
          CreateDescription:
            "Beautiful forms that engage with people beyond old boring surveys. ",
          ShareDescription:
            "Built for MENA, mobile-first and WhatsApp friendly. ",
          ResultsDescription:
            "Beautiful dashboards that give you the most important insights.",
          HeroCTA: "Start Building",
          "About.1":
            "Build your Form in Arabic or English, add unlimited questions and preview on Mobile or Desktop",
          "About.2":
            "Generate a link to share your Form on WhatsApp, Email, or anything else",
          "About.3":
            "View your Form responses on your Dashboard, available in Arabic or English. Get statistics on your form.",
          ShortAnswerPlaceholder: "Type your answer here...",
          EmailPlaceholder: "Type your email here...",
          PhonePlaceholder: "Type your phone number here...",
          one_minute: "one minute ago",
          mins_under_ten: "{{interval}} minutes ago",
          mins_over_ten: "{{interval}} minutes ago",
          one_second: "one second ago",
          seconds_under_ten: "{{interval}} seconds ago",
          seconds_over_ten: "{{interval}} seconds ago",
          one_hour: "one hour ago",
          hours_under_ten: "{{interval}} hours ago",
          hours_over_ten: "{{interval}} hours ago",
          one_day: "one day ago",
          days_under_ten: "{{interval}} days ago",
          days_over_ten: "{{interval}} days ago",
          one_month: "one month ago",
          months_under_ten: "{{interval}} months ago",
          months_over_ten: "{{interval}} months ago",
          one_year: "one year ago",
          years_under_ten: "{{interval}} years ago",
          ai_responses_warning:
            "You have {{remaining}} Responses remaining for this form. Click here to upgrade to premium",
          years_over_ten: "{{interval}} years ago",
          ExitWarning:
            "Warning! You have unsaved work. Press Publish to save your form.",
          UnsavedWarning: "This form has unsaved changes",
          "Walkthrough.Step.One": "Welcome to SeenJeem! 🎉",
          "Walkthrough.Step.One.Description": "Let's get started",
          "Walkthrough.Step.Two": "Add a question by pressing this button",
          "Walkthrough.Step.Three": "Click here to edit your question",
          "Walkthrough.Step.Four": "Press here to save your work",
          "Walkthrough.Step.One.AI": "Welcome to SeenJeem AI Form Creator! 🎉",
          "Walkthrough.Step.Three.AI":
            "You can add the info you want to collect here (You don't need to add the whole question)",
          "Walkthrough.Step.Four.AI":
            "You can press here to begin the preview of AI-Generated Form",
          "Whatsapp.CTA": "Need help? We'd love to speak to you",
          FirstAIFormTitle:
            "Experience effortless form creation with our new AI-powered feature. Create your first form in seconds!",
          ReadyToGo: "Ready to go?",
          StartBuilding: "Start building",
          AddForm: "Add a Form",
          FormIndexed: "{{index}}. Form",
          StaticScore: "(The user's score will appear here)",
          ScoreDefaultTitle: "Thanks for completing the quiz! You scored:",
          ThankYouPageDefaultTitle: "Thank you for your feedback",
          "ScoreModal.Header": "Score Quiz",
          "ScoreModal.Description": "Assign points to answers",
          "Settings.Sidebar.Description": "Click to configure",
          "Settings.Sidebar.Description.Feedback": "Click to configure",
          "DisabledScoreScreen.Tooltip":
            "To show a score, please change your form to a `Quiz` in `Settings`",
          "OnboardingModal.Header": "We would love to hear your feedback",
          "CopyButton.Default": "Copy",
          "CopyButton.Success": "Success!",
          "ShareModal.Header": "Share your form",
          "ShareModal.Body": "Copy the link or share it on social media",
          "NameForm.FormName.Label": "Form Name",
          Addition: "Add",
          "NameForm.UserType.Label": "What are you using this form for?",
          "NameForm.FormType.Label": "What kind of form do you want to create?",
          "NameForm.PhoneNumber.Label": "Phone Number",
          "NewFormModal.Header": "Create a form",
          NoResponses: "You have not received any responses",
          "NewForm.Button": "New Form",
          "SurveyModal.Header": "We'd love to hear your feedback 🧐",
          "Download QR code": "Download QR code",
          "Theme.Design.Title": "Design",
          "Theme.FontType.Label": "Font type",
          "Theme.FontType.Placeholder": "Select Font",
          "Theme.Design.Questions.Label": "Questions",
          "Theme.Design.Answers.Label": "Answers",
          "Theme.Design.Buttons.Label": "Buttons",
          "Theme.Design.ButtonText.Label": "Button Text",
          "Theme.Design.Background.Label": "Background",
          "Theme.Design.Format.Label": "Format",
          "Theme.Design.WelcomeAndEndingAlign.Label":
            "Welcome Screen & Endings",
          "Theme.Design.QuestionsAlign.Label": "Questions",
          "Theme.Design.Logo.Label": "Add a logo",
          "Theme.Design.Logo.Tooltip": "Company Logo",
          "Theme.Design.Logo.Success": "Logo uploaded successfully",
          "Theme.Design.Colors.Label": "Colors",
          "Theme.Design.Colors.HexCode": "Hex Code",
          "Theme.Design.Colors.Transparency": "Transparency",
          "Theme.Design.Colors.AddColor": "Add Color",
          "Max.File.4MB": "File size is more than 4mb.",
          "RemoveLogo.PaidAccount":
            "You must have a paid account to remove the SeenJeem logo.",
          "Email.Validation.Error": "Please enter a valid email address",
          "Minimum 2 choices": "Minimum {{number}} choices",
          "Phone.Validation.Error": "Please enter a valid phone number",
          "NameForm.FormType.Placeholder": "Please choose one",
          WelcomeScreen: "Welcome Screen",
          EndingScreen: "Ending",
          "Login.Welcome": "You're welcome in",
          "Login.Button": "Login",
          "Login.Forgot": "Forgot my password",
          "Login.ForgotSent": "Link sent!",
          "Login.NewAccount": "Don't have an account?",
          "Login.Signup": "Subscribe",
          "Login.Google": "Connect with Google",
          "Login.Facebook": "Connect with Facebook",
          "Login.NotFound": "Email not found.",
          "Login.WrongCred": "Invalid credentials.",
          "Signup.Title": "New Account",
          "Signup.Save": "Save",
          "Signup.Cancel": "Cancel",
          "Signup.Details": "Tell us a little about yourself",
          "Signup.WhyUse": "Why would you use SeenJeem?",
          "Signup.WhatSector": "In what sector of work?",
          "Signup.WhichCompany": "For which company do you work?",
          "Signup.FormPurpose":
            "What is the purpose of the form you want to create?",
          "Signup.Exists": "Already registered.",
          DeleteQuestionModalTitle: "Delete question",
          DeleteQuestionModalText:
            "Are you sure you want to delete this question? You will lose all responses saved.",
          QuestionsTab: "Classic",
          Transcription: "Transcription",
          TypeHere: "Type here...",
          FilterQuestions: "Filter Questions",
          TableSettings: "Table Settings",
          Selected: "Selected",
          Delete: "Delete",
          DeleteModalHeader: "You are about to delete {{number}} responses",
          DeleteModalSubHeader1:
            "Are you sure you want to permanently delete these responses?",
          DeleteModalSubHeader2:
            "We can't recover any responses once you delete them",
          DeleteSuccess: "Responses deleted successfully",
          NoAnswer: "No Response",
          DisableForm: "Disable Form",
          DisableWhatsAppNotifications: "Disable WhatsApp Notifications",
          DisableWhatsAppNotificationsHelper:
            "This will disable WhatsApp Notifications for the selected form",
          "DisableForm.Desc":
            "You can stop the form from receiving responses knowing that the data will remain saved.",
          "DisableForm.Helper":
            "You must have a paid account to disable the form",
          DisabledFormModalText: "This form is disabled",
          Saved: "Saved",
          Overview: "Overview",
          AIFormCreationMessage: "Welcome to Seen Jeem AI Form Creator",
          AIForm: "AI Form",
          InfoToCollect: "Info to collect",
          DisableFormExitWarning: "Warning: your changes are not saved",
          ChooseTemplate: "Choose a template",
          UseTemplate: "Use Template",
          "UseTemplate.Helper":
            "Choose a preset template and customize it as you wish",
          StartFromScratch: "From Scratch",
          "StartFromScratch.Helper":
            "Get started and build something beautiful",
          "Template.CustomerSatisfaction": "Customer Satisfaction",
          "Template.Departure": "Departure Form",
          "Template.Employment": "Employment Application",
          "Template.Volunteer": "Volunteer Application",
          "Template.Effectiveness": "Effectiveness Evaluation",
          "Welcome.Modal.Title": "Choose intro type",
          "Endings.Modal.Title": "Choose the type of outro",
          "Overview.Placeholder": "Explain what this conversation is about",
          ResponsePageSubHeader:
            "Just click on the survey to display individual responses.",
          "AccountLimit.CTA": "Upgrade to Premium Now",
          "AccountLimit.Header":
            "You have reached the maximum number of replies available on your account. Please update your subscription to access additional responses!",
          "Whatsapp.Title": "Get your responses on WhatsApp for free",
          "Whatsapp.Description":
            "You can enable or disable this feature for each form from the settings page",
          "Whatsapp.Submit": "Submit",
          ...pricingEnglish,
        },
      },
      ar: {
        translation: {
          Home: "الصفحة الرئيسية",
          "Hero.One": "تبحث عن أداة لتصميم استبيانك باللغة العربية؟",
          "Hero.Two":
            "أول أداة إلكترونية عربية لتصميم الاستبيانات بسرعة وسهولة",
          "Speak The Right Language": "استخدم اللغة المناسبة",
          "Forms Made For Arabic": "نماذج مصممة خصيصاً للغة العربية",
          "Our Mission": "هدفنا",
          "AboutUs.Header":
            "إنشاء نماذج إلكترونية جميلة باللغة العربية أمر ليس صعباً للغاية",
          "AboutUs.Description":
            "منصة سين جيم ستساعدك على تصميم نماذج مبينة على قوالب وخطوط وتصميمات جذابة حتى تزيد من تفاعل جمهورك معها",
          "Create Beautiful Arabic Forms": "إنشاء نماذج عربية جميلة",
          "About.1":
            "قم بإنشاء النموذج الخاص بك باللغتين العربية والإنجليزية، وإضافة عدد غير محدود من الأسئلة، ومعايتنها باستخدام هاتفك النقال أو حاسوبك المكتبي",
          "About.2":
            "أنشئ رابطًا لمشاركة النموذج الخاص بك على WhatsApp أو عبر البريد الإلكتروني أو بأية وسيلة تواصل أخرى",
          "About.3":
            "استعرض ردود الجمهور على النموذج الخاص بك مباشرةً على لوحة القيادة الرقمية الخاصة بك، والمتاحة باللغة العربية أو الإنجليزية",
          Account: "استبياناتي",
          Page: "الصفحة",
          Submit: "إرسال",
          "Thank You": "شكرا",
          Preview: "عرض مسبق",
          Restart: "اعادة البدء",
          "Are you sure you want to save your form?":
            "هل أنت متأكد من أنك تريد حفظ التعديلات؟",
          No: "لا",
          Yes: "نعم",
          Loading: "جار التحميل",
          "Something went wrong": "هناك خطأ ما",
          Content: "الأسئلة",
          Publish: "حفظ التعديلات",
          "Your Link": "رابط الاستبيان",
          Success: "تم بنجاح!",
          "Usage Metrics": "مقاييس الاستخدام",
          "Summary of Responses": "ملخص الإجابات",
          "Responses by Date": "الإجابات حسب التاريخ",
          "Number of Views": "عدد المشاهدات",
          Submissions: "الردود",
          "Completion Rate": "معدل الإنجاز",
          "Avg. Time To Complete": "معدل وقت الإنجاز",
          Sections: "الأسئلة",
          "Avg.": "معدل",
          // 1: "١",
          Showing: "تظهر",
          "0-5": " ٠ - ٥ ",
          "out of": "من أصل",
          responses: "الردود",
          Response: "الإجابات",
          Date: "تاريخ",
          Time: "الوقت",
          "Create a Form": "إنشاء نموذج",
          Form: "نموذج",
          FormIndexed: "{{index}}. نموذج ",
          Next: "التالي",
          Create: "صمّم نموذجك",
          Create2: "أنشئ",
          QuestionsTab: "كلاسيكي",
          CreateDescription:
            "قم بإنشاء النموذج الخاص بك باللغتين العربية والإنجليزية، وإضافة عدد غير محدود من الأسئلة، ومعايتنها باستخدام هاتفك النقال أو حاسوبك المكتبي",
          Share: "شارك مع جمهورك",
          ShareDescription:
            "قم بإرسال رابط النموذج الخاص بك عبر البريد الإلكتروني، أو عبر تطبيقات وسائل التواصل الاجتماعي",
          Results: "حلّل بياناتك",
          ResultsDescription:
            "قم باستعراض وتقييم نتائج النموذج الخاص بك بيانياً والمتاحة باللغتين العربية والإنجليزية",
          HeroCTA: "صمّم نموذجك الآن!",
          "Add A Page": "إضافة مقدمة",
          "Add A Question": "إضافة سؤال",
          "Add An Ending": "إضافة خاتمة",
          "Multiple Choice": "اختيار من متعدد",
          Overview: "ملخص",
          AIFormCreationMessage:
            "مرحبًا بك في مُنشئ نماذج الذكاء الاصطناعي لسين جيم",
          AIForm: "نموذج ذكاء اصطناعي",
          InfoToCollect: "المعلومات التي تريد جمعها",
          "Form 1": "نموذج ١",
          "Form 2": "نموذج ٢",
          "Sign In": "تسجيل الدخول",
          "Sign Out": "خروج",
          "Sign Up": "اشتراك",
          "Build your Form": "بناء النموذج الخاص بك",
          "Send to your audience": "أرسل إلى جمهورك",
          "Analyze your responses": "حلل ردودك",
          Summary: "النتائج",
          Responses: "الردود",
          "{{answers}} out of {{total}} people answered this question":
            "أجاب {{answers}} من {{total}} أشخاص على هذا السؤال",
          "Opinion Scale": "معيار التقييم",
          "Short Answer": "اجابة قصيرة",
          "Choice {{number}}": "الخيار {{number}}",
          DeleteQuestionModalTitle: "حذف السؤال",
          DeleteQuestionModalText:
            " هل أنت متأكد أنك تريد حذف هذا السؤال؟ علماً بأن حذف السؤال سيؤدي إلى فقدان أي بيانات محفوظة",
          "Add a choice": "أضف خيارا",
          ShortAnswerPlaceholder: "اكتب إجابتك هنا",
          EmailPlaceholder: "بريدك الإلكتروني",
          PhonePlaceholder: "رقم التليفون",
          one_minute: "قبل دقيقة واحدة",
          mins_under_ten: "قبل {{interval}} دقائق",
          mins_over_ten: "قبل {{interval}} دقائق",
          one_second: "قبل ثانية واحدة",
          seconds_under_ten: "قبل {{interval}} ثوان",
          seconds_over_ten: "قبل {{interval}} ثوان",
          one_hour: "منذ ساعة واحدة",
          hours_under_ten: "قبل {{interval}} ساعات",
          hours_over_ten: "قبل {{interval}} ساعات",
          one_day: "قبل يوم واحد",
          days_under_ten: "قبل {{interval}} أيام",
          days_over_ten: "قبل {{interval}} أيام",
          one_month: "قبل شهر",
          months_under_ten: "قبل {{interval}} شهور",
          months_over_ten: "قبل {{interval}} شهور",
          one_year: "قبل عام",
          years_under_ten: "قبل {{interval}} سنوات",
          ai_responses_warning:
            "لديك {{remaining}} ردود متبقية لهذا النموذج. انقر هنا للترقية إلى الإصدار المميز",
          new: "جديد",
          years_over_ten: "قبل {{interval}} سنوات",
          Endings: "نوع النموذج",
          ExitWarning: "انتبه! لم تقم بحفظ تعديلاتك. هل أنت متأكد من الخروج؟",
          UnsavedWarning: "يحتوي النموذج على تغييرات غير محفوظة",
          "Continue Anyway": "تواصل على أي حال",
          Continue: "خروج",
          Cancel: "عودة",
          Questions: "الأسئلة",
          Skip: "إنهاء",
          "Walkthrough.Step.One": "🎉 مرحبا بكم في سين جيم",
          "Walkthrough.Step.One.Description":
            "سنقوم بإرشادك لإعداد نموذج الاستبيان الأول الخاص بك ",
          "Walkthrough.Step.Two": "اضغط هنا لإضافة سؤالك",
          "Walkthrough.Step.Three": "أدخل سؤالك هنا",
          "Walkthrough.Step.Four": "اضغط هنا لحفظ تعديلاتك",
          "Walkthrough.Step.One.AI":
            "🎉 !مرحبًا بك في منشئ نماذج الذكاء الاصطناعي الخاص بسين جيم",
          "Walkthrough.Step.Three.AI":
            "يمكنك إضافة المعلومات التي تريد جمعها هنا (لا تحتاج إلى إضافة السؤال بأكمله)",
          "Walkthrough.Step.Four.AI":
            "يمكنك الضغط هنا لبدء معاينة النموذج الذي تم إنشاؤه بواسطة الذكاء الاصطناعي",
          Back: "عودة",
          Last: "تم",
          "Download CSV": "تنزيل ملف CSV",
          FirstAIFormTitle:
            "استمتع بتجربة إنشاء النماذج دون عناء من خلال الميزة الجديدة المدعومة بالذكاء الاصطناعي. أنشئ النموذج الأول الخاص بك في ثوانٍ!",
          "Form Published": "تم بنجاح",
          "An Error Occurred": "حدث خطأ",
          "Whatsapp.CTA": "هل لديك بعض الملاحظات؟",
          Settings: "الإعدادات",
          "Share Icons": "أيقونات مشاركة",
          ReadyToGo: "هل أنت مستعد؟",
          HomeCTA: "صمّم نموذجك الآن",
          AddForm: "إضافة نموذج",
          StaticScore: "(ستظهر نتيجة المستخدم هنا ، على سبيل المثال):",
          ScoreDefaultTitle: "شكرا لإكمال الاختبار! احرزت:",
          ThankYouPageDefaultTitle: "شكرا على المشاركة",
          Save: "حفظ",
          "ScoreModal.Header": "اختبار",
          "ScoreModal.Description": "قم بتعيين نقاط للإجابات",
          Survey: "استبيان",
          "Settings.Sidebar.Description":
            "يعطي نتيجة مختلفة بناءً على الإجابات المختارة (كاختبار تحليل الشخصيات)",
          "Settings.Sidebar.Description.Feedback":
            "يعطي نتيجة نهائية بناءً على النقاط المخصصة لكل سؤال",
          "Add Ending": "إضافة نهاية",
          Close: "إغلاق",
          "DisabledScoreScreen.Tooltip":
            "  لحساب النتيجة، قم بتغيير النموذج إلى `اختبار` في الإعدادات",
          "Thank You Screen": "شاشة النهاية",
          "Score Screen": "شاشة النتيجة",
          "SurveyModal.Header": "نحن حريصون على سماع آرائك 🧐",
          Total: "إجمالي",
          "CopyButton.Default": "نسخ",
          "CopyButton.Success": "تم نسخ الرابط بنجاح",
          "ShareModal.Header": "شارك إستبيانك",
          "ShareModal.Body": "انسخ الرابط أو شاركه على وسائل التواصل الاجتماعي",
          "NewForm.Button": "نموذج جديد ",
          "NewFormModal.Header": "أنشئ نموذجًا جديدًا",
          "NameForm.FormName.Label": "اسم النموذج",
          Addition: "إضافة",
          DefaultFormName: "نموذجي",
          "NameForm.UserType.Label": "ما هو غرضك من إنشاء هذا النموذج؟",
          "NameForm.UserType.Placeholder": "اختر من الخيارات",
          University: "الجامعة",
          Work: "العمل",
          Personal: "غرض شخصي",
          "NameForm.FormType.Label": "ما هو نوع النموذج الذي تود إنشاءه؟",
          "NameForm.FormType.Placeholder": "اختر من الخيارات",
          "NameForm.Sector.Label": "في أي قطاع تعمل؟",
          "NameForm.Company.Label": "لأي شركة تعمل؟",
          "NameForm.PhoneNumber.Label": "رقم التليفون",
          Research: "بحث",
          Registration: "آلية تسجيل",
          Poll: "استطلاع",
          Sale: "مبيعات",
          Feedback: "تقييمات وملاحظات",
          Contact: "آلية تواصل",
          Application: "طلب",
          Education: "التعليم",
          Healthcare: "الصحة",
          Beauty: "التجميل",
          Fitness: "اللياقة البدنية",
          Technology: "تكنولوجيا",
          Retail: "بيع بالتجزئة",
          Other: "آخر",
          ...pricing,
          "AccountLimit.Header":
            "لقد وصلت إلى الحد الأقصى المتاح لعدد الردود على حسابك. يرجى تحديث اشتراكك للوصول إلى الردود الإضافية!",
          "AccountLimit.CTA": "تحديث حسابي الآن",
          DisableForm: "تعطيل النموذج",
          DisableWhatsAppNotifications: "تعطيل إشعارات الواتساب",
          DisableWhatsAppNotificationsHelper:
            "سيؤدي هذا إلى تعطيل إشعارات الواتساب للنموذج المحدد",
          "DisableForm.Desc":
            "يمكنك إيقاف النموذج عن استقبال الردود علماً بأن البيانات ستبقى محفوظة",
          "DisableForm.Helper": "يجب أن يكون لديك حساب مدفوع لتعطيل النموذج",
          DisabledFormModalText: "تم تعطيل هذا النموذج",
          AccountSettings: "إعدادات الحساب",
          Upgrade: "تحديث حسابي",
          Record: "إرسال صوت",
          Voice: "صوت",
          Email: "البريد الالكتروني",
          Phone: "رقم الموبايل",
          "Download QR code": "تنزيل رمز الاستجابة السريعة",
          "Theme.Design.Title": "التخصيصات",
          "Theme.FontType.Label": "نوع الخط",
          "Theme.FontType.Placeholder": "نوع الخط",
          "Theme.Design.Questions.Label": "لون الأسئلة",
          "Theme.Design.Answers.Label": "لون الإجابات",
          "Theme.Design.Buttons.Label": "لون الأزرار",
          "Theme.Design.ButtonText.Label": "لون النص في الأزرار",
          "Theme.Design.Background.Label": "لون الخلفية",
          "Theme.Design.Format.Label": "التنسيق",
          "Theme.Design.WelcomeAndEndingAlign.Label": "شاشة البداية والخاتمة",
          "Theme.Design.QuestionsAlign.Label": "الأسئلة",
          "Theme.Design.Logo.Label": "اضافة شعار",
          "Theme.Design.Logo.Success": "تم رفع الشعار بنجاح",
          "Theme.Design.Logo.Tooltip": "شعار الشركة",
          "Theme.Design.Colors.Label": "الألوان",
          "Theme.Design.Colors.HexCode": "كود Hex",
          "Theme.Design.Colors.Transparency": "الشفافية",
          "Theme.Design.Colors.AddColor": "اضافة",
          Add: "أضف",
          "Max.File.4MB": "حجم الملف أكبر من ٤ ميغا بايت.",
          "Welcome Screen": "مقدمة",
          Start: "ابدأ",
          "Success Icon": "رمز النجاح",
          "MultipleSelection.Label": "يمكن للمستخدم اختيار أكثر من خيار واحد",
          "MultipleSelection.HelpText": "اختر {{number}}",
          "VoiceRecording.Limit":
            "يجب أن يكون التسجيل الصوتي لمدة ثانية على الأقل",
          Quote: "إضافة عنوان وشرح",
          "Number of Selections": "عدد الاختيارات",
          Score: "اختبار التقييم",
          Quiz: "اختبار النتائج",
          "Link outcomes to endings": "ربط النتائج بالنهايات",
          "Choose Answers": "ربط الإجابة بالنتيجة",
          "Cannot exceed the number of choices": "لا يمكن تجاوز عدد الخيارات",
          "Remove SeenJeem Logo": "إزالة شعار سين جيم",
          "RemoveLogo.PaidAccount":
            "يجب أن يكون لديك حساب مدفوع لإزالة شعار سين جيم",
          Reorder: "ترتيب",
          Required: "الزامي",
          "Email.Validation.Error": "يرجى إدخال عنوان بريد إلكتروني صالح",
          "Minimum 2 choices": "الحد الأدنى {{number}} اختيارات",
          "Phone.Validation.Error": "يرجى إدخال رقم هاتف صالح",
          Ok: "حسنا",
          Small: "صغير",
          Medium: "متوسط",
          Large: "كبير",
          Upload: "تحميل",
          Arrangement: "ترتيب",
          "Choose Question Type": "اختر نوع السؤال",
          "Welcome.Modal.Title": "اختر نوع المقدمة",
          "Endings.Modal.Title": " اختر نوع الخاتمة",
          WelcomeScreen: "شاشة الترحيب",
          EndingScreen: "الخاتمة",
          "Login.Welcome": "مرحبا بك في",
          "Login.Button": "دخول",
          "Login.Forgot": "نسيت كلمة المرور",
          "Login.ForgotSent": "تم إرسال الرابط!",
          "Login.NewAccount": "ليس لديك حساب؟",
          "Login.Google": "Connect with Google",
          "Login.Signup": "اشتراك",
          "Login.Facebook": "Connect with Facebook",
          "Login.NotFound": "البريد الإلكتروني غير مسجل",
          "Login.WrongCred": "بيانات الاعتماد غير صالحة",
          "Signup.Title": "حساب جديد",
          "Signup.Save": "حفظ",
          "Signup.Cancel": "إلغاء",
          "Signup.Details": "أخبرنا قليلاً عن نفسك",
          "Signup.WhyUse": "لماذا ستستخدم سين جيم؟",
          "Signup.WhatSector": "في أي قطاع من العمل؟",
          "Signup.WhichCompany": "لأي شركة تعمل؟",
          "Signup.FormPurpose": "ما هو الغرض من النموذج الذي تريد إنشاؤه؟",
          "Signup.Exists": "مسجل بالفعل",
          Transcription: "تفريغ النص",
          TypeHere: "أكتب هنا...",
          FilterQuestions: "تصفية الأسئلة",
          TableSettings: "إعدادات الجدول",
          Selected: "تم تحديد",
          Delete: "حذف",
          DeleteModalHeader: "أنت على وشك حذف {{number}} من الردود",
          DeleteModalSubHeader1:
            "هل أنت متأكد أنك تريد حذف هذه الردود بشكل دائم؟",
          DeleteModalSubHeader2: "لا يمكننا استعادة أي ردود بمجرد حذفها",
          DeleteSuccess: "تم حذف الردود بنجاح",
          NoAnswer: "لا يوجد جواب",
          Saved: "تم الحفظ",
          DisableFormExitWarning: "تحذير: لديك تغييرات غير محفوظة",
          ChooseTemplate: "اختر قالبًا",
          UseTemplate: "استخدم قالبًا",
          "UseTemplate.Helper": "اختر نموذجًا مسبقًا وقم بتخصيصه كما ترغب",
          StartFromScratch: "ابدأ من البداية",
          "StartFromScratch.Helper": "ابدأ مباشرة وابنِ شيئًا جميلاً",
          "Template.CustomerSatisfaction": "نموذج استبيان رضا العملاء",
          "Template.Departure": "نموذج مغادرة",
          "Template.Employment": "نموذج طلب توظيف",
          "Template.Volunteer": "نموذج طلب تطوع",
          "Template.Effectiveness": "نموذج تقييم فعالية",
          "Overview.Placeholder": "اشرح ما تدور حوله هذه المحادثة",
          ResponsePageSubHeader:
            "يمكنك الضغط على كل إجابة فردية لاستعراضها على حدة.",
          "Whatsapp.Title": "احصل على ردودك على الواتس اب مجان",
          "Whatsapp.Description":
            "يمكنك تمكين أو تعطيل هذه الميزة لكل نموذج من صفحة الإعدادات",
          "Whatsapp.Submit": "اشترك الآن",
        },
      },
    },
  });

export default i18n;
