import { Text } from "@chakra-ui/react";
import { useSiteContext } from "../../context/SiteContext";
import { logAnalyticsEvent } from "../../firebase/firebase-analytics";

export default () => {
  const { language, toggleLanguage } = useSiteContext();

  return (
    <Text
      style={{ cursor: "pointer" }}
      onClick={() => {
        logAnalyticsEvent("change_language", {
          value: language === "ar" ? "English" : "عربي",
        });
        toggleLanguage();
      }}
    >
      {language === "ar" ? "English" : "عربي"}
    </Text>
  );
};
