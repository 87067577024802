import { Answer, Form, FormChoice, Variables } from "types/Form";
import { FormFieldLogicActionCondition } from "types/Logic";

export const scoreCalculator = (answers: Answer[], form: Form): Variables => {
  let variables: Variables = {};
  if (form.variables) variables = JSON.parse(JSON.stringify(form.variables));
  if (!("score" in variables))
    variables = {
      ...variables,
      score: 0,
    };

  form.logic?.forEach((logic) => {
    logic.actions.forEach((action) => {
      answers.forEach((answer) => {
        // opinion_scale, multiple_choice
        if (answer.type === "choices") {
          answer.value.choices.forEach((choice) => {
            if (action.action === "add") {
              if (checkConditions(action.condition, answer, choice)) {
                if ("score" in variables) {
                  variables.score += action.details.value.value;
                } else {
                  variables.score = action.details.value.value;
                }
              }
            }
          });
        }
      });
    });
  });

  return variables;
};

const checkConditions = (
  condition: FormFieldLogicActionCondition,
  answer: Answer,
  choice: FormChoice
) => {
  let check = true;
  condition.vars.forEach((variable) => {
    if (condition.op === "equals") {
      if (variable.type === "field") {
        if (variable.value !== answer.field_id) check = false;
      }

      if (variable.type === "choice") {
        if (variable.value !== choice.id) check = false;
      }
    }
  });

  return check;
};

export const totalScoreCalculator = (form: Form) => {
  let total = 0;

  form.fields?.forEach((field) => {
    let fieldMax = 0;

    form.logic?.forEach((logic) => {
      // find max score per field
      if (logic.field_id === field.field_id) {
        logic.actions.forEach((action) => {
          if (action.details.value.value > fieldMax) {
            fieldMax = action.details.value.value;
          }
        });
      }
    });
    total += fieldMax;
  });

  return total;
};
