import { useRef, useEffect, useCallback, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { FormField, VoiceAnswer } from "types/Form";
import { useStaticFormContext } from "context/StaticFormContext";
import uuid from "react-uuid";
import { StereoAudioRecorder, RecordRTCPromisesHandler } from "recordrtc";
import { useTranslation } from "react-i18next";
import { useFormContext } from "context/FormContext";
import { DEFULT_BUTTON_COLOR } from "utils/addForm/formConstants";
import { SettableMetadata } from "firebase/storage";
import { uploadToStorage } from "./RecordButtonContainer.utils";
import { RecordButton } from "./RecordButton";

// todo:
// fix style on mobile preview (should use minWidth)

export const RecordView = ({
  field,
  isStatic,
  setChatAudio = () => {},
}: {
  field: FormField;
  isStatic: boolean;
  setChatAudio?: (audio: any) => void;
}) => {
  const mediaRecorder = useRef<any>(null);
  const item = useRef<HTMLElement>(null);

  const { form } = useFormContext();

  const { i18n, t } = useTranslation();
  const arabicT = i18n.getFixedT("ar");

  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [stream, setStream] = useState<any>();
  const [permission, setPermission] = useState<boolean>(false);
  const [recordError, setRecordError] = useState<string>();

  const [recordStartTime, setRecordStartTime] = useState<Date>();

  const [audio, setAudio] = useState<any>(null);

  const fontFamily = form?.theme?.font_family;
  const questionColor = form?.theme?.colors?.question;

  const startRecording = async () => {
    if (!isStatic) return;

    if (recordingStatus === "recording") {
      stopRecording();
      return;
    }

    setRecordError(undefined);

    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });

    setPermission(true);
    setStream(mediaStream);
  };

  const stopRecording = async () => {
    if (!isStatic) return;

    if (recordingStatus !== "recording") {
      return;
    }

    setRecordingStatus("inactive");
    await mediaRecorder.current.stopRecording();

    const blob = await mediaRecorder.current.getBlob();

    const audioUrl = URL.createObjectURL(blob);

    if (recordStartTime) {
      const audioDuration =
        (new Date().getTime() - recordStartTime.getTime()) / 1000;

      if (audioDuration < 1) {
        if (field.properties.required) {
          setRecordError(
            form?.language === "ar"
              ? "VoiceRecording.Limit"
              : "Voice recording must be at least 1 second long"
          );
        }
      } else {
        setAudio(audioUrl);
      }
    }
    stream.getAudioTracks()[0].stop();
    setPermission(false);
  };

  const { answers, setAnswers } = useStaticFormContext();

  let timerID;
  let counter = 0;

  const pressHoldEvent = new CustomEvent("pressHold");

  const pressHoldDuration = 60000;

  const pressingDown = useCallback(
    (e) => {
      // if (audio !== undefined) return;
      startRecording();
      requestAnimationFrame(timer);

      e.preventDefault();
    },
    [audio, startRecording]
  );

  const notPressingDown = useCallback(async () => {
    // if (audio !== undefined) return;
    if (recordingStatus === "recording") {
      cancelAnimationFrame(timerID);

      counter = 0;
      stopRecording();
    }
  }, [recordingStatus, stopRecording]);

  useEffect(() => {
    if (stream?.active) {
      const recorder = new RecordRTCPromisesHandler(stream, {
        type: "audio",
        mimeType: "audio/wav",
        recorderType: StereoAudioRecorder,
      });

      mediaRecorder.current = recorder;

      setRecordingStatus("recording");

      recorder.startRecording();

      setRecordStartTime(new Date());
    }
  }, [stream?.active]);

  useEffect(() => {
    const id = uuid();
    if (audio) {
      const metaData: SettableMetadata = {};

      if (form?.uid && form?.user) {
        metaData.customMetadata = {
          formId: form?.uid,
          formOwner: form?.user,
        };
      }

      uploadToStorage(audio, id, metaData);

      if (setChatAudio && recordStartTime) {
        setChatAudio({
          source: id,
          duration: (new Date().getTime() - recordStartTime.getTime()) / 1000,
        });
      }

      if (answers && setAnswers && recordStartTime) {
        const objIndex = answers.findIndex(
          (obj) => obj.field_id === field.field_id
        );

        const newAnswer: VoiceAnswer = {
          field_id: field.field_id,
          type: "voice",
          value: {
            source: id,
            duration: (new Date().getTime() - recordStartTime.getTime()) / 1000,
          },
        };

        if (objIndex < 0) {
          setAnswers([...answers, newAnswer]);
        } else {
          answers[objIndex] = newAnswer;
          setAnswers([...answers]);
        }
      }
    }
  }, [audio]);

  useEffect(() => {
    if (item && item.current) {
      item.current?.addEventListener("mousedown", pressingDown, false);
      item.current?.addEventListener("mouseup", notPressingDown, false);
      item.current?.addEventListener("mouseleave", notPressingDown, false);
      item.current?.addEventListener("touchstart", pressingDown, false);
      item.current?.addEventListener("touchend", notPressingDown, false);
    }

    return () => {
      item.current?.removeEventListener("mousedown", pressingDown, false);
      item.current?.removeEventListener("mouseup", notPressingDown, false);
      item.current?.removeEventListener("mouseleave", notPressingDown, false);
      item.current?.removeEventListener("touchstart", pressingDown, false);
      item.current?.removeEventListener("touchend", notPressingDown, false);
    };
  }, [notPressingDown, pressingDown]);

  function timer() {
    if (counter < pressHoldDuration) {
      timerID = requestAnimationFrame(timer);
      counter++;
    } else {
      item.current?.dispatchEvent(pressHoldEvent);
    }
  }

  const buttonColor =
    form?.theme?.colors?.button !== DEFULT_BUTTON_COLOR
      ? form?.theme?.colors?.button
      : "#3c2399";

  return (
    <Flex w="100%">
      <Flex w="auto" m={["auto"]} ms={["auto", "auto", 0]} flexDir="column">
        <Text
          mx="auto"
          fontSize="20px"
          mb={5}
          color={questionColor ?? "teal"}
          fontFamily={fontFamily ?? "inherit"}
        >
          {t("Record")}
        </Text>
        <RecordButton
          status={recordingStatus}
          innerRef={item}
          mediaBlobUrl={audio}
          isDisabled={!isStatic}
          buttonColor={buttonColor}
        />
      </Flex>
      <Text
        position="absolute"
        bottom="9px"
        fontSize="12px"
        color={questionColor ?? "teal"}
        my="10px"
      >
        {recordError
          ? form?.language === "ar"
            ? arabicT(recordError ?? "VoiceRecording.Limit")
            : recordError
          : null}
      </Text>
    </Flex>
  );
};
