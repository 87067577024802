import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  IconButton,
  Flex,
  Switch,
  Text,
  Input,
  FormControl,
  FormLabel,
  FlexProps,
  useToast,
  Box,
} from "@chakra-ui/react";
import { SettingsIcon } from "@chakra-ui/icons";
import { t } from "i18next";
import { ReactNode, useMemo, useRef, useState } from "react";
import { useFormContext } from "context/FormContext";
import { changeFormHandler } from "components/form/utils/utils";
import { FieldType, Form, FormField } from "types/Form";
import { useSiteContext } from "context/SiteContext";
import OverlayWrapper from "components/form/general/overlay/OverlayWrapper";
import { logAnalyticsEvent } from "../../firebase/firebase-analytics";

export const DEFAULT_NUMBER_OF_SELECTIONS = 2;

interface MultipleChoiceSettingsModalChildrenProps {
  onClick: () => void;
}
interface MultipleChoiceSettingsModalProps extends FlexProps {
  field: FormField;
  isDefaultOpen?: boolean;
  children?: (props: MultipleChoiceSettingsModalChildrenProps) => ReactNode;
}

export default ({
  field,
  isDefaultOpen,
  ...props
}: MultipleChoiceSettingsModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: isDefaultOpen,
  });
  const [previousForm, setPreviousForm] = useState<string>();
  const { form, setForm } = useFormContext();
  const { language, isDesktop } = useSiteContext();

  // fix stale closure
  const ref = useRef<any>({ form: [] }).current;
  ref.form = form;

  const changeForm = ({
    fieldAttribute,
    value,
    type,
  }: {
    fieldAttribute: string;
    value: any;
    type: string;
  }) => {
    if (!field?.field_id) return;
    const updatedForm = changeFormHandler({
      form: ref.form,
      fieldAttribute,
      value,
      id: field.field_id,
      type,
    });

    if (setForm) {
      setForm(updatedForm);
    }
  };

  if (!form) return null;

  const numberOfChoices = (field.properties.choices ?? []).length;

  const error = useMemo(() => {
    if ((field.properties.number_of_selection ?? 1) > numberOfChoices) {
      return t("Cannot exceed the number of choices");
    }

    if (
      (field.properties.number_of_selection ?? 1) < DEFAULT_NUMBER_OF_SELECTIONS
    ) {
      return t("Minimum 2 choices", {
        number: DEFAULT_NUMBER_OF_SELECTIONS.toLocaleString(
          language === "en" ? "en-US" : "ar-EG"
        ),
      });
    }

    return undefined;
  }, [field.properties.number_of_selection, numberOfChoices, language]);

  const onCancel = () => {
    // if there is an error then rollback to previous changes
    if (error && previousForm && setForm) {
      setForm(JSON.parse(previousForm) as Form);
    }

    onClose();
  };

  const onSettingClicked = () => {
    logAnalyticsEvent("open_multiple_choice_settings");
    onOpen();

    // on every open of modal store previous form
    setPreviousForm(JSON.stringify(form));
  };

  return (
    <Flex {...props}>
      {props.children ? (
        props.children({
          onClick: onSettingClicked,
        })
      ) : (
        <IconButton
          icon={
            <SettingsIcon
              color={{ base: "#3D2399", lg: "#6C6A6A" }}
              width={{ base: "16px", lg: undefined }}
              height={{ base: "16px", lg: undefined }}
            />
          }
          onClick={onSettingClicked}
          aria-label="settings"
          bg="white"
          border="1px"
          borderColor={{ base: "#3D2399", lg: "#6C6A6A" }}
        />
      )}

      <OverlayWrapper
        title={t("Settings")}
        isOpen={isOpen}
        onClose={() => {
          logAnalyticsEvent("close_multiple_choice_settings");
          onCancel();
        }}
        size={isDesktop ? "md" : "6xl"}
      >
        <Flex gap="10px" alignItems="center">
          <Flex flex={1}>
            <Text fontSize="18px">{t("Required")}</Text>
          </Flex>
          <Switch
            colorScheme="teal"
            isChecked={field.properties.required}
            onChange={(e) => {
              changeForm({
                fieldAttribute: "required",
                value: e.target.checked,
                type: FieldType.MULTIPLE_CHOICE,
              });
            }}
          />
        </Flex>

        <Flex gap="10px" alignItems="center" mt={5}>
          <Flex flex={1}>
            <Text fontSize="18px">{t("MultipleSelection.Label")}</Text>
          </Flex>
          <Switch
            colorScheme="teal"
            // todo: will break with multiple screens
            isChecked={field.properties.multiple_selection}
            onChange={(e) => {
              changeForm({
                fieldAttribute: "multiple_selection",
                value: e.target.checked,
                type: FieldType.MULTIPLE_CHOICE,
              });
              if (e.target.checked) {
                logAnalyticsEvent("use_multiple_selection");
              } else {
                logAnalyticsEvent("not_use_multiple_selection");
              }
            }}
          />
        </Flex>

        <Flex flexDir="column" mt={5}>
          <Flex gap="10px" alignItems="center">
            <Flex flex={1}>
              <Text>{t("Number of Selections")}</Text>
            </Flex>
            <Input
              width="40px"
              type="number"
              min={1}
              px={0}
              textAlign="center"
              isDisabled={!field.properties.multiple_selection}
              value={
                field.properties.number_of_selection ??
                DEFAULT_NUMBER_OF_SELECTIONS
              }
              borderColor="#efefef"
              onChange={(e) => {
                changeForm({
                  fieldAttribute: "number_of_selection",
                  value: e.target.value.trim(),
                  type: FieldType.MULTIPLE_CHOICE,
                });
              }}
            />
          </Flex>
          {error && <Text fontSize="14px">{error}</Text>}
        </Flex>

        <ModalFooter
          mt={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="outline"
            borderColor="teal.500"
            color="teal.500"
            w="100%"
            maxW="200px"
            fontWeight="normal"
            onClick={onCancel}
            ms={2}
          >
            {t("Ok")}
          </Button>
        </ModalFooter>
      </OverlayWrapper>
    </Flex>
  );
};
