import {
  deleteObject,
  getDownloadURL,
  getMetadata,
  ref,
  uploadBytes,
  UploadResult,
} from "firebase/storage";
import { getFileBlob } from "components/buttons/RecordButton/RecordButtonContainer.utils";
import { storage } from "../firebase/firebase-config";

export const uploadFileToStorage = (
  fileUrl: string,
  storagePath: string
): Promise<UploadResult> => {
  return new Promise((resolve, reject) => {
    const storageRef = ref(storage, storagePath);
    getFileBlob(fileUrl, (blob) => {
      uploadBytes(storageRef, blob)
        .then((snapshot) => {
          //   console.log(snapshot.key);
          // console.log("Uploaded a blob or file!", snapshot);
          resolve(snapshot);
        })
        .catch(reject);
    });
  });
};

export const getFirebaseFileUrl = async (id: string) =>
  getDownloadURL(ref(storage, id));

export const checkFirebaseFileExist = async (id: string) => {
  const storageRef = ref(storage, id);

  try {
    await getMetadata(storageRef);
    return true;
  } catch (error) {
    if ((error as any).code === "storage/object-not-found") {
      return false;
    }
  }
};

export const deleteFirebaseFile = async (id: string) =>
  deleteObject(ref(storage, id));
