import { Button } from "@chakra-ui/react";

export default ({ children, innerref = undefined, ...props }) => (
  <Button
    h="40px"
    border="1px"
    borderColor="gray.300"
    dir="rtl"
    fontWeight="400"
    iconSpacing="14px"
    color="white"
    _focus={{ outline: "none" }}
    ref={innerref}
    {...props}
  >
    {children}
  </Button>
);
