import { Flex, Textarea, SlideFade } from "@chakra-ui/react";
import { EndingType, ThankYouScreen } from "types/Form";
import ShareIcons from "components/general/ShareIcons/ShareIcons";
import { useFormContext } from "context/FormContext";
import SuccessIcon from "components/field/SuccessIcon";
import { CTA } from "components/field/CTA";
import { useOnboardingFormContext } from "context/Onboarding/OnboardingFormContext";
import LogoCTA from "components/field/LogoCTA";
import { getFontSize } from "utils/form/fontSize";
import { useMemo } from "react";
import { ChangeFormProps } from "../types";

const ThankYouPage = ({
  isStatic,
  field,
  changeForm,
  width,
  minWidth,
  disableCTA,
}: {
  isStatic: boolean;
  field: ThankYouScreen;
  changeForm: (props: ChangeFormProps) => void;
  width: number;
  minWidth: number;
  disableCTA: boolean;
}) => {
  // todo: test this works
  const { form } = useFormContext();
  const { onClose } = useOnboardingFormContext();

  const textAlignment = form?.theme?.screens?.alignment;
  const fontSize = useMemo(
    () => getFontSize(form?.theme?.fields?.font_size, width > minWidth),
    [form?.theme?.fields?.font_size, width, minWidth]
  );
  const fontFamily = form?.theme?.font_family;
  const questionColor = form?.theme?.colors?.question;
  const button = form?.theme?.colors?.button;
  const buttonText = form?.theme?.colors?.button_content;

  return (
    <SlideFade
      delay={0.2}
      in
      style={{ width: "100%", height: "100%" }}
      offsetY={isStatic ? "100%" : "0%"}
    >
      <Flex direction="column" w="100%" fontWeight="400" gap="5px">
        <Flex mx="auto" w="100%" h="100%" flexDir="column">
          {(field.properties.show_success_icon === undefined ||
            field.properties.show_success_icon) && (
            <SuccessIcon backgroundColor={button} iconColor={buttonText} />
          )}
          <Textarea
            mt={2}
            disabled={isStatic}
            fontWeight="300"
            fontSize={fontSize}
            variant="unstyled"
            onChange={(e) =>
              changeForm({
                fieldAttribute: "title",
                value: e.target.value,
                id: field.id,
                type: EndingType.THANK_YOU,
              })
            }
            // textAlign={field.properties.right_align ? "right" : "center"}
            textAlign={textAlignment ?? "center"}
            fontFamily={fontFamily ?? "inherit"}
            value={field?.title}
            resize="none"
            px={1}
            border={isStatic ? "none" : "1px dashed"}
            borderColor="gray.300"
            overflow="hidden"
            h="auto"
            minH="0"
            ref={(node) => {
              if (!node) return;
              node.style.height = "auto";
              node.style.height = `${node?.scrollHeight}px`;
            }}
            style={{
              resize: "none",
              WebkitTextFillColor: questionColor ?? "gray.800",
              opacity: 1,
            }}
          />
        </Flex>
        <Flex w="100%">
          {form && field.properties.share_icons && <ShareIcons form={form} />}
        </Flex>
        {(field.properties.hide_logo === undefined ||
          !field.properties.hide_logo) && <LogoCTA disableCTA={disableCTA} />}
        {field.properties.show_cta && (
          <CTA width={width} minWidth={minWidth} onClick={onClose} />
        )}
      </Flex>
    </SlideFade>
  );
};

export default ThankYouPage;
