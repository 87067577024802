import { useState, useEffect } from "react";
import { useFormContext } from "context/FormContext";

export const useGetFormUrl = () => {
  const [formURL, setFormURL] = useState<string>("");
  const { form, isAiForm } = useFormContext();

  useEffect(() => {
    if (!form) return;

    const baseURL =
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000"
        : "https://seenjeem.io";
    const path = isAiForm ? "chat" : "form";
    if (form.uid) {
      setFormURL(`${baseURL}/${path}/${form.uid}`);
    }
  }, [form?.uid, isAiForm]);

  return formURL;
};
