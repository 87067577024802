import { Link } from "@chakra-ui/react";
import { NavLink as ReactRouterLink } from "react-router-dom";

const MenuItem = ({
  children,
  to,
  end = false,
  icon,
  iconActive,
  render,
}: {
  children?: any;
  to: string;
  end?: boolean;
  icon?: any;
  iconActive?: any;
  render: any;
}) => {
  return (
    <Link
      href={to}
      as={ReactRouterLink}
      to={to}
      // _activeLink={{
      //   borderBottom: "2px",
      //   borderBottomColor: "teal.600",
      //   "& > p": { fontWeight: 500 },
      //   color: "black",
      // }}
      _hover={{
        textDecoration: "none",
      }}
      _focus={{ border: "none" }}
      fontSize={["14px", "16px"]}
      fontWeight={500}
      h="100%"
      end={end}
      color="gray.400"
    >
      {
        ({ isActive }) => render({ isActive })
        // <Flex alignItems="center" mt={3} gap="7px">
        //   <Text fontWeight={300} display="block">
        // React.cloneElement(children, { isactive: isActive.toString() })
        //   </Text>
        //   {isActive ? iconActive ?? icon : icon}
        // </Flex>
      }
    </Link>
  );
};

export const withLink = (Component) => (props) =>
  (
    <MenuItem {...props}>
      {({ isActive }) => <Component isActive={isActive} {...props} />}
    </MenuItem>
  );

export default MenuItem;
