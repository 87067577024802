export function applyOpacity(color: string, opacity: number) {
  if (typeof color !== "string" || color.length !== 7) return color;

  if (opacity < 0) opacity = 0;
  else if (opacity > 100) opacity = 100;

  opacity = Math.round(opacity * 2.55);

  return color + opacity.toString(16).toUpperCase().padStart(2, "0");
}

export function colorWithoutOpacity(color: string) {
  if (typeof color !== "string") return color;

  return `#${color.replace("#", "").substring(0, 6)}`;
}

export function getColorAlphaProgress(color: string) {
  const hexOpacity = color.replace("#", "").padEnd(8, "FF").substring(6);
  const color255 = parseInt(hexOpacity, 16);

  return Math.ceil((color255 * 100) / 255);
}
