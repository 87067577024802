import { IconButton } from "@chakra-ui/react";
import { useSiteContext } from "context/SiteContext";
import { ReactComponent as ThemeIcon } from "icons/theme.svg";

const EditModeThemeIconButton = () => {
  const { onDesignOverlayOpen, isDesktop } = useSiteContext();
  return !isDesktop ? (
    <IconButton
      icon={<ThemeIcon height={16} width={16} />}
      onClick={onDesignOverlayOpen}
      aria-label="Theme"
      bg="white"
      border="1px"
      borderColor="#F3B244"
    />
  ) : null;
};

export default EditModeThemeIconButton;
