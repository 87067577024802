import { Flex, Text, Spinner } from "@chakra-ui/react";

export default () => {
  return (
    <Flex w="100%" height="100%">
      <Flex mx="auto" gap="10px" my="auto">
        <Spinner mx="auto" color="teal.600" />
      </Flex>
    </Flex>
  );
};
