import { Flex, Button } from "@chakra-ui/react";
import { isFieldAnswered } from "components/form/utils/fieldAnswer.utils";
import { useFormContext } from "context/FormContext";
import { useMemo } from "react";
import { EndingType } from "types/Form";
import {
  DEFULT_BUTTON_COLOR,
  DEFULT_BUTTON_CONTENT_COLOR,
} from "utils/addForm/formConstants";
import { useIsLastQuestion } from "utils/useIsLastQuestion";
import { useStaticFormContext } from "../../context/StaticFormContext";

export const FormFooter = ({
  width,
  isStatic,
  visiblePageNumber,
  minWidth,
  setVisiblePageNumber,
  onSaveResponse,
  language,
  arabicT,
  fieldId,
}) => {
  const { form } = useFormContext();
  const { answers } = useStaticFormContext();

  const fontFamily = form?.theme?.font_family;
  const buttonTextColor = form?.theme?.colors?.button_content;
  const buttonBackgroundColor = form?.theme?.colors?.button;

  const isDifferentButtonBG = buttonBackgroundColor !== DEFULT_BUTTON_COLOR;
  const isDifferentButtonContentColor =
    buttonTextColor !== DEFULT_BUTTON_CONTENT_COLOR;

  const buttonDisabled = useMemo(() => {
    return isFieldAnswered({
      answers: answers ?? [],
      form: form!,
      fieldId,
      isStatic,
    });
  }, [form, answers, isStatic, fieldId]);

  const isLastPageInFields = useIsLastQuestion(fieldId, form);

  return (
    <Flex flexGrow={1} mx="auto" ms={width < minWidth ? "auto" : 0}>
      {isStatic && (
        <Button
          onClick={() => {
            setVisiblePageNumber(visiblePageNumber + 1);
            onSaveResponse();
          }}
          maxH="none"
          fontWeight={400}
          color={isDifferentButtonContentColor ? buttonTextColor : "white"}
          bg={isDifferentButtonBG ? buttonBackgroundColor : "teal.600"}
          w="auto"
          h="35px"
          alignSelf="end"
          _hover={{
            bg: isDifferentButtonBG ? buttonBackgroundColor : "teal.400",
          }}
          fontFamily={fontFamily ?? "inherit"}
          borderRadius="3px"
          disabled={buttonDisabled}
          fontSize={["16px", "18px"]}
        >
          {isLastPageInFields
            ? language === "ar"
              ? arabicT("Submit")
              : "Submit"
            : language === "ar"
            ? arabicT("Next")
            : "Next"}
        </Button>
      )}
    </Flex>
  );
};
