import { ref, get, update, query } from "firebase/database";
import { Form } from "types/Form";

let db;

import("../../../context/firebase-db").then(({ database }) => {
  db = database;
});

export const isSurveyComplete = async (userId: string, callback) => {
  const formQuery = query(ref(db, `user/${userId}`));
  const snapshot = await get(formQuery);

  const data: { [key: string]: Form } = await snapshot.val();

  if (data?.hasCompletedSurvey) return callback(true);

  callback(false);

  return null;
};

export const setSurveyComplete = (userId: string) => {
  update(ref(db, `user/${userId}`), {
    hasCompletedSurvey: true,
  }).catch((err) => console.log({ err }));
};

export const isWhatsappSurveyComplete = async (userId: string, callback) => {
  const userQuery = query(ref(db, `user/${userId}`));
  const snapshot = await get(userQuery);

  const data: { [key: string]: Form } = await snapshot.val();

  if (!data?.number) return;

  if (data?.hasCompletedWhatsappSurvey) return callback(true);

  callback(false);

  return null;
};

export const setWhatsappSurveyComplete = (userId: string) => {
  update(ref(db, `user/${userId}`), {
    hasCompletedWhatsappSurvey: true,
  }).catch((err) => console.log({ err }));
};
