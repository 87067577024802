/* eslint-disable no-unreachable */
import { Dispatch, SetStateAction, useEffect, useMemo, useRef } from "react";
import { Flex, Box, useDimensions } from "@chakra-ui/react";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { useFormContext } from "context/FormContext";
import SettingsModal from "components/modals/SettingsModal";
import {
  EndingType,
  FieldType,
  FormField,
  ThankYouScreen,
  WelcomeScreens,
} from "types/Form";
import MultipleChoiceSettingsModal from "components/modals/MultipleChoiceSettingsModal";
import { useStaticFormContext } from "context/StaticFormContext";
import { isAllCounterSame, outcomeCalculator } from "utils/outcomeCalculator";
import BasicFieldSettingsModal from "components/modals/BasicFieldSettingsModal";
import { removePage } from "components/create/utils";
import { useSiteContext } from "context/SiteContext";
import { logAnalyticsEvent } from "../../firebase/firebase-analytics";
import Field from "./templates/Field";
import ThankYouPage from "./templates/ThankYouPage";
import ScorePage from "./templates/ScorePage";
import {
  changeFormHandler,
  getFieldIndexById,
  hasRequiredOption,
  isThankYouScreen,
} from "./utils/utils";
import { ChangeFormProps } from "./types";
import FormCompanyLogo from "./general/compayLogo/FormCompanyLogo";
import WelcomeScreen from "./templates/WelcomePage";
import QuotePage from "./templates/QuotePage";
import EditModeBackIconButton from "./general/EditModeBackIconButton";
import EditModeThemeIconButton from "./general/EditModeThemeIconButton";
import EditModeTopBoxWrapper from "./general/EditModeTopBoxWrapper";
import EditModeDeleteIconButton from "./general/EditModeDeleteIconButton";

interface FormProps {
  visiblePageNumber: number;
  setVisiblePageNumber: Dispatch<SetStateAction<number>>;
  isStatic: boolean;
  disableCTA: boolean;
  onSaveResponse: () => void;
  onCloseForm?: () => void;
  isExpanded?: boolean;
  onExpand?: () => void;
  setIsOpen?: (isOpen: boolean) => void;
}

export const MIN_WIDTH = 615;

const logAnalytics = ({ fieldAttribute, value, id, type }) => {
  return logAnalyticsEvent("edit_form", {
    fieldAttribute: JSON.stringify(fieldAttribute),
    value: JSON.stringify(value),
    id: JSON.stringify(id),
    type: JSON.stringify(type),
  });
};

const debouncedLogAnalytics = AwesomeDebouncePromise(logAnalytics, 1000);

const Form = ({
  visiblePageNumber,
  setVisiblePageNumber,
  isStatic,
  disableCTA,
  onSaveResponse,
  isExpanded,
  onExpand,
  setIsOpen,
}: FormProps) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const dimensions = useDimensions(elementRef, true);
  const { form, setForm, selectedLogoUrl } = useFormContext();

  const { answers } = useStaticFormContext();
  const { isDesktop } = useSiteContext();

  // fix stale closure
  const ref = useRef<any>({ form: [] }).current;
  ref.form = form;

  const formFields = form?.fields ?? [];
  const welcomePages = form?.welcome_screens ?? [];
  const thankYouPages = form?.thankyou_screens ?? [];

  const pages: (FormField | ThankYouScreen | WelcomeScreens)[] = useMemo(
    () => [...welcomePages, ...formFields, ...thankYouPages],
    [welcomePages, formFields, thankYouPages, form]
  );

  useEffect(() => {
    if (visiblePageNumber >= pages.length) {
      setVisiblePageNumber(0);
    }
  }, [pages, visiblePageNumber]);

  const onSetVisiblePageNumber = (pageNumber) => {
    // 1. Check if up coming page is thank you screen or not.
    if (isThankYouScreen(pages[pageNumber])) {
      const counters = outcomeCalculator(answers ?? [], ref.form);

      let maxCounter = Number.MIN_VALUE;
      let maxCounterKey = "";

      // 2. check if all counters are same or not.
      if (isAllCounterSame(counters)) {
        // 3. if same then continue to next page as that is the thank you page.
        setVisiblePageNumber(pageNumber);
      } else {
        // 4. else check which counter is max and store max counter and counter key as well.
        Object.keys(counters).forEach((counterKey) => {
          if (counters[counterKey] > maxCounter) {
            maxCounter = counters[counterKey];
            maxCounterKey = counterKey;
          }
        });

        // 5. get thank you page id from the counter key.
        const endingPageId = maxCounterKey.split("counter_")[1];

        // 6. get page index based on id.
        const endingPageIndex = pages.findIndex(
          (page) => (page as ThankYouScreen).id === endingPageId
        );

        setVisiblePageNumber(
          endingPageIndex > -1 ? endingPageIndex : pageNumber
        );
      }
    } else {
      setVisiblePageNumber(pageNumber);
    }
  };

  const changeForm = ({ fieldAttribute, value, id, type }: ChangeFormProps) => {
    const updatedForm = changeFormHandler({
      form: ref.form,
      fieldAttribute,
      value,
      id,
      type,
    });

    if (setForm) {
      setForm(updatedForm);
    }
    debouncedLogAnalytics({ fieldAttribute, value, id, type });
  };

  const handleRemovePage = (index) => {
    if (form) {
      if (
        pages[index].type === EndingType.THANK_YOU ||
        pages[index].type === EndingType.SCORE
      ) {
        const deleteIndex = form.thankyou_screens?.findIndex(
          (page) => page.id === (pages[index] as ThankYouScreen).id
        );
        if (deleteIndex === thankYouPages.length - 1) setVisiblePageNumber(0);
        removePage(deleteIndex, form, "thankyou_screens", setForm);
      } else {
        removePage(
          welcomePages.length > 0 ? index - 1 : index,
          form,
          "fields",
          setForm
        );
      }
    }
  };

  const handleRemoveWelcomePage = (index) => {
    if (form) {
      removePage(index, form, "welcome_screens", setForm);
    }
  };

  const returnField = ({ field, pageIndex }) => {
    if (field.type === FieldType.WELCOME_SCREEN) {
      if (dimensions) {
        if (visiblePageNumber === pageIndex) {
          return (
            <Flex w="100%" key={form?.welcome_screens?.indexOf(field)}>
              {!isStatic && (
                <EditModeTopBoxWrapper>
                  {setIsOpen && (
                    <EditModeBackIconButton setIsOpen={setIsOpen} />
                  )}
                  <EditModeThemeIconButton />
                  <EditModeDeleteIconButton
                    onClick={() => handleRemoveWelcomePage(pageIndex)}
                  />
                </EditModeTopBoxWrapper>
              )}
              <WelcomeScreen
                key={form?.fields?.indexOf(field)}
                isStatic={isStatic}
                visiblePageNumber={visiblePageNumber}
                setVisiblePageNumber={onSetVisiblePageNumber}
                width={dimensions?.borderBox?.width}
                changeForm={changeForm}
                screen={field}
                minWidth={MIN_WIDTH}
                isExpanded={isExpanded}
                onExpand={onExpand}
              />
            </Flex>
          );
        }
      }
    }

    if (field.type === FieldType.QUOTE) {
      if (dimensions) {
        if (visiblePageNumber === pageIndex) {
          return (
            <Flex w="100%" key={form?.welcome_screens?.indexOf(field)}>
              {!isStatic && (
                <EditModeTopBoxWrapper>
                  {setIsOpen && (
                    <EditModeBackIconButton setIsOpen={setIsOpen} />
                  )}
                  <EditModeThemeIconButton />
                  {formFields.length > 1 && (
                    <EditModeDeleteIconButton
                      onClick={() => handleRemovePage(pageIndex)}
                    />
                  )}
                </EditModeTopBoxWrapper>
              )}
              <QuotePage
                key={form?.fields?.indexOf(field)}
                isStatic={isStatic}
                visiblePageNumber={visiblePageNumber}
                setVisiblePageNumber={onSetVisiblePageNumber}
                width={dimensions?.borderBox?.width}
                changeForm={changeForm}
                field={field}
                minWidth={MIN_WIDTH}
                isExpanded={isExpanded}
                onExpand={onExpand}
              />
            </Flex>
          );
        }
      }
    }

    if (
      field.type === FieldType.OPINION_SCALE ||
      field.type === FieldType.MULTIPLE_CHOICE ||
      field.type === FieldType.SHORT_ANSWER ||
      field.type === FieldType.VOICE ||
      field.type === FieldType.DATE ||
      field.type === FieldType.EMAIL ||
      field.type === FieldType.PHONE
    ) {
      if (dimensions) {
        if (visiblePageNumber === pageIndex) {
          return (
            <Flex w="100%" key={form?.fields?.indexOf(field)}>
              {!isStatic &&
                isDesktop &&
                field.type === FieldType.MULTIPLE_CHOICE && (
                  <Box position="absolute" right={2} top={2}>
                    <MultipleChoiceSettingsModal field={field} />
                  </Box>
                )}
              {!isStatic && (
                <EditModeTopBoxWrapper>
                  {setIsOpen && (
                    <EditModeBackIconButton setIsOpen={setIsOpen} />
                  )}
                  {hasRequiredOption(field.type) && (
                    <BasicFieldSettingsModal field={field} />
                  )}
                  {field.type === FieldType.MULTIPLE_CHOICE && (
                    <MultipleChoiceSettingsModal field={field} />
                  )}
                  <EditModeThemeIconButton />
                  {formFields.length > 1 && (
                    <EditModeDeleteIconButton
                      onClick={() => handleRemovePage(pageIndex)}
                    />
                  )}
                </EditModeTopBoxWrapper>
              )}
              <Box
                mt={{
                  base: 5,
                  lg: 0,
                }}
                w="100%"
              >
                <Field
                  key={form?.fields?.indexOf(field)}
                  isStatic={isStatic}
                  visiblePageNumber={visiblePageNumber}
                  actualPageNumber={getFieldIndexById(
                    ref.form.fields,
                    field.field_id
                  )}
                  setVisiblePageNumber={onSetVisiblePageNumber}
                  width={dimensions?.borderBox?.width}
                  field={field}
                  language={form?.language}
                  onSaveResponse={onSaveResponse}
                  changeForm={changeForm}
                  minWidth={MIN_WIDTH}
                  isExpanded={isExpanded}
                  onExpand={onExpand}
                />
              </Box>
            </Flex>
          );
        }
      }
    }

    if (field.type === EndingType.THANK_YOU) {
      if (dimensions) {
        if (visiblePageNumber === pageIndex) {
          return (
            <Flex w="100%" key={form?.fields?.indexOf(field)}>
              {!isStatic && field.type === EndingType.THANK_YOU && (
                <EditModeTopBoxWrapper>
                  {setIsOpen && (
                    <EditModeBackIconButton setIsOpen={setIsOpen} />
                  )}
                  <SettingsModal />
                  <EditModeThemeIconButton />
                  {thankYouPages.length > 1 && (
                    <EditModeDeleteIconButton
                      onClick={() => handleRemovePage(pageIndex)}
                    />
                  )}
                </EditModeTopBoxWrapper>
              )}
              <ThankYouPage
                key={form?.fields?.indexOf(field)}
                isStatic={isStatic}
                field={field}
                changeForm={changeForm}
                width={dimensions?.borderBox?.width}
                minWidth={MIN_WIDTH}
                disableCTA={disableCTA}
              />
            </Flex>
          );
        }
      }
    }

    if (field.type === EndingType.SCORE) {
      if (dimensions) {
        if (visiblePageNumber === pageIndex) {
          return (
            <Flex w="100%" key={form?.fields?.indexOf(field)}>
              {!isStatic && field.type === EndingType.SCORE && (
                <EditModeTopBoxWrapper>
                  {setIsOpen && (
                    <EditModeBackIconButton setIsOpen={setIsOpen} />
                  )}
                  <SettingsModal />
                  <EditModeThemeIconButton />
                  {thankYouPages.length > 1 && (
                    <EditModeDeleteIconButton
                      onClick={() => handleRemovePage(pageIndex)}
                    />
                  )}
                </EditModeTopBoxWrapper>
              )}
              <ScorePage
                key={form?.fields?.indexOf(field)}
                isStatic={isStatic}
                field={field}
                changeForm={changeForm}
                width={dimensions?.borderBox?.width}
                minWidth={MIN_WIDTH}
                disableCTA={disableCTA}
                uid={form?.uid}
              />
            </Flex>
          );
        }
      }
    }
    return null;
  };

  const returnPages = () => {
    return pages
      .filter((field) => field?.active === true)
      .map((field, pageIndex) => {
        return returnField({ field, pageIndex });
      });
  };

  const formBackground = form?.theme?.colors?.background;
  const logoId = form?.theme?.logo_id;

  return (
    <Flex
      mx="auto"
      flexDir="column"
      w="100%"
      h="100%"
      backgroundColor={formBackground ?? "white"}
      borderRadius={{ base: 0, lg: "5px" }}
      dir={form?.language === "ar" ? "rtl" : "ltr"}
      ref={elementRef}
      overflow="auto"
      position="relative"
    >
      <Flex flex="1 0 55px" maxH="200px" />
      <Flex flex="0 1 auto" flexDir="row" maxH="auto">
        <Flex flex="1 1 12%" maxW="20%" flexBasis="30%" />
        <Flex flex="1 1 190px" maxW="60%">
          {dimensions && (
            <FormCompanyLogo
              selectedLogoUrl={selectedLogoUrl}
              logoId={logoId}
            />
          )}
          {returnPages()}
        </Flex>
        <Flex flex="0 1 12%" />
      </Flex>
      <Flex flex="1 0 55px" maxH="200px" />
    </Flex>
  );
};

export default Form;
