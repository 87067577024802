import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { IconButton, Flex, Icon } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { ReactComponent as MicrophoneIcon } from "./microphone-342.svg";
import { styles, outerCircleVariants } from "./RecordButton.styles";

export const RecordButton = ({
  status,
  innerRef,
  mediaBlobUrl,
  isDisabled,
  buttonColor,
}: {
  status: string;
  innerRef: any;
  mediaBlobUrl?: string;
  buttonColor?: string;
  isDisabled: boolean;
}) => {
  const innerCircleAnimation = useAnimation();
  const outerCircleAnimation = useAnimation();

  useEffect(() => {
    (async () => {
      if (status === "recording") {
        await outerCircleAnimation.start("largeCircle");
        await outerCircleAnimation.start(["pulseOut", "pulseIn"], {
          repeat: Infinity,
          repeatType: "mirror",
        });
      } else {
        await outerCircleAnimation.start("circle");
      }
    })();
  }, [status]);

  useEffect(() => {
    (async () => {
      if (status === "recording") {
        await innerCircleAnimation.start("square");
      } else {
        await innerCircleAnimation.start("circle");
      }
    })();
  }, [status]);

  useEffect(() => {
    outerCircleAnimation.set("circle");
  }, [buttonColor]);

  return (
    <Flex w="100%" flexDir="column">
      <Flex ref={innerRef} flexDir="column">
        <motion.div style={styles.container}>
          <motion.div
            initial="circle"
            animate={outerCircleAnimation}
            variants={outerCircleVariants(buttonColor)}
            style={{ ...styles.circle, ...styles.outerCircle }}
          />

          <IconButton
            w="80%"
            h="80%"
            bg="none"
            color={buttonColor ?? "teal"}
            _hover={{
              background: "none",
            }}
            _active={{
              outline: "none",
            }}
            _focus={{
              outline: "none",
            }}
            colorScheme={buttonColor ?? "teal"}
            aria-label="Call Sage"
            fontSize="20px"
            disabled={isDisabled}
            cursor={isDisabled ? "not-allowed" : "pointer"}
            icon={<MicrophoneIcon height="40px" fill={buttonColor ?? "teal"} />}
          />
        </motion.div>
      </Flex>
      <Flex>
        {mediaBlobUrl && (
          <Flex mt={10} w="100%" maxW="200px" mx="auto">
            <Icon w="100%" mx="auto">
              <CheckIcon color={buttonColor ?? "teal"} />
            </Icon>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
