import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import uuid from "react-uuid";
import MultipleChoiceButton from "components/field/MultipleChoiceButton";
import { useStaticFormContext } from "context/StaticFormContext";
import { FormChoice, FormField, Answer } from "types/Form";
import AddChoiceButton from "components/field/AddChoiceButton";
import { useFormContext } from "context/FormContext";
import { DEFAULT_NUMBER_OF_SELECTIONS } from "components/modals/MultipleChoiceSettingsModal";
import {
  DEFULT_BUTTON_COLOR,
  DEFULT_BUTTON_CONTENT_COLOR,
} from "utils/addForm/formConstants";
import { isChoiceSelected } from "./utils";

export const MultipleChoiceButtons = ({
  visiblePageNumber,
  language,
  choices,
  changeForm,
  isStatic,
  field,
}: {
  visiblePageNumber?: number;
  language: string;
  choices?: FormChoice[];
  changeForm: ({ fieldAttribute, value, id, type }) => void;
  isStatic: boolean;
  field: FormField;
}) => {
  const { form } = useFormContext();
  const { answers, setAnswers } = useStaticFormContext();

  const { i18n } = useTranslation();
  const arabicT = i18n.getFixedT("ar");

  const multipleChoiceHintColor = form?.theme?.colors?.question;

  // fixed stale closure
  const ref = useRef<any>({ choices: [] }).current;
  ref.choices = choices;

  useEffect(() => {
    if (!choices) {
      changeForm({
        fieldAttribute: "choices",
        value: [],
        id: field?.field_id,
        type: field?.type,
      });
    }
  }, []);

  const addChoice = () => {
    changeForm({
      fieldAttribute: "choices",
      value: [
        ...ref.choices,
        // todo fix translation
        {
          id: uuid(),
          label:
            language === "en"
              ? `Choice ${ref.choices.length + 1}`
              : arabicT("Choice {{number}}", {
                  number: (ref.choices.length + 1).toLocaleString("ar-EG"),
                }),
        },
      ],
      id: field?.field_id,
      type: field?.type,
    });
  };

  const removeChoice = ({ choiceId }) => {
    const newChoices = ref.choices.filter((choice) => choice.id !== choiceId);
    changeForm({
      fieldAttribute: "choices",
      value: newChoices,
      id: field.field_id,
      type: field?.type,
    });
  };

  const editChoice = ({ text, choiceId }) => {
    ref.choices.find((choice) => choice.id === choiceId).label = text;
    changeForm({
      fieldAttribute: "choices",
      value: ref.choices,
      id: field.field_id,
      type: field?.type,
    });
  };

  const submitResponse = (choiceId: string) => {
    if (answers && setAnswers && field.field_id !== undefined) {
      const objIndex = answers.findIndex(
        (obj) => obj.field_id === field.field_id
      );

      const oldChoices = field.properties.multiple_selection
        ? (
            answers[objIndex]?.value as {
              choices: FormChoice[];
            }
          )?.choices ?? []
        : [];

      const existingChoiceIndex = oldChoices.findIndex(
        (obj) => obj.id === choiceId
      );

      if (existingChoiceIndex > -1) {
        oldChoices.splice(existingChoiceIndex, 1);
      } else {
        oldChoices.push({
          id: choiceId,
          // todo add label
          label: "",
        });
      }

      const newAnswer: Answer = {
        field_id: field.field_id,
        type: "choices",
        value: {
          choices: oldChoices,
        },
      };

      if (objIndex < 0) {
        setAnswers([...answers, newAnswer]);
      } else {
        answers[objIndex] = newAnswer;
        setAnswers([...answers]);
      }
    }
  };

  const buttonTextColor = form?.theme?.colors?.button_content;
  const buttonBackgroundColor = form?.theme?.colors?.button;
  const fontFamily = form?.theme?.font_family;

  const isDifferentButtonContentColor =
    buttonTextColor !== DEFULT_BUTTON_CONTENT_COLOR;

  return (
    <Flex
      justifyContent="space-between"
      alignSelf="start"
      mb={0}
      flexDir="column"
      gap="5px"
      maxW="100%"
    >
      {field.properties.multiple_selection && (
        <Text
          mb={2}
          fontSize={14}
          color={multipleChoiceHintColor ?? "gray.600"}
        >
          {language === "ar"
            ? arabicT("MultipleSelection.HelpText", {
                number: parseInt(
                  (
                    field.properties.number_of_selection ??
                    DEFAULT_NUMBER_OF_SELECTIONS
                  ).toString(),
                  10
                ).toLocaleString("ar-EG"),
              })
            : `Choose ${
                field.properties.number_of_selection ??
                DEFAULT_NUMBER_OF_SELECTIONS
              }`}
        </Text>
      )}
      {choices?.map(({ id, label }, choiceIndex) => {
        const isSelected = isChoiceSelected({ id, answers, field });

        return (
          <MultipleChoiceButton
            choiceIndex={choiceIndex}
            choiceId={id}
            language={language}
            isStatic={isStatic}
            label={label}
            key={id}
            // todo:  does this work?
            isSelected={isSelected}
            removeChoice={removeChoice}
            editChoice={editChoice}
            submitResponse={submitResponse}
            {...{
              buttonTextColor: isDifferentButtonContentColor
                ? buttonTextColor
                : DEFULT_BUTTON_COLOR,
              buttonBackgroundColor,
              fontFamily,
            }}
          />
        );
      })}
      {!isStatic && (
        <Flex>
          <AddChoiceButton addChoice={addChoice} />
        </Flex>
      )}
    </Flex>
  );
};
