import { DeleteIcon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/react";
import { useState } from "react";
import DeleteQuestionOverlay from "./overlay/DeleteQuestionOverlay";

const EditModeDeleteIconButton = ({
  isAiForm = false,
  onClick,
}: {
  isAiForm?: boolean;
  onClick: () => void;
}) => {
  const [isDeleteOverlayOpen, setIsDeleteOverlayOpen] = useState(false);
  return (
    <>
      <IconButton
        icon={
          <DeleteIcon
            width={isAiForm ? "12px" : 4}
            height={isAiForm ? "12px" : 4}
            color="#6C6A6A"
          />
        }
        size={isAiForm ? "xs" : undefined}
        onClick={() => setIsDeleteOverlayOpen(true)}
        aria-label="Delete"
        bg="white"
        border={!isAiForm ? "1px" : "none"}
        borderColor={!isAiForm ? "#D9D5D5" : "none"}
      />
      <DeleteQuestionOverlay
        isOpen={isDeleteOverlayOpen}
        onClose={() => setIsDeleteOverlayOpen(false)}
        onDelete={onClick}
      />
    </>
  );
};

export default EditModeDeleteIconButton;
