import { SettableMetadata, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../../firebase/firebase-config";

export const getFileBlob = function (url, cb) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.addEventListener("load", function () {
    cb(xhr.response);
  });
  xhr.send();
};

export const uploadToStorage = (
  audioUrl: string,
  id: string,
  metaData: SettableMetadata
) => {
  const storageRef = ref(storage, `voicenotes/${id}`);
  getFileBlob(audioUrl, (blob) => {
    uploadBytes(storageRef, blob, metaData).then((snapshot) => {
      //   console.log(snapshot.key);
      console.log("Uploaded a blob or file!");
    });
  });
};

// export const getStream = async (setStream) => {
//   if ("MediaRecorder" in window) {
//     try {
//       const mediaStream = await navigator.mediaDevices.getUserMedia({
//         audio: true,
//         video: false,
//       });
//       console.log({ mediaStream });
//       setStream(mediaStream);
//     } catch (err: any) {
//       alert(err?.message);
//     }
//   } else {
//     alert("The MediaRecorder API is not supported in your browser.");
//   }
// };
