import { Input, InputProps } from "@chakra-ui/react";

interface ShortAnswerProps {
  inputColor?: string;
  placeholder?: string;
  language?: string;
  fontFamily?: string;
  fontSize?: string;
  value?: string;
  disabled?: boolean;
  onChange?: InputProps["onChange"];
}

const ShortAnswer = ({
  inputColor,
  placeholder = "Enter value here...",
  language = "en",
  disabled,
  fontFamily,
  value,
  onChange,
  fontSize,
}: ShortAnswerProps) => {
  return (
    <Input
      height="auto"
      minH="40px"
      variant="flushed"
      fontSize={fontSize}
      _disabled={{ opacity: disabled ? 0.4 : 1 }}
      cursor={disabled ? "not-allowed" : undefined}
      borderColor={inputColor ?? "gray.200"}
      placeholder={placeholder}
      _placeholder={{ color: inputColor ?? "inherit" }}
      dir={language === "en" ? "ltr" : "rtl"}
      color={inputColor ?? "gray.600"}
      disabled={disabled}
      fontFamily={fontFamily ?? "inherit"}
      focusBorderColor={inputColor ?? "gray.800"}
      value={value}
      onChange={onChange}
    />
  );
};

export default ShortAnswer;
