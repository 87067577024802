import { Flex, SlideFade, Textarea } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";

import { Inputs } from "components/field/Inputs";
import { FormField } from "types/Form";
import { QuoteScreenFooter } from "components/field/QuoteScreenFooter";

interface QuotePageProps {
  visiblePageNumber: number;
  setVisiblePageNumber: Dispatch<SetStateAction<number>>;
  isStatic: boolean;
  width: number;
  changeForm: any;
  minWidth: number;
  field: FormField;
  isExpanded?: boolean;
  onExpand?: () => void;
}

const QuotePage = ({
  visiblePageNumber,
  isStatic,
  width,
  changeForm,
  minWidth,
  field,
  setVisiblePageNumber,
  isExpanded,
  onExpand,
}: QuotePageProps) => {
  return (
    <SlideFade
      delay={0.5}
      in
      style={{ width: "100%", height: "100%" }}
      offsetY="100%"
    >
      <Flex direction="column" w="100%">
        <Flex w="100%" h="100%" position="relative">
          <Flex direction="column" w="100%">
            <Inputs
              minWidth={minWidth}
              isStatic={isStatic}
              width={width}
              changeForm={changeForm}
              field={field}
              isExpanded={isExpanded}
              onExpand={onExpand}
            />
          </Flex>
        </Flex>
        <QuoteScreenFooter
          field={field}
          isStatic={isStatic}
          visiblePageNumber={visiblePageNumber}
          setVisiblePageNumber={setVisiblePageNumber}
        />
      </Flex>
    </SlideFade>
  );
};

export default QuotePage;
