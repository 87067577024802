/* eslint react/no-children-prop: 0 */ // --> OFF

import {
  Box,
  Text,
  Input,
  InputGroup,
  useDisclosure,
  useOutsideClick,
  InputRightAddon,
  FormHelperText,
  FormControl,
} from "@chakra-ui/react";
import {
  Country,
  SearchOnList,
} from "components/modals/NewFormModal/PhoneInput/SearchOnList";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useState, useRef } from "react";
import Countries from "../modals/NewFormModal/PhoneInput/countries.json";

export const isValidPhoneNumber = (value: string) => {
  const foundedCountry = Countries.find((country) =>
    value.includes(country.dial_code)
  );

  if (foundedCountry) {
    if (foundedCountry.length.length === 1) {
      const parsedPhoneNumber = value
        .trim()
        .slice(foundedCountry.dial_code.length);

      return parsedPhoneNumber.length === foundedCountry.length[0];
    }
  }

  return false;
};

interface PhoneInputFieldProps {
  isStatic: boolean;
  placeholder: string;
  number: string;
  language: string;
  country?: string;
  fontSize?: string;
  error?: string;
  borderColor?: string;
  textColor?: string;
  onNumberChange?: (number: string) => void;
  onCountryChange?: (countryCode: string) => void;
}

const PhoneInputField = ({
  isStatic = true,
  language = "en",
  number,
  placeholder = "Phone number",
  country = "+1",
  error,
  borderColor,
  textColor,
  fontSize,
  onNumberChange,
  onCountryChange,
}: PhoneInputFieldProps) => {
  const ref = useRef(null);
  const { isOpen, onToggle, onClose } = useDisclosure();

  const onHandleCountryChange = (item: Country) => {
    if (onCountryChange) {
      onCountryChange(item?.dial_code);
    }
    onClose();
  };
  useOutsideClick({
    ref,
    handler: () => onClose(),
  });

  const inputBorder = {
    borderStartWidth: "1px",
    borderStartColor:
      isStatic && error ? "0 0 0 1px #E53E3E" : borderColor ?? "gray.200",
  };

  return (
    <>
      <Box sx={{ minHeight: isStatic && isOpen && "330px" }}>
        <Box as="section" ref={ref} position="relative" maxW="230px">
          <FormControl>
            <InputGroup
              dir="ltr"
              border="1px solid"
              borderRadius="6px"
              borderColor={
                isStatic && error
                  ? "0 0 0 1px #E53E3E"
                  : borderColor ?? "gray.200"
              }
              boxShadow={isStatic && error ? "0 0 0 1px #E53E3E" : undefined}
            >
              <InputRightAddon
                ps={2}
                pe={1}
                minH="40px"
                height="100%"
                opacity={!isStatic ? 0.4 : 1}
                bgColor="transparent"
                cursor={!isStatic ? "not-allowed" : "pointer"}
                onClick={onToggle}
                border="none"
                borderEndRadius={language === "en" ? 0 : "6px"}
                borderStartRadius={language !== "en" ? 0 : "6px"}
                children={
                  <>
                    <Text
                      as="span"
                      dir="ltr"
                      color={textColor ?? "gray.600"}
                      fontWeight="medium"
                      fontSize={fontSize}
                    >
                      {country}
                    </Text>
                    {isStatic && isOpen ? (
                      <ChevronUpIcon
                        boxSize={6}
                        color={textColor ?? "gray.600"}
                      />
                    ) : (
                      <ChevronDownIcon
                        boxSize={6}
                        color={textColor ?? "gray.600"}
                      />
                    )}
                  </>
                }
              />
              <Input
                ps={2}
                pe={1}
                textAlign="left"
                variant="outline"
                borderWidth={0}
                _hover={{
                  borderColor: "none",
                  ...inputBorder,
                }}
                _focus={{
                  borderColor: "none",
                  ...inputBorder,
                }}
                _active={{
                  boxShadow: "none",
                  ...inputBorder,
                }}
                boxShadow="none"
                placeholder={placeholder}
                color={textColor ?? "gray.600"}
                disabled={!isStatic}
                type="number"
                value={number}
                height="auto"
                minH="40px"
                borderRadius={0}
                fontSize={fontSize}
                {...inputBorder}
                _placeholder={{
                  color: textColor ? `${textColor}9C` : "gray.400",
                }}
                onChange={(e) => {
                  if (onNumberChange) {
                    onNumberChange(e.target.value);
                  }
                }}
              />
            </InputGroup>
            {isStatic && error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
          {isStatic && isOpen ? (
            <SearchOnList data={Countries} onChange={onHandleCountryChange} />
          ) : null}
        </Box>
      </Box>
    </>
  );
};

export default PhoneInputField;
