import { Flex, SlideFade, useMediaQuery } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FieldProps } from "types/Field";
import { OpinionScaleButtons } from "components/field/OpinionScaleButtons";
import { Inputs } from "components/field/Inputs";
import { Heading } from "components/field/FieldHeading";
import { MultipleChoiceButtons } from "components/field/MultipleChoiceButtons";
import { FormFooter } from "components/field/FieldFooter";
import { FieldType } from "types/Form";
import { RecordView } from "components/buttons/RecordButton/RecordButtonContainer";
import ShortAnswerWrapper from "components/field/ShortAnswerWrapper";
import EmailWrapper from "components/field/EmailWrapper";
import PhoneInputWrapper from "components/field/PhoneInputWrapper";
import DateWrapper from "components/field/DateWrapper";

const Field = ({
  actualPageNumber,
  visiblePageNumber,
  setVisiblePageNumber,
  isStatic,
  width,
  field,
  language,
  onSaveResponse,
  changeForm,
  minWidth,
  isExpanded,
  onExpand,
}: FieldProps) => {
  const [isDesktop] = useMediaQuery("(min-width: 992px)");
  const { i18n } = useTranslation();
  const arabicT = i18n.getFixedT("ar");
  const {
    field_id: fieldId,
    type: fieldType,
    properties: { choices },
  } = field;
  return (
    <SlideFade
      delay={0.5}
      in
      style={{ width: "100%", height: "100%" }}
      offsetY="100%"
    >
      <Flex direction="column" w="100%">
        <Flex w="100%" h="100%" position="relative">
          <Heading
            isStatic={isStatic}
            width={width}
            minWidth={minWidth}
            language={language}
            visiblePageNumber={actualPageNumber}
          />

          <Flex direction="column" w="100%">
            <Inputs
              minWidth={minWidth}
              isStatic={isStatic}
              width={width}
              changeForm={changeForm}
              field={field}
              isExpanded={isExpanded}
              onExpand={onExpand}
            />
            {fieldType === FieldType.VOICE && (
              <RecordView field={field} isStatic={isStatic} />
            )}
            {fieldType === FieldType.OPINION_SCALE && (
              <OpinionScaleButtons field={field} />
            )}
            {fieldType === FieldType.MULTIPLE_CHOICE && (
              <MultipleChoiceButtons
                visiblePageNumber={visiblePageNumber}
                language={language ?? "en"} // bad idea?
                choices={choices}
                changeForm={changeForm}
                field={field}
                isStatic={isStatic}
              />
            )}
            {fieldType === FieldType.SHORT_ANSWER && (
              <ShortAnswerWrapper
                language={language ?? "en"} // bad idea?
                isStatic={isStatic}
                field={field}
              />
            )}
            {fieldType === FieldType.DATE && (
              <DateWrapper isStatic={isStatic} field={field} />
            )}
            {fieldType === FieldType.EMAIL && (
              <EmailWrapper
                language={language ?? "en"}
                isStatic={isStatic}
                field={field}
              />
            )}
            {fieldType === FieldType.PHONE && (
              <PhoneInputWrapper
                language={language ?? "en"}
                isStatic={isStatic}
                field={field}
              />
            )}
            {(isDesktop || isStatic) && <Flex flex="1 1 66px" maxH="84px" />}
          </Flex>
        </Flex>
        <FormFooter
          width={width}
          isStatic={isStatic}
          visiblePageNumber={visiblePageNumber}
          minWidth={minWidth}
          setVisiblePageNumber={setVisiblePageNumber}
          onSaveResponse={onSaveResponse}
          language={language}
          arabicT={arabicT}
          fieldId={fieldId}
        />
      </Flex>
    </SlideFade>
  );
};

export default Field;
