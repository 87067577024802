import { Box, BoxProps } from "@chakra-ui/react";

interface Props extends BoxProps {
  value: string;
  fontSize?: string;
  innerRef: any;
  disabled: boolean;
}

export function InlineEdit({
  innerRef,
  value,
  disabled,
  fontSize,
  ...props
}: Props) {
  return (
    <Box
      contentEditable={!disabled}
      textAlign="start"
      _focus={{ outline: "none", border: 0 }}
      _focusVisible={{ outline: "none" }}
      aria-label="Field name"
      border="none"
      suppressContentEditableWarning
      pe={2}
      py={2}
      overflowWrap="anywhere"
      whiteSpace="pre-wrap"
      ref={innerRef}
      w="100%"
      maxW="100%"
      userSelect="text"
      fontSize={fontSize}
      style={{
        WebkitUserSelect: "text",
      }}
      {...props}
    >
      {value}
    </Box>
  );
}
