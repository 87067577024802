import { UserRole } from "types/User";
import { useAuthState } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { subscribe } from "stripe/stripeExtension";
import { useState } from "react";
import { logAnalyticsEvent } from "../firebase/firebase-analytics";

export const useCheckout = () => {
  const [loadingStripe, setLoadingStripe] = useState<boolean>(false);
  const { user, userRole, openLoginModal } = useAuthState();
  const navigate = useNavigate();

  const handleCheckout = () => {
    logAnalyticsEvent("start_checkout", { user: user?.uid });
    if (userRole === UserRole.PREMIUM) return navigate("/account/create");
    if (user)
      return subscribe({ currentUser: user.uid, setLoading: setLoadingStripe });
    openLoginModal("/pricing");
  };

  return { handleCheckout, loadingStripe };
};
