import "./App.css";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import { lazy, Suspense, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import Loading from "components/general/Loading";
import WhatsappLink from "components/buttons/whatsappLink";
import Header from "components/navigation/Header";
import { AuthContextProvider, useAuthState } from "context/AuthContext";
import ChakraRTLProvider from "context/ChakraRTLProvider";
import { SiteContextProvider } from "context/SiteContext";
import PricingPage from "pages/PricingPage/PricingPage";
import * as Sentry from "@sentry/react";

const LoginModalWrapper = lazy(
  () => import("../components/modals/LoginModal/LoginModalWrapper")
);

const CreateWrapper = lazy(() => import("../components/create/CreateWrapper"));

const SummaryTabContainer = lazy(
  () => import("../components/results/SummaryTab/SummaryTabContainer")
);

const ResponsesTabContainer = lazy(
  () => import("../components/results/ResponsesTab/ResponsesTabContainer")
);

const SettingsTabContainer = lazy(
  () => import("../components/settings/SettingsTabContainer")
);

const ShowForm = lazy(() => import("../pages/ShowForm/ShowForm"));

const Account = lazy(() => import("../pages/AccountPage/AccountPage"));
const Chat = lazy(() => import("../pages/Chat/ChatWrapper"));
const ChatTabContainer = lazy(
  () => import("../components/chat/ChatTabContainer")
);

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Container = () => {
  const { isAuthenticated, openLoginModal } = useAuthState();

  useEffect(() => {
    if (!isAuthenticated) {
      openLoginModal("/account/create-chat");
    }
  }, [isAuthenticated, openLoginModal]);

  return (
    <Flex h="100%" flexDir="column" zIndex="2">
      <Header />
      <Outlet />
      <LoginModalWrapper />
      <WhatsappLink />
    </Flex>
  );
};

function App() {
  return (
    <HelmetProvider>
      <SiteContextProvider>
        <ChakraRTLProvider>
          <AuthContextProvider>
            <Suspense fallback={<Loading />}>
              <SentryRoutes>
                <Route element={<Container />}>
                  <Route
                    path=""
                    element={<Navigate to="account/create-chat" replace />}
                  />
                  <Route path="pricing" element={<PricingPage />} />
                  <Route path="account" element={<Account />}>
                    <Route path="create" element={<CreateWrapper />} />
                    <Route path="summary" element={<SummaryTabContainer />} />
                    <Route
                      path="responses"
                      element={<ResponsesTabContainer />}
                    />
                    <Route path="settings" element={<SettingsTabContainer />} />
                    <Route path="create-chat" element={<ChatTabContainer />} />
                    <Route path="" element={<Navigate to="create" replace />} />
                  </Route>
                </Route>
                <Route path="/form/:formId" element={<ShowForm />} />
                <Route path="/chat/:formId" element={<Chat />} />
                <Route
                  path="/chat"
                  element={<Navigate to="/account/create" replace />}
                />
              </SentryRoutes>
            </Suspense>
          </AuthContextProvider>
        </ChakraRTLProvider>
      </SiteContextProvider>
    </HelmetProvider>
  );
}

export default App;
