export enum AuthMode {
  LOGIN = "login",
  SIGN_UP = "signUp",
}

export enum SignUpState {
  AI = "ai",
  USER_TYPE = "userType",
  DONE = "done",
}
