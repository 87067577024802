import { IconButton } from "@chakra-ui/react";
import { useSiteContext } from "context/SiteContext";
import React from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";

const EditModeBackIconButton: React.FC<{
  setIsOpen: (isOpen: boolean) => void;
}> = ({ setIsOpen }) => {
  const { isDesktop } = useSiteContext();
  return !isDesktop ? (
    <IconButton
      icon={<ChevronRightIcon width={5} height={5} color="#6C6A6A" />}
      onClick={() => {
        setIsOpen(false);
      }}
      aria-label="Back"
      bg="white"
      border="1px"
      borderColor="#D9D5D5"
    />
  ) : null;
};

export default EditModeBackIconButton;
