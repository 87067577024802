import axios from "axios";
import { FormLink, Channel } from "types/Form";
import { logAnalyticsEvent } from "../../firebase/firebase-analytics";

export const getAllLinks = async (
  title: string,
  description: string,
  formId: string,
  isAiForm: boolean
) => {
  const channels = [
    Channel.WHATSAPP,
    Channel.FACEBOOK,
    Channel.LINKEDIN,
    Channel.TWITTER,
    Channel.QRCODE,
  ];

  const calls = channels.map((ch) =>
    getDynamicLink(title, description, formId, ch, isAiForm)
  );

  return Promise.all(calls)
    .then((data) => data)
    .catch((err) => {
      logAnalyticsEvent("getDynamicLinks_fail", { value: err });
      console.log({ err });
    });
};

export const getDynamicLink = async (
  title: string,
  description: string,
  formId: string,
  channel: Channel,
  isAiForm: boolean
): Promise<FormLink> => {
  const baseURL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : "https://seenjeem.io";

  const formType = isAiForm ? "chat" : "form";

  return axios
    .post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCpRotSbkhU1jdjDQaVjyMWVi9SLAzascw`,
      {
        dynamicLinkInfo: {
          domainUriPrefix: "https://seenjeem.page.link",
          link: `${baseURL}/${formType}/${formId}?utm_source=${channel}&utm_medium=share`,
          // todo add meta tags
          socialMetaTagInfo: {
            socialTitle: title ?? "SeenJeem",
            socialDescription:
              description ??
              "أول أداة إلكترونية عربية لتصميم الاستبيانات بسرعة وسهولة",
            socialImageLink: "https://seenjeem.io/alef1.svg",
          },
        },
        suffix: {
          option: "SHORT",
        },
      }
    )
    .then((data) => {
      let link;
      link = data?.data?.shortLink;
      if (!link) {
        link = null;
      }
      return { channel, link };
    })

    .catch((err) => {
      logAnalyticsEvent("getDynamicLink_fail", { value: err });
      return { channel, link: "" };
    });
};
