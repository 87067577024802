import { Box } from "@chakra-ui/react";
import { useFormContext } from "context/FormContext";
import { useMemo } from "react";
import { getFontSize } from "utils/form/fontSize";

export const Heading = ({
  isStatic,
  width,
  minWidth,
  language,
  visiblePageNumber,
}) => {
  const { form } = useFormContext();
  const fontColor = form?.theme?.colors?.question;
  const fontSize = useMemo(
    () => getFontSize(form?.theme?.fields?.font_size, width > minWidth),
    [form?.theme?.fields?.font_size, width, minWidth]
  );

  return (
    <Box
      position="absolute"
      fontWeight="400"
      color={fontColor ?? "inherit"}
      left={language === "en" ? "-16px" : undefined}
      right={language === "ar" ? "-16px" : undefined}
      pt={0}
      fontSize={fontSize}
    >
      {language === "ar"
        ? (visiblePageNumber + 1).toLocaleString("ar-EG")
        : visiblePageNumber + 1}
      .
    </Box>
  );
};
