const arabicAlpha = [
  "ا",
  "ب",
  "ج",
  "د",
  "ه",
  "و",
  "ز",
  "ح",
  "ط",
  "ي",
  "ك",
  "ل",
  "م",
  "ن",
  "س",
  "ع",
  "ف",
  "ص",
  "ق",
  "ر",
  "ش",
  "ت",
  "ث",
  "خ",
  "ذ",
  "ض",
  "ظ",
  "غ",
];

export const getAlphaLetter = (index, lang) => {
  if (lang === "en") {
    return String.fromCharCode(index + 65);
  }
  return arabicAlpha[index];
};

export const isChoiceSelected = ({ id, answers, field }) => {
  const fieldResponse = answers?.find(
    (response) => response.field_id === field.field_id
  );
  let isSelected = false;
  if (fieldResponse?.type === "choices") {
    isSelected = fieldResponse?.value?.choices.some(
      (choice) => choice.id === id
    );

    // id === fieldResponse?.value?.choices[0].id;
  }
  return isSelected;
};
