import { Text, Flex } from "@chakra-ui/react";
import { t } from "i18next";
import { UserRole } from "types/User";
import usePricing, { Feature, Plan } from "../usePricing";

export const FeaturesMobile = ({ plan }: { plan: Plan }) => {
  const { features } = usePricing();
  return (
    <>
      <Flex alignItems="center" justify="space-between" px={4} py={2}>
        <Flex bg="white !important">
          <Text fontSize="18px" color="teal.500">
            {t("Features")}
          </Text>
        </Flex>
        <Flex bg="white !important">
          <Flex flexDir="column" gap="10px">
            <Flex alignItems="baseline">
              <Text fontSize="24px" fontWeight={500}>
                ${plan.price}
              </Text>
              <Text>/{t("month")}</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {features.map((feature) => {
        return (
          <FeatureColumn
            key={feature.label}
            feature={feature}
            plan={plan.name}
          />
        );
      })}
    </>
  );
};

const FeatureColumn = ({
  feature,
  plan,
}: {
  feature: Feature;
  plan: UserRole;
}) => {
  return (
    <Flex py={4} px={2} w="100%" justify="space-between" alignItems="center">
      <Flex flexDir="row" gap="10px" justify="space-between">
        <Flex alignItems="baseline">
          <Text>{feature.label}</Text>
        </Flex>
      </Flex>
      <Flex flexDir="row" minW="15%">
        <Flex w="100%">
          <Text mx="auto">{feature.value[plan]}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
