import { extendTheme, ChakraProvider, theme as base } from "@chakra-ui/react";
import { useSiteContext } from "./SiteContext";

export const theme = extendTheme({
  fonts: {
    // body: direction === "rtl" ? `Estedad, sans-serif` : base?.fonts.body,
    body: "'Almarai', sans-serif",
  },
  colors: {
    teal: {
      100: "#dcd5f6",
      200: "#baacec",
      300: "#9782e3",
      400: "#7559d9",
      500: "#522fd0",
      600: "#3D2399",
    },
    gray: {
      50: "#A8C6F717",
      100: "#11807F19",
      200: "#F6F6FA",
      400: "#707070",
      500: "#8598AD",
      700: "#2D3748",
    },
  },
  components: {
    Button: {
      variants: {
        outline: {
          borderColor: "teal.600",
          color: "teal.600",
          borderRadius: 3,
        },
      },
      // todo: figure out
      baseStyle: {
        _focus: {
          outline: "none",
        },
      },
    },
  },
  zIndices: {
    modal: 11000,
  },
});

// eslint-disable-next-line import/prefer-default-export
const ChakraRTLProvider = ({ children }) => {
  const { direction } = useSiteContext();

  return (
    <ChakraProvider theme={{ ...theme, direction }}>{children}</ChakraProvider>
  );
};

export default ChakraRTLProvider;
