import { Box } from "@chakra-ui/react";

const EditModeTopBoxWrapper = ({ children }) => (
  <Box
    position="absolute"
    top={{ base: 5, lg: 2 }}
    right={{ base: 5, lg: 2 }}
    display="flex"
    gap="5px"
  >
    {children}
  </Box>
);

export default EditModeTopBoxWrapper;
