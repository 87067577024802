import { Flex } from "@chakra-ui/react";
import PriceTableDesktop from "./PriceTableDesktop";
import PriceTableMobile from "./Mobile.tsx/PriceTableMobile";
import PricingHeader from "./PricingHeader";
import PricingQuestions from "./PricingQuestions";
import PricingTestimonial from "./PricingTestimonial";

export default () => {
  return (
    <Flex flexDir="column">
      <PricingHeader />
      <PriceTableMobile />
      {/* <PricingTestimonial /> */}
      <PricingQuestions />
    </Flex>
  );
};
