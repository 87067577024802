import { Image, Flex, Text } from "@chakra-ui/react";
import { t } from "i18next";
import inline from "icons/whatsappInline.svg";
import icon from "icons/whatsappIcon.svg";
import { useSiteContext } from "context/SiteContext";
import { useMemo } from "react";
// import { logAnalyticsEvent } from "../../firebase/firebase-analytics";

export default () => {
  const { direction } = useSiteContext();

  const horizontalSpacing = useMemo(() => {
    const spacing = {
      base: "10px",
      md: "20px",
    };
    return direction === "rtl"
      ? { left: { ...spacing } }
      : { right: { ...spacing } };
  }, [direction]);

  return (
    <a
      aria-label="Chat on WhatsApp"
      href="https://wa.me/message/VI24BCEWITYJL1"
      target="_blank"
      rel="noreferrer"
    >
      <Flex
        bg="white"
        minH="auto"
        position="fixed"
        bottom={{ base: "10px", md: "20px" }}
        {...horizontalSpacing}
        zIndex={2}
        borderRadius={{ base: "50%", md: "10px" }}
        p={{ base: 3, md: 4 }}
        flexDir="column"
        maxW={{ base: "150px", md: "160px" }}
        textAlign="center"
        boxShadow="2xl"
        border="1px"
        borderColor="gray.100"
        // onClick={() => {
        //   logAnalyticsEvent("whatsapp_chat");
        // }}
      >
        <Flex mb={2} display={{ base: "none", md: "block" }}>
          <Text fontSize="16px">{t("Whatsapp.CTA")}</Text>
        </Flex>
        <Flex mx="auto" display={{ md: "none" }}>
          <Image alt="Chat on WhatsApp" src={icon} maxW="50px" />
        </Flex>
        <Flex display={{ base: "none", md: "block" }}>
          <Image
            alt="Chat on WhatsApp"
            src={inline}
            w="100%"
            style={{ minWidth: "120px" }}
          />
        </Flex>
      </Flex>
    </a>
  );
};
