import { IconButton } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

export default ({ backgroundColor, iconColor }) => (
  <IconButton
    borderRadius="50%"
    bg={backgroundColor ?? "teal.600"}
    color={iconColor ?? "white"}
    h="36px"
    w="36px"
    minW="40px"
    minH="40px"
    mr={3}
    icon={<CheckIcon style={{ margin: "9px auto" }} />}
    aria-label="Preview"
    _active={{
      backgroundColor: backgroundColor ?? "teal.600",
    }}
    _hover={{ backgroundColor: backgroundColor ?? "teal.600" }}
    _focus={{
      outline: "none",
    }}
    cursor="default"
    mx="auto"
  />
);
