import { Flex, Text, Icon as ChakraIcon, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default ({ isActive, Icon, text }) => {
  return (
    <Flex
      _hover={{ borderBottom: "1px" }}
      borderBottom={isActive ? "1px" : "none"}
      borderColor="teal.600"
    >
      <Text
        fontSize="16px"
        color={isActive ? "teal.600" : "gray.600"}
        _hover={{
          color: "teal.600",
        }}
        textAlign="end"
      >
        {text}
      </Text>
    </Flex>
  );
};

export const SignOutButton = ({ signOut }) => {
  const { t } = useTranslation();
  return (
    <Button
      h="auto"
      alignItems="center"
      fontSize={["14px", null, "16px"]}
      onClick={signOut}
      variant="outline"
      fontWeight={500}
      p={2}
    >
      {t("Sign Out")}
    </Button>
  );
};

export const SignInButton = ({ handler }) => {
  const { t } = useTranslation();
  return (
    <Button
      h={[27, null, "auto"]}
      fontSize={["14px", null, "16px"]}
      onClick={handler}
      variant="link"
      fontWeight={500}
      p={2}
      maxW={["90px", "100%"]}
      whiteSpace={["normal", "nowrap"]}
    >
      {t("Sign In")}
    </Button>
  );
};

export const SignUpButton = ({ handler }) => {
  const { t } = useTranslation();
  return (
    <Button
      display={{ base: "none", lg: "inline-flex" }}
      fontSize="sm"
      onClick={() => {
        handler();
      }}
      bg="teal.600"
      color="white"
      fontWeight={600}
      _hover={{
        bg: "teal.600",
      }}
    >
      {t("Sign Up")}
    </Button>
  );
};
