import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Link,
  useColorModeValue,
  useDisclosure,
  useDimensions,
  useMediaQuery,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { useAuthState } from "context/AuthContext";
import { ReactComponent as AccountIcon } from "icons/Account.svg";
import { ReactComponent as HomeIcon } from "icons/Home.svg";
import { useSiteContext } from "context/SiteContext";
import Logo from "components/general/Logo";
import { t } from "i18next";
import { billingPortal } from "stripe/stripeExtension";
import { UserRole } from "types/User";
import { useRef } from "react";
import { MIN_WIDTH } from "components/form/Form";
import { useNavigate } from "react-router-dom";
import NavbarItem, {
  SignInButton,
  SignUpButton,
  SignOutButton,
} from "./NavbarItem";
import MenuItem from "./MenuItem";
import LanguageMenu from "./LanguageMenu";

const Links = ({ isAuthenticated }) => {
  const { userRole } = useAuthState();
  return (
    <>
      <Flex>
        <MenuItem
          to="/"
          render={({ isActive }) => (
            <NavbarItem isActive={isActive} Icon={HomeIcon} text={t("Home")} />
          )}
        />
      </Flex>

      {isAuthenticated && (
        <Flex>
          <MenuItem
            to="account"
            render={({ isActive }) => (
              <NavbarItem
                isActive={isActive}
                Icon={AccountIcon}
                text={t("Account")}
              />
            )}
          />
        </Flex>
      )}

      <Flex>
        {userRole === UserRole.PREMIUM ? (
          <Text
            fontSize="16px"
            color="gray.600"
            onClick={billingPortal}
            cursor="pointer"
            _hover={{
              color: "teal.600",
              borderBottom: "1px",
            }}
          >
            {t("AccountSettings")}
          </Text>
        ) : (
          <MenuItem
            to="pricing"
            render={({ isActive }) => (
              <NavbarItem
                isActive={isActive}
                Icon={AccountIcon}
                text={t("Pricing")}
              />
            )}
          />
        )}
      </Flex>

      <LanguageMenu />
    </>
  );
};

const DesktopNav = ({ isAuthenticated }) => {
  return (
    <Stack direction="row" spacing={8}>
      <Links isAuthenticated={isAuthenticated} />
    </Stack>
  );
};

const MobileNav = ({ isAuthenticated, dir }) => {
  const { signOut } = useAuthState();
  const navigate = useNavigate();
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ lg: "none" }}
      spacing={2}
      dir={dir}
    >
      <Links isAuthenticated={isAuthenticated} />
      {isAuthenticated && (
        <SignOutButton
          signOut={() => {
            signOut();
            navigate("/account/create-chat");
          }}
        />
      )}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify="space-between"
        align="center"
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
      </Flex>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

export default function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();
  const [isDesktop] = useMediaQuery("(min-width: 992px)");
  const { isAuthenticated, openLoginModal, signOut } = useAuthState();
  const { direction, openPreview, onShareModalOpen, onDesignOverlayOpen } =
    useSiteContext();
  const elementRef = useRef<HTMLDivElement>(null);
  const dimensions = useDimensions(elementRef, true);
  const navigate = useNavigate();

  const logoMaxWidth = dimensions
    ? dimensions.contentBox.width < MIN_WIDTH
      ? "100px"
      : "125px"
    : "125px";

  return (
    <Box zIndex="2" ref={elementRef}>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH="60px"
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle="solid"
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align="center"
        dir={direction}
        h="auto"
      >
        <Flex
          flex={{ lg: "auto" }}
          ms={{ base: -2 }}
          display={{ base: "flex", lg: "none" }}
          mb={2}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant="ghost"
            aria-label="Toggle Navigation"
          />
        </Flex>
        <Flex
          flex={{ base: 1.5 }}
          justify={{ lg: "start", base: "end" }}
          h="100%"
        >
          <Flex maxW={logoMaxWidth} flex="1" flexGrow={0} w="auto" h="auto">
            <Logo />
          </Flex>

          <Flex
            display={{ base: "none", lg: "flex" }}
            ms={10}
            alignItems="center"
          >
            <DesktopNav isAuthenticated={isAuthenticated} />
          </Flex>
        </Flex>

        {isDesktop && (
          <Stack
            flex={{ base: 1, lg: 0 }}
            justify="flex-end"
            direction="row"
            spacing={3}
          >
            {isAuthenticated && (
              <SignOutButton
                signOut={() => {
                  signOut();
                  navigate("/account/create-chat");
                }}
              />
            )}
            {!isAuthenticated && (
              <SignInButton
                handler={() => openLoginModal("/account/create-chat")}
              />
            )}
            {!isAuthenticated && (
              <SignUpButton
                handler={() => openLoginModal("/account/create-chat")}
              />
            )}
          </Stack>
        )}
      </Flex>

      <Collapse
        in={isOpen}
        animateOpacity
        // fix navbar pushing everything out
        // style={{ position: "absolute", width: "100%" }}
      >
        <MobileNav isAuthenticated={isAuthenticated} dir={direction} />
      </Collapse>
    </Box>
  );
}
