/* eslint-disable react/jsx-no-constructed-context-values */
import {
  createContext,
  useState,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { useTranslation } from "react-i18next";
import { useDisclosure, useMediaQuery } from "@chakra-ui/react";

export const SiteContext = createContext<{
  direction: string;
  isDesktop: boolean;
  setDirection: (dir: string) => void;
  language: string;
  changeLanguage: (lang: string) => void;
  toggleLanguage: () => void;
  isPreviewOpen: boolean;
  closePreview: () => void;
  openPreview: () => void;
  isShareModalOpen: boolean;
  onShareModalOpen: () => void;
  onShareModalClose: () => void;
  isThemeModalOpen: boolean;
  onThemeModalOpen: () => void;
  onThemeModalClose: () => void;
  isDesignOverlayOpen: boolean;
  onDesignOverlayClose: () => void;
  onDesignOverlayOpen: () => void;
  isArrangementOverlayOpen: boolean;
  onArrangementOverlayClose: () => void;
  onArrangementOverlayOpen: () => void;
  isAddQuestionModalOpen: boolean;
  onAddQuestionModalOpen: () => void;
  onAddQuestionModalClose: () => void;
  isNewFormModalOpen: boolean;
  onNewFormModalOpen: () => void;
  onNewFormModalClose: () => void;
  addPageType: "welcome" | "question" | "ending";
  setAddPageType: Dispatch<SetStateAction<"welcome" | "question" | "ending">>;
  isNewAIFormModalOpen: boolean;
  onNewAIFormModalOpen: () => void;
  onNewAIFormModalClose: () => void;
}>({
  direction: "rtl",
  isDesktop: true,
  setDirection: () => {},
  language: "ar",
  changeLanguage: () => {},
  toggleLanguage: () => {},
  isPreviewOpen: false,
  closePreview: () => {},
  openPreview: () => {},
  isShareModalOpen: false,
  onShareModalOpen: () => {},
  onShareModalClose: () => {},
  isThemeModalOpen: false,
  onThemeModalOpen: () => {},
  onThemeModalClose: () => {},
  isDesignOverlayOpen: false,
  onDesignOverlayClose: () => {},
  onDesignOverlayOpen: () => {},
  isArrangementOverlayOpen: false,
  onArrangementOverlayClose: () => {},
  onArrangementOverlayOpen: () => {},
  isAddQuestionModalOpen: false,
  onAddQuestionModalOpen: () => {},
  onAddQuestionModalClose: () => {},
  isNewFormModalOpen: false,
  onNewFormModalOpen: () => {},
  onNewFormModalClose: () => {},
  addPageType: "welcome",
  setAddPageType: () => {},
  isNewAIFormModalOpen: false,
  onNewAIFormModalOpen: () => {},
  onNewAIFormModalClose: () => {},
});

export const SiteContextProvider = (props) => {
  const [isDesktop] = useMediaQuery("(min-width: 992px)");
  const { i18n } = useTranslation();

  const [direction, setDirection] = useState<string>(i18n.dir());
  const [language, setLanguage] = useState<string>(i18n.language);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [addPageType, setAddPageType] = useState<
    "welcome" | "question" | "ending"
  >();

  const {
    isOpen: isNewAIFormModalOpen,
    onOpen: onNewAIFormModalOpen,
    onClose: onNewAIFormModalClose,
  } = useDisclosure();

  const {
    isOpen: isShareModalOpen,
    onOpen: onShareModalOpen,
    onClose: onShareModalClose,
  } = useDisclosure();

  const {
    isOpen: isDesignOverlayOpen,
    onOpen: onDesignOverlayOpen,
    onClose: onDesignOverlayClose,
  } = useDisclosure();

  const {
    isOpen: isThemeModalOpen,
    onOpen: onThemeModalOpen,
    onClose: onThemeModalClose,
  } = useDisclosure();

  const {
    isOpen: isAddQuestionModalOpen,
    onOpen: onAddQuestionModalOpen,
    onClose: onAddQuestionModalClose,
  } = useDisclosure();

  const {
    isOpen: isNewFormModalOpen,
    onOpen: onNewFormModalOpen,
    onClose: onNewFormModalClose,
  } = useDisclosure();

  const {
    isOpen: isArrangementOverlayOpen,
    onClose: onArrangementOverlayClose,
    onOpen: onArrangementOverlayOpen,
  } = useDisclosure();

  const changeLanguage = (lang) => {
    if (lang === "ar") {
      setDirection("rtl");
      setLanguage("ar");
      i18n.changeLanguage("ar");
    } else {
      setDirection("ltr");
      setLanguage("en");
      i18n.changeLanguage("en");
    }
  };

  const toggleLanguage = () => {
    if (language === "en") {
      setDirection("rtl");
      setLanguage("ar");
      i18n.changeLanguage("ar");
    } else {
      setDirection("ltr");
      setLanguage("en");
      i18n.changeLanguage("en");
    }
  };

  return (
    <SiteContext.Provider
      value={{
        direction,
        setDirection,
        language,
        isDesktop,
        changeLanguage,
        toggleLanguage,
        isPreviewOpen: isOpen,
        closePreview: onClose,
        openPreview: onOpen,
        isShareModalOpen,
        onShareModalOpen,
        onShareModalClose,
        isThemeModalOpen,
        onThemeModalOpen,
        onThemeModalClose,
        isDesignOverlayOpen,
        onDesignOverlayClose,
        onDesignOverlayOpen,
        isArrangementOverlayOpen,
        onArrangementOverlayClose,
        onArrangementOverlayOpen,
        isAddQuestionModalOpen,
        onAddQuestionModalOpen,
        onAddQuestionModalClose,
        isNewFormModalOpen,
        onNewFormModalOpen,
        onNewFormModalClose,
        addPageType,
        setAddPageType,
        isNewAIFormModalOpen,
        onNewAIFormModalOpen,
        onNewAIFormModalClose,
      }}
      {...props}
    />
  );
};

export const useSiteContext = () => {
  const siteContextData = useContext(SiteContext);

  return {
    ...siteContextData,
  };
};
