import { FontSize } from "types/Theme";

export const getFontSize = (
  fontSize?: FontSize,
  isLargScreen?: boolean
): string => {
  let size = 16;

  if (fontSize === FontSize.LARGE) {
    size = 24;
  }

  if (fontSize === FontSize.MEDIUM) {
    size = 20;
  }

  if (isLargScreen) {
    size += 2;
  }

  return `${size}px`;
};
