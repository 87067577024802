import { useMemo } from "react";
import { Flex, Textarea, SlideFade, Alert, AlertIcon } from "@chakra-ui/react";
import Logo from "components/general/Logo";
import { EndingType, ThankYouScreen } from "types/Form";
import ShareIcons from "components/general/ShareIcons/ShareIcons";
import Score from "components/field/Score";
import { t } from "i18next";
import { useFormContext } from "context/FormContext";
import { getFontSize } from "utils/form/fontSize";
import { logAnalyticsEvent } from "../../../firebase/firebase-analytics";

const ScorePage = ({
  isStatic,
  field,
  changeForm,

  width,
  minWidth,
  disableCTA,
  uid,
}: {
  isStatic: boolean;
  field: ThankYouScreen;
  changeForm?: any;

  width: any;
  minWidth: number;
  disableCTA: boolean;
  uid?: string;
}) => {
  const { form } = useFormContext();

  const textAlignment = form?.theme?.screens?.alignment;
  const fontSize = useMemo(
    () => getFontSize(form?.theme?.fields?.font_size, width > minWidth),
    [form?.theme?.fields?.font_size, width, minWidth]
  );
  const fontFamily = form?.theme?.font_family;

  return (
    <SlideFade
      delay={0.2}
      in
      style={{ width: "100%", height: "100%" }}
      offsetY={isStatic ? "100%" : "0%"}
    >
      <Flex direction="column" w="100%" fontWeight="400" gap="5px">
        <Flex mx="auto" w="100%" h="100%" flexDir="column">
          {!isStatic && form?.type !== "score" && (
            <Alert status="warning" borderRadius="9px" fontWeight={300}>
              <AlertIcon />
              {t("DisabledScoreScreen.Tooltip")}
            </Alert>
          )}
          <Textarea
            mt={2}
            disabled={isStatic}
            fontWeight="400"
            fontSize={fontSize}
            color="gray.600"
            variant="unstyled"
            onChange={(e) =>
              changeForm({
                fieldAttribute: "title",
                value: e.target.value,
                id: field.id,
                type: EndingType.THANK_YOU,
              })
            }
            fontFamily={fontFamily ?? "inherit"}
            textAlign={textAlignment ?? "center"}
            value={field?.title}
            resize="none"
            px={1}
            border={isStatic ? "none" : "1px dashed"}
            borderColor="gray.300"
            overflow="hidden"
            h="auto"
            minH="0"
            ref={(node) => {
              if (!node) return;
              node.style.height = "auto";
              node.style.height = `${node?.scrollHeight}px`;
            }}
            style={{
              resize: "none",
              WebkitTextFillColor: "gray.800",
              opacity: 1,
            }}
          />
        </Flex>
        <Score isStatic={isStatic} />
        <Flex w="100%">
          {form && field.properties.share_icons && <ShareIcons form={form} />}
        </Flex>
        <Flex
          mx="auto"
          maxW="200px"
          onClick={() => {
            logAnalyticsEvent("thankyou_page_logo");
          }}
        >
          <Logo disable={disableCTA} />
        </Flex>
      </Flex>
    </SlideFade>
  );
};

export default ScorePage;
