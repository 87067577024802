import { Text, Flex } from "@chakra-ui/react";
import { useFormContext } from "context/FormContext";
import { useStaticFormContext } from "context/StaticFormContext";
import { scoreCalculator, totalScoreCalculator } from "utils/scoreCalculator";
import { useEffect, useState } from "react";

export default ({ isStatic }: { isStatic: boolean }) => {
  const { answers } = useStaticFormContext();
  const { form } = useFormContext();
  const [score, setScore] = useState<number>(0);

  const textAlignment = form?.theme?.screens?.alignment;
  const fontSize = form?.theme?.screens?.font_size;
  const fontFamily = form?.theme?.font_family;

  useEffect(() => {
    if (!answers || !form) return;
    const { score: calculatedScore } = scoreCalculator(answers, form);
    setScore(calculatedScore);
  }, [answers]);

  if (!form) return <></>;

  const formatScore = (val) => {
    if (form.language === "ar") {
      return val?.toLocaleString("ar-EG");
    }
    return val;
  };

  if (form.type !== "score") {
    return <Flex py={2} />;
  }

  return (
    <Flex textAlign="center" flexDir="column" fontWeight={300} py={2}>
      <Flex mx="auto">
        <Flex mx="auto" fontSize="24px" fontFamily={fontFamily ?? "inherit"}>
          {isStatic ? (
            formatScore(score)
          ) : (
            <Text me={2} color="gray.300">
              x
            </Text>
          )}{" "}
          /{" "}
          <Text ms={2} textAlign={textAlignment}>
            {formatScore(totalScoreCalculator(form))}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
