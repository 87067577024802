import { Input, Box, FormHelperText, FormControl } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useStaticFormContext } from "context/StaticFormContext";
import { FormField, Answer } from "types/Form";
import { useFormContext } from "context/FormContext";
import EmailField from "./EmailField";

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (value: string) => {
  return value.trim() !== "" && EMAIL_REGEX.test(value);
};

const EmailWrapper = ({
  isStatic,
  field,
  language,
}: {
  isStatic: boolean;
  field: FormField;
  language: string;
}) => {
  const { form } = useFormContext();
  const { answers, setAnswers } = useStaticFormContext();
  const { i18n } = useTranslation();
  const arabicT = i18n.getFixedT("ar");

  const save = (e) => {
    if (answers && setAnswers) {
      const objIndex = answers.findIndex(
        (obj) => obj.field_id === field.field_id
      );

      const newAnswer: Answer = {
        field_id: field.field_id,
        type: "email",
        value: {
          text: e,
        },
      };
      if (objIndex < 0) {
        setAnswers([...answers, newAnswer]);
      } else {
        answers[objIndex] = newAnswer;
        setAnswers([...answers]);
      }
    }
  };

  const answer = () => {
    const fieldResponse = answers?.find(
      (response) => response.field_id === field.field_id
    );

    if (fieldResponse?.type === "email") {
      return fieldResponse.value.text;
    }
    return "";
  };

  const validEmail =
    field.properties.required && answer().length > 0
      ? isValidEmail(answer())
      : true;

  const error =
    isStatic && !validEmail
      ? form?.language === "ar"
        ? arabicT("Email.Validation.Error")
        : i18n.t("Email.Validation.Error")
      : undefined;

  return (
    <EmailField
      error={error}
      value={answer()}
      onChange={(e) => save(e.target.value)}
      {...{ isStatic, language }}
    />
  );
};

export default EmailWrapper;
