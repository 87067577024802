import { Flex, Button } from "@chakra-ui/react";
import { useFormContext } from "context/FormContext";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { EndingType } from "types/Form";
import {
  DEFULT_BUTTON_COLOR,
  DEFULT_BUTTON_CONTENT_COLOR,
} from "utils/addForm/formConstants";
import { useIsLastQuestion } from "utils/useIsLastQuestion";

export const QuoteScreenFooter = ({
  field,
  isStatic,
  visiblePageNumber,
  setVisiblePageNumber,
}) => {
  const { form } = useFormContext();
  const { i18n } = useTranslation();
  const arabicT = i18n.getFixedT("ar");

  const button = form?.theme?.colors?.button;
  const button_content = form?.theme?.colors?.button_content;

  const isDifferentButtonBG = button !== DEFULT_BUTTON_COLOR;
  const isDifferentButtonContentColor =
    button_content !== DEFULT_BUTTON_CONTENT_COLOR;

  const isLastPageInFields = useIsLastQuestion(field.field_id, form);

  return (
    <Flex flexGrow={1} mx="auto" mt={10}>
      <Button
        onClick={() => {
          setVisiblePageNumber(visiblePageNumber + 1);
        }}
        maxH="none"
        fontWeight={400}
        color={isDifferentButtonContentColor ? button_content : "white"}
        bg={isDifferentButtonBG ? button : "teal.600"}
        w="auto"
        h="35px"
        alignSelf="end"
        _hover={{
          bg: isDifferentButtonBG ? button : "teal.400",
        }}
        borderRadius="3px"
        disabled={!isStatic}
        fontSize={["16px", "18px"]}
      >
        {isLastPageInFields
          ? form?.language === "ar"
            ? arabicT("Submit")
            : "Submit"
          : form?.language === "ar"
          ? arabicT("Next")
          : "Next"}
      </Button>
    </Flex>
  );
};
