import { Text, Link, Flex } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
// import Logo5 from "./Logo.png";
import { ReactComponent as Logo5 } from "./Logo.svg";

export default function Logo({
  disable,
  redirectLink,
}: {
  disable?: boolean;
  redirectLink?: string;
}) {
  if (disable) return <Logo5 />;

  if (redirectLink) {
    return (
      <Link
        href={redirectLink}
        h="100%"
        w="100%"
        _hover={{
          textDecoration: "none",
        }}
      >
        <Logo5 />
      </Link>
    );
  }

  return (
    <Link
      href="/"
      as={ReactRouterLink}
      to="/"
      h="100%"
      w="100%"
      _hover={{
        textDecoration: "none",
      }}
    >
      <Logo5 />
      {/* <Flex h="100%" w="100%" alignItems="center">
        <Text fontSize="33px" color="teal.600" mx="auto">
          SeenJeem
        </Text>
      </Flex> */}

      {/* <LogoSvg height="inherit" width="inherit" /> */}
    </Link>
  );
}
